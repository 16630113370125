import {
    combineAndUpdateProducts,
    completeAndSortTemporaryLines,
    filterAndSortRegisteredLines,
    filterUnregisteredLines,
} from '@b2d/pages/Achats/components/forms/formElements/fragments/productsSection/utility';
import { updateFormTotaux } from '@b2d/pages/Achats/components/forms/functions/calculsProduits';
import productsArrayItemStructure from '@b2d/pages/Achats/components/forms/formElements/fragments/productsSection/productsArrayItemStructure';

import type { FormStructure } from '@europrocurement/flexy-form';
import type {
    LigneProduit,
    LigneProduitRegistered,
} from '@b2d/pages/Achats/components/forms/types';
import type { ProductsArrayStructureProps } from '@b2d/pages/Achats/components/forms/formElements/types';

export default ({
    formContext,
    rubFacts,
    txTva,
    mode,
    hasBlured,
    openModal,
    produitsVente,
    displaySells,
    handleTotalsLock,
    stateSwitchLockValues,
    customOnRemoveEvent,
    customBeforeAppendEvent,
    customOnAppendEvent,
    defaultEvents = true,
}: ProductsArrayStructureProps): Array<FormStructure> => [
    {
        type: 'subformarray',
        name: 'produits',
        isArray: true,
        displayAddButton: true,
        displayClearButton: true,
        addBtnLabel: 'Ajouter un produit',
        addBtnTooltip: 'Ajouter un produit',
        minLength: 1,
        maxLength: 10,
        removeBtnTooltip: 'Supprimer le produit',
        gridProps: {
            rowSpacing: 0,
            columnSpacing: 2,
            columns: 10,
        },
        events: {
            onRemove() {
                if (customOnRemoveEvent) {
                    customOnRemoveEvent();
                }

                if (!defaultEvents) return;

                if (formContext && stateSwitchLockValues) {
                    updateFormTotaux({
                        formContext,
                        reasonToTriggerUpdate: stateSwitchLockValues.totals.value,
                    });
                }
            },
            beforeAppend(item: Partial<LigneProduit>) {
                if (customBeforeAppendEvent) {
                    customBeforeAppendEvent(item);
                }

                if (!defaultEvents) return;

                const cloneItem = { ...item };

                if (cloneItem.txtva) {
                    cloneItem.txtva.label = 20;
                    cloneItem.txtva.value = 20;
                }
            },
            onAppend(item: LigneProduitRegistered, formContextOnAppend, fieldArray) {
                if (customOnAppendEvent) {
                    customOnAppendEvent(item, formContextOnAppend);
                }

                if (!defaultEvents) return;

                // Get the current products in the form context
                const formContextProducts: Array<LigneProduitRegistered> =
                    formContextOnAppend.getValue('produits') || [];

                // Get the lines from the fieldArray (excluding the newly added item)
                const linesFromFieldArrayWithoutCurrentItem: Array<LigneProduitRegistered> =
                    fieldArray.fields || [];

                // Step 1: Filter and sort registered lines, preserving any user modifications
                const linesRegistered = filterAndSortRegisteredLines(
                    linesFromFieldArrayWithoutCurrentItem,
                ).map((registeredLine) => {
                    // Check if the registered line has been modified in the form context
                    const modifiedLine = formContextProducts.find((product) => {
                        const sameIdLigne =
                            product.idLigne?.toString() === registeredLine.idLigne?.toString();

                        const calculatedTtcUndefined = product?.ttc === undefined;
                        const calculatedTtcDifferent =
                            product?.ttc?.toString() !== registeredLine?.ttc?.toString();
                        const calculatedTvaUndefined = product?.tva === undefined;
                        const calculatedTvaDifferent =
                            product?.tva?.toString() !== registeredLine?.tva?.toString();

                        const calculatedTtcChanged =
                            !calculatedTtcUndefined && calculatedTtcDifferent;
                        const calculatedTvaChanged =
                            !calculatedTvaUndefined && calculatedTvaDifferent;

                        const oneOfCalculatedFieldsChanged =
                            calculatedTtcChanged || calculatedTvaChanged;

                        return sameIdLigne && oneOfCalculatedFieldsChanged;
                    });

                    // Return the modified version of the registered line (if modified) or the original
                    return modifiedLine || registeredLine;
                });

                // Step 2: Filter out unregistered lines (new lines)
                const linesUnregistered = filterUnregisteredLines(
                    linesFromFieldArrayWithoutCurrentItem,
                    item,
                );

                // Step 3: Process temporary lines from the form context, ensuring they are sorted and initialized
                const temporaryLinesFromFormContext = formContextProducts.filter(
                    (formContextProduct) => !formContextProduct.idLigne,
                );
                const temporaryLines = completeAndSortTemporaryLines(
                    temporaryLinesFromFormContext,
                    linesUnregistered,
                );

                // Step 4: Combine registered and temporary lines and update the form context
                combineAndUpdateProducts(formContextOnAppend, linesRegistered, temporaryLines);
            },
        },
        structure: productsArrayItemStructure({
            rubFacts,
            txTva,
            mode,
            hasBlured,
            openModal,
            produitsVente,
            displaySells,
            handleTotalsLock,
        }),
    },
];
