import { KeycloakConfig } from 'keycloak-js';
// import { BASE_PATH as BASE_PATH_ACHATS } from '../openApi/ApiAchats/base';
// import { BASE_PATH as BASE_PATH_DOSSIERS } from '../openApi/ApiDossiers/base';
// import { BASE_PATH as BASE_PATH_TIERS } from '../openApi/ApiTiers/base';
// import { BASE_PATH as BASE_PATH_OFFRE } from '../openApi/ApiOffre/base';
// import { BASE_PATH as BASE_PATH_FORMALITE } from '../openApi/ApiFormalite/base';
// import { BASE_PATH as BASE_PATH_SIGN } from '../openApi/ApiSign/base';
// import { BASE_PATH as BASE_PATH_FACTURATION } from '../openApi/ApiFacturation/base';
// import { BASE_PATH as BASE_PATH_MAIL } from '../openApi/ApiMail/base';

export enum ENV {
    'DEV' = 'dev',
    'QUAL' = 'qual',
    'PREPROD' = 'preprod',
    'PROD' = 'prod',
}

export enum SOCIETES_G3_FULLNAME_FROM_ID {
    'Affiches Parisiennes' = 2,
    'Claude et Goy' = 8,
    'Europrocurement' = 9,
    'Agence Juridique' = 10,
}

export enum SOCIETES_LTL_FULLNAME_FROM_ID {
    'Le Tout Lyon' = 3,
    "L'Essor" = 4,
    'Nouvelles Publications Commerciales' = 5,
    'Le Patriote Beaujolais' = 6,
}

export enum SOCIETES_GIE_FULLNAME_FROM_ID {
    'Legal2Digital' = 12,
}

export const ALL_SOCIETES_FULLNAME_FROM_ID = {
    ...SOCIETES_G3_FULLNAME_FROM_ID,
    ...SOCIETES_LTL_FULLNAME_FROM_ID,
    ...SOCIETES_GIE_FULLNAME_FROM_ID,
};

/**
 * Sociétés référencées dans B2D
 *
 * Constante à modifier pour ajouter ou supprimer une société dans B2D.
 * Il est possible de faire référence à n'importe quelle société de G3, LTL ou GIE.
 */
export const BDD_SWITCH_SOCIETES_IDS_ARRAY = [2, 3, 5, 12] as const;

/**
 * Identifiants des sociétés référencées
 */
export type BDD_SWITCH_SOCIETES_IDS_TYPE = (typeof BDD_SWITCH_SOCIETES_IDS_ARRAY)[number];

/**
 * Libelles des sociétés référencées.
 */
export const BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID = BDD_SWITCH_SOCIETES_IDS_ARRAY.reduce(
    (acc, id) => {
        const name = ALL_SOCIETES_FULLNAME_FROM_ID[id];
        if (name) {
            acc[id] = name;
        }
        return acc;
    },
    {} as { [id in BDD_SWITCH_SOCIETES_IDS_TYPE]: string },
);

export type ENV_API = 'local' | ENV;

export type ApiEnvType = { [envName in ENV_API]?: string };

export enum APIS_ENUM {
    'ACHATS' = 'achats',
    'SIGN' = 'sign',
    'TIERS' = 'tiers',
    'DOSSIERS' = 'dossiers',
    'OFFRE' = 'offre',
    'FORMALITE' = 'formalite',
    'FACTURATION' = 'facturation',
    'MAIL' = 'mail',
    'FORMULAIRE' = 'formulaire',
}

export type APIS_ENV_OPTIONS = {
    [x in APIS_ENUM]: ApiEnvType;
};

export type KC_ENV_OPTIONS = {
    [x in ENV]: Omit<KeycloakConfig, 'clientId'>;
};

export type APIS_ENV_SELECTED = {
    [x in APIS_ENUM]: keyof ApiEnvType;
};

export type KC_ENV_SELECTED = keyof KC_ENV_OPTIONS;

export type APIS_KC_ENV_OPTIONS = {
    apis: APIS_ENV_OPTIONS;
    keycloak: KC_ENV_OPTIONS;
};

export type APIS_KC_ENV_SELECTED = {
    apis: APIS_ENV_SELECTED;
    keycloak: KC_ENV_SELECTED;
};

const APIENV: APIS_KC_ENV_OPTIONS = {
    apis: {
        achats: {
            local: 'http://localhost:8086',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        tiers: {
            local: 'http://localhost:8080',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        sign: {
            local: 'http://localhost:8080',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        dossiers: {
            local: 'http://localhost:8087',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        offre: {
            local: 'http://localhost:8090',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        formalite: {
            local: 'http://localhost:8083',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        facturation: {
            local: 'http://localhost:8080',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        mail: {
            local: 'http://localhost:8081',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
        formulaire: {
            local: 'http://localhost:8080',
            qual: 'https://api.qual.legal2digital.net',
            preprod: 'https://api.preprod.legal2digital.net',
            prod: 'https://api.legal2digital.net',
        },
    },
    keycloak: {
        dev: {
            realm: 'dev',
            url: 'https://sso.preprod.legal2digital.net',
        },
        qual: {
            realm: 'qual',
            url: 'https://sso.preprod.legal2digital.net',
        },
        preprod: {
            realm: 'preprod',
            url: 'https://sso.preprod.legal2digital.net',
        },
        prod: {
            realm: 'prod',
            url: 'https://sso.legal2digital.net',
        },
    },
};

export default APIENV;
