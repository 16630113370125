/* eslint-disable @typescript-eslint/no-unused-vars */

import {
    SliceFactory,
    FetchCollectionData,
    FetchItemDataType,
    ApiCollectionResponse,
} from '@europrocurement/l2d-redux-utils';
import {
    ConfigurationParameters as AchatsConfigurationParameters,
    Configuration,
    FactureAchatApi,
    FactureAchatApiGetFacturesAchatsFactureAchatCollectionRequest,
    FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup,
    FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup,
} from '../../../openApi/ApiAchats';
import { ACHATS_REDUCER_NAME } from '../constants';
import { Order } from '../../../types/apiGenericTypes';

export type FactureAchatApiObject =
    FactureAchatJsonldFactureAchatReadFactureAchatReadItemFactureAchatLinkGieTimestampableBlameambleGroupAttachmentGroup &
        Record<string, unknown>;

export type FactureAchat = FactureAchatApiObject;
export type FactureAchatNote =
    FactureAchatNoteJsonldFactureAchatReadFactureAchatReadCalculatedItemsTimestampableBlameambleGroupAttachmentGroupTaggableGroup;

export const FACTURES_ACHATS_SLICE_NAME = 'factureachat';

export const FACTURES_ACHATS_STATS_DATASOURCE_NAME = 'statsFacturesAchats';
export const FACTURES_ACHATS_PAUSE_DATASOURCE_NAME = 'pauseFactureAchats';
export const FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME = 'statementFactureAchats';

export const additionalFacturesAchatsDataSources: Array<string> = [
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
    FACTURES_ACHATS_PAUSE_DATASOURCE_NAME,
    FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME,
];

const mapperFactureAchat = function (factureAchat: FactureAchatApiObject) {
    return {
        ...factureAchat,
    } as FactureAchat;
};

export function createFactureAchatSlice(configuration: AchatsConfigurationParameters) {
    const conf = new Configuration(configuration);
    const api = new FactureAchatApi(conf);

    const fetchFactureAchatsCollection: FetchCollectionData<FactureAchatApiObject> = ({
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) => {
        let dateFactureBefore: string | undefined;
        let dateFactureAfter: string | undefined;
        let createdAtBefore: string | undefined;
        let createdAtAfter: string | undefined;
        let lignesNumeroDossier: string | undefined;
        let lignesNumeroPrestation: string | undefined;
        let updatedBy: string | undefined;
        let numeroFacture: string | undefined;
        let recherche: string | undefined;
        let libelleFacturante: string | undefined;
        let codesRejetsEtat: string | undefined;
        let cloture: boolean | undefined;
        let deleted: boolean | undefined;
        let linkedCodeRejets: boolean | undefined;
        let syncG3: boolean | undefined;
        let multiAnnonces: boolean | undefined;
        let orderId: Order;
        let orderUpdatedAt: Order;

        let idEntiteFacturante: number | undefined;
        let idDossier: number | undefined;
        let idPresta: number | undefined;
        let idJal: number | undefined;
        let idSociete: number | undefined;
        let idReleveAchat: number | undefined;
        let pdfFactureId: number | undefined;

        let categories: string[] | undefined;
        let codesRejets: string[] | undefined;

        if (filters.linkedCodeRejets !== undefined) {
            linkedCodeRejets = filters.linkedCodeRejets as boolean;
        }

        if (filters.dateFacture) {
            [dateFactureBefore, dateFactureAfter] = filters.dateFacture as string[];
        }

        if (filters.createdAt) {
            [createdAtBefore, createdAtAfter] = filters.createdAt as string[];
        }

        if (filters.ligneNumeroDossier) {
            lignesNumeroDossier = filters.ligneNumeroDossier as string;
        }

        if (filters.ligneNumeroPresta) {
            lignesNumeroPrestation = filters.ligneNumeroPresta as string;
        }

        if (filters.updatedBy) {
            updatedBy = filters.updatedBy as string;
        }

        if (filters.idEntiteFacturante) {
            idEntiteFacturante = filters.idEntiteFacturante as number;
        }

        if (filters.idDossier) {
            idDossier = filters.idDossier as number;
        }

        if (filters.idPresta) {
            idPresta = filters.idPresta as number;
        }

        if (filters.idJal) {
            idJal = filters.idJal as number;
        }

        if (filters.idSociete) {
            idSociete = filters.idSociete as number;
        }

        if (filters.idReleveAchat) {
            idReleveAchat = filters.idReleveAchat as number;
        }

        if (filters.libelleFacturante) {
            libelleFacturante = filters.libelleFacturante as string;
        }

        if (filters.cloture || filters.cloture === false) {
            cloture = filters.cloture as boolean;
        }

        if (filters.multiAnnonces === true) {
            multiAnnonces = true;
        } else if (filters.multiAnnonces === false) {
            multiAnnonces = false;
        }

        if (filters.deleted === true) {
            deleted = true;
        } else if (filters.deleted === false) {
            deleted = false;
        }

        if (search && search !== '') {
            recherche = search;
        }

        if (
            filters.codesRejets &&
            Array.isArray(filters.codesRejets) &&
            filters.codesRejets.length > 0
        ) {
            codesRejets = filters.codesRejets;
        } else if (
            filters.categories &&
            Array.isArray(filters.categories) &&
            filters.categories.length > 0
        ) {
            categories = filters.categories;
        }

        if (filters.syncG3 === true) {
            syncG3 = true;
        } else if (filters.syncG3 === false) {
            syncG3 = false;
        }

        if (filters.codesRejetsEtat && filters.codesRejetsEtat !== '') {
            codesRejetsEtat = filters.codesRejetsEtat as string;
        }

        orders.forEach((item) => {
            if (item.field === 'id') {
                orderId = item.value;
            }
            if (item.field === 'updatedAt') {
                orderUpdatedAt = item.value;
            }
        });

        const requestParameters: FactureAchatApiGetFacturesAchatsFactureAchatCollectionRequest = {
            xIdSociete,
            page: pagination.page + 1,
            itemsPerPage: pagination.itemsPerPage,
            existsCodesRejets: linkedCodeRejets,
            cloture,
            syncG3,
            deleted,
            multiAnnonces,
            dateFactureBefore,
            dateFactureAfter,
            createdAtBefore,
            createdAtAfter,
            libelleFacturante,
            lignesNumeroDossier,
            lignesNumeroPrestation,
            updatedBy,
            idEntiteFacturante,
            lignesIdDossier: idDossier,
            lignesIdPrestation: idPresta,
            idJal,
            numeroFacture,
            codesRejetsCodeRejetCategorie2: categories,
            codesRejetsCodeRejetCode2: codesRejets,
            codesRejetsEtat,
            idSociete,
            idReleveAchat,
            recherche,
            orderId,
            orderUpdatedAt,
        };

        return api
            .getFacturesAchatsFactureAchatCollection(requestParameters)
            .then((res) => res.data) as Promise<ApiCollectionResponse<FactureAchatApiObject>>;
    };

    const fetchFactureAchatsItem: FetchItemDataType<FactureAchatApiObject> = ({
        idItem,
        search,
        pagination,
        filters,
        orders,
        xIdSociete,
        rootstate,
    }) =>
        api
            .getFactureAchatFactureAchatItem({
                id: String(idItem),
                xIdSociete,
            })
            .then((res) => res.data) as Promise<FactureAchatApiObject>;

    const { slice: factureAchatSlice, dataSourcesThunks: factureAchatDataSourcesThunks } =
        SliceFactory.createSlice<FactureAchatApiObject, FactureAchat>(
            FACTURES_ACHATS_SLICE_NAME,
            ACHATS_REDUCER_NAME,
            additionalFacturesAchatsDataSources,
            fetchFactureAchatsCollection,
            fetchFactureAchatsItem,
            mapperFactureAchat,
            {
                cloture: true,
                deleted: false,
            },
            undefined,
            [
                {
                    field: 'updatedAt',
                    value: 'desc',
                },
            ],
        );

    return { factureAchatSlice, factureAchatDataSourcesThunks };
}
