import { ConfigurationParameters as FactureFormaliteConfigurationParameters } from '../../openApi/ApiFormalite';
import { createFactureFormaliteSlice } from './slices/factureFormaliteSlice';

export function createFactureFormaliteReducer(
    configuration: FactureFormaliteConfigurationParameters,
) {
    const { factureFormaliteDataSourcesThunks, factureFormaliteSlice } =
        createFactureFormaliteSlice(configuration);
    return { factureFormaliteDataSourcesThunks, factureFormaliteSlice };
}
