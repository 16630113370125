import React from 'react';

import { LazyBreadcrumb } from '@europrocurement/flexy-components';

import PortailAchatWidgets from './PortailAchatWidgets/PortailAchatWidgets';

const Accueil = function () {
    return (
        <>
            <LazyBreadcrumb path={[{ path: 'Accueil', link: '/accueil' }, 'Dashboard']} />

            <PortailAchatWidgets />
        </>
    );
};
export default Accueil;
