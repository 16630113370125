import React from 'react';

import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons';
import { FormStructure } from '@europrocurement/flexy-form';
import { Dossier } from '@europrocurement/l2d-domain';
import {
    AutocompleteFolioStructureProps,
    FolioInvoiceSectionStructureProps,
    FolioInvoiceSectionWithAdvancedResearchStructureProps,
    GetFolioAutocompleteStructureProps,
    SectionHeadControlProps,
} from '@b2d/pages/Achats/components/forms/formElements/types';
import HeaderStructure from '@b2d/pages/Achats/components/fragments/HeaderStructure';
import { resetDossierAndFournisseurs } from '@b2d/pages/Achats/components/forms/functions/generalFunctions';
import { resetDossiersFields } from '@b2d/pages/Achats/components/forms/functions/dossierFonctions';
import { autocompleteStructureReadonly } from '@b2d/pages/Achats/components/forms/formElements/fragments/common';

const autocompleteFolioStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    onSelectOption,
    mode,
}: AutocompleteFolioStructureProps): FormStructure | null => {
    if (!dataSource || !fetchData) {
        console.error('Missing prop to render provision autocomplete field !');

        return null;
    }

    return {
        type: 'autocompletestore',
        name: 'numero_dossier',
        inputlabel: 'Recherche par numéro de dossier',
        dataSource,
        fetchData,
        renderField: 'numero',
        idProperty: 'id',
        defaultInputValue,
        debounce: 1000,
        rules: {
            required:
                mode.type === 'register' ? 'Vous devez renseigner le numero de dossier' : undefined,
        },
        events: {
            onClear: (event, newValue, reason, details, formContext) => {
                resetDossierAndFournisseurs(formContext);
            },
            onSelectOption,
        },
        getOptionLabel: (item: Dossier) => `${item.numero || 'Pas de numéro'}`, // Se rends dans l'input
        simpleRenderOption: (item: Dossier) =>
            `${item.numero || 'Pas de numéro'} - ${
                item.prestations ? item.prestations[0]?.jal?.nom : 'Pas de nom de journal'
            } - ${item.annonceur || "Pas de nom d'annonceur"}`, // Se rends dans les options
        // onChangeInput: onChangeDossier,
        searchability: (terms) => {
            if (terms.length < 5) {
                return 'Veuillez saisir au moins 5 caractères';
            }
            if (!/^\d+$/.test(terms)) {
                return 'Un numéro de dossier est composé de chiffres uniquement';
            }
            return true;
        },
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    };
};

const autocompleteProvisionStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    onSelectOption,
    mode,
}: AutocompleteFolioStructureProps): FormStructure | null => {
    if (!dataSource || !fetchData) {
        console.error('Missing prop to render folio autocomplete field !');

        return null;
    }

    return {
        type: 'autocompletestore',
        name: 'numero_annonce',
        inputlabel: "Recherche par numéro d'annonce",
        dataSource,
        fetchData,
        renderField: 'prestations.0.numero',
        defaultInputValue,
        idProperty: 'id',
        debounce: 1000,
        rules: {
            required:
                mode.type === 'register' ? "Vous devez renseigner le numero d'annonce" : undefined,
        },
        events: {
            onClear: (event, newValue, reason, details, formContext) => {
                resetDossiersFields(formContext);
            },
            onSelectOption,
        },
        getOptionLabel: (item: Dossier) =>
            `${item.prestations && item.prestations[0]?.numero}` || '',
        // Se rends dans les options
        simpleRenderOption: (item: Dossier) =>
            `${item.prestations && item.prestations[0]?.numero} - ${
                item.prestations && item.prestations[0]?.jal?.nom
            } - ${item.annonceur}`,
        searchability: (terms) =>
            terms.length >= 5 ? true : 'Veuiez saisir au moins 5 caractères',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    };
};

const getFolioAutocompleteStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    onSelectOption,
    mode,
}: GetFolioAutocompleteStructureProps): FormStructure | null => {
    const booleanEditMode = mode.type === 'update' && mode.lock;

    if (booleanEditMode) {
        return autocompleteStructureReadonly({
            name: 'numero_dossier',
            inputlabel: 'Numéro de dossier',
        });
    }

    return autocompleteFolioStructure({
        mode,
        dataSource,
        fetchData,
        defaultInputValue,
        onSelectOption,
    });
};

const getProvisionAutocompleteStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    onSelectOption,
    mode,
}: GetFolioAutocompleteStructureProps): FormStructure | null => {
    const booleanEditMode = mode.type === 'update' && mode.lock;

    if (booleanEditMode)
        return autocompleteStructureReadonly({
            name: 'numero_annonce',
            inputlabel: "Numéro d'annonce",
        });

    return autocompleteProvisionStructure({
        mode,
        dataSource,
        fetchData,
        defaultInputValue,
        onSelectOption,
    });
};

const folioInvoiceSectionStructure = ({
    disabled,
    mode,
    autocompleteProps,
    headerSwitches = [],
    headerChildren = [],
    hideHeader = false,
    onSelectOption = undefined,
}: FolioInvoiceSectionStructureProps): Array<FormStructure> => {
    const folioAutocompleteStructure = getFolioAutocompleteStructure({
        dataSource: autocompleteProps?.folio?.dataSource,
        fetchData: autocompleteProps?.folio?.fetchData,
        defaultInputValue: autocompleteProps?.folio?.defaultValue,
        onSelectOption,
        mode,
    });

    const provisionAutocompleteStructure = getProvisionAutocompleteStructure({
        dataSource: autocompleteProps?.provision?.dataSource,
        fetchData: autocompleteProps?.provision?.fetchData,
        defaultInputValue: autocompleteProps?.provision?.defaultValue,
        onSelectOption,
        mode,
    });

    const autocompleteStructures = [];

    if (folioAutocompleteStructure !== null) {
        autocompleteStructures.push(folioAutocompleteStructure);
    }

    if (provisionAutocompleteStructure !== null) {
        autocompleteStructures.push(provisionAutocompleteStructure);
    }

    let headerStructure: Array<FormStructure> = [];

    if (!hideHeader) {
        headerStructure = [
            {
                type: 'header',
                label: (
                    <HeaderStructure
                        icon={<FontAwesomeIcon icon={faFolderOpen} />}
                        title="Dossier Annonce"
                        controls={headerChildren}
                        switches={headerSwitches}
                    />
                ),
                name: 'file_header',
            },
        ];
    }

    return [
        ...headerStructure,
        ...autocompleteStructures,
        {
            type: 'text',
            name: 'nom_journal',
            inputlabel: 'Journal',
            disabled,
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
        },
        {
            type: 'text',
            name: 'date_parution',
            inputlabel: 'Date de parution',
            disabled,
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
        },
        {
            type: 'text',
            name: 'nom_annonceur',
            inputlabel: 'Annonceur',
            disabled,
            xs: 12,
            sm: 4,
            md: 4,
            lg: 4,
        },
    ];
};

const folioInvoiceSectionWithAdvancedResearchStructure = ({
    onClickModal,
    autocompleteProps,
    mode,
    headerSwitches = [],
    headerChildren = [],
}: FolioInvoiceSectionWithAdvancedResearchStructureProps): Array<FormStructure> => {
    let overwriteHeaderChildren: Array<SectionHeadControlProps> = [];

    if (headerChildren && Array.isArray(headerChildren)) {
        overwriteHeaderChildren = [...overwriteHeaderChildren, ...headerChildren];
    } else {
        overwriteHeaderChildren.push(headerChildren);
    }

    if (mode.type === 'register') {
        overwriteHeaderChildren.push(
            <Button
                onClick={() => onClickModal()}
                variant="outlined"
                size="small"
                color="secondary"
                sx={{ marginLeft: 'auto' }}
            >
                Recherche avancée
            </Button>,
        );
    }

    return folioInvoiceSectionStructure({
        disabled: true,
        mode,
        autocompleteProps,
        headerSwitches,
        headerChildren: overwriteHeaderChildren,
    });
};

export {
    autocompleteFolioStructure,
    autocompleteProvisionStructure,
    getFolioAutocompleteStructure,
    getProvisionAutocompleteStructure,
    folioInvoiceSectionStructure,
    folioInvoiceSectionWithAdvancedResearchStructure,
};
