import React, { FC } from 'react';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { FormContextType } from '../../Package/Components/types';
import CatalogArticleOverrideLine, {
    CatalogFormOverridableArticleItem,
} from './CatalogArticleOverrideLine';

type ComponentProps = {
    context: FormContextType;
};

/** Catalog override module :
 * - List all package article as line
 * - For each line allow edition for (label / price & quantity)
 */
const CatalogOverrideModule: FC<ComponentProps> = function ({ context }) {
    const articles = context.getValues()
        .catalogArticlesOverrides as CatalogFormOverridableArticleItem[];
    const articleList = articles?.map((article, index) => (
        <CatalogArticleOverrideLine
            key={`override_catalog_line_${index + 1}`}
            article={article}
            context={context}
            index={index}
        />
    ));
    return <Box>{articles && articles.length > 0 ? articleList : <CircularProgress />}</Box>;
};

export default CatalogOverrideModule;
