/* eslint-disable no-case-declarations */
import { FactureAchat } from '@europrocurement/l2d-domain';
import { generatePath } from 'react-router';
import achatsViewPathResolver, {
    AchatsSuportedViewPathResolver,
} from '../pages/Achats/achatsViewPathResolver';

export type AccessibleDirections = AchatsSuportedViewPathResolver;
export type PossibleParams = {
    factureAchat?: FactureAchat;
    mediaObjectId?: number;
    dossiernum?: string;
    from?: 'Mes factures' | 'À traiter';
    statementId?: number;
};

export type GenerateB2DPathResponseType =
    | {
          status: 'OK';
          path: string;
      }
    | {
          status: 'KO';
          message: string;
      }
    | {
          status: 'STAY';
      };

const generateB2DPath = (
    direction: AccessibleDirections,
    params?: PossibleParams,
): GenerateB2DPathResponseType => {
    switch (direction) {
        case 'formmultimediaobjectstatement':
        case 'formmultimediaobject':
        case 'formmediaobject':
            if (!params?.mediaObjectId) return { status: 'KO', message: 'PDF manquant ! 💥' };

            const mediaObjectParams: {
                mediaobjectid: string;
                from?: 'Mes factures' | 'À traiter';
                statementid?: number;
            } = {
                mediaobjectid: `${params.mediaObjectId}`,
                statementid: params.statementId || undefined,
            };

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), mediaObjectParams),
            };
        case 'statementRegister':
            if (!params?.statementId) return { status: 'KO', message: 'Relevé manquant ! 💥' };
            if (!params?.mediaObjectId) return { status: 'KO', message: 'PDF manquant ! 💥' };

            const registerStatementParams: {
                mediaobjectid: string;
                statementid?: number;
            } = {
                mediaobjectid: `${params.mediaObjectId}`,
                statementid: params.statementId,
            };

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), registerStatementParams),
            };
        case 'statementRecap':
            if (!params?.statementId) return { status: 'KO', message: 'Relevé manquant ! 💥' };

            const recapStatementParams: {
                statementid: number;
            } = {
                statementid: params.statementId,
            };

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), recapStatementParams),
            };
        case 'formmultirecap':
        case 'formmultirecapfpro':
        case 'formmultiadddossier':
        case 'formmultinextdossier':
        case 'formfactureachat':
        case 'consulterMultiFacture':
            if (!params?.factureAchat) return { status: 'KO', message: 'Facture manquante ! 💥' };

            const { factureAchat } = params;

            let mediaobjectparam;

            if (!factureAchat.pdfFacture) {
                if (!params.mediaObjectId) {
                    return { status: 'KO', message: 'PDF manquant ! 💥' };
                }
                mediaobjectparam = params.mediaObjectId;
            }

            const idMediaobject = mediaobjectparam || factureAchat.pdfFacture?.id;
            const factureParams: {
                factureachatid: string;
                mediaobjectid: string;
            } = {
                factureachatid: `${factureAchat.id}`,
                mediaobjectid: '',
            };

            if (!idMediaobject)
                return { status: 'KO', message: "Impossible d'extraire l'id du PDF ! 💥" };

            factureParams.mediaobjectid = String(idMediaobject);

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), factureParams),
            };
        case 'unclosedStatementView':
            const statementParams = {
                statementid: params?.statementId,
            };

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), statementParams),
            };
        case 'formmultieditdossier':
        case 'formmultieditfprodossier':
        case 'nextEditFproDossierMultiFacturePath':
        case 'nextEditDossierMultiFacturePath':
            if (!params?.factureAchat) return { status: 'KO', message: 'Facture manquante ! 💥' };
            if (!params?.dossiernum) return { status: 'KO', message: 'N° dossier manquant ! 💥' };

            const factureAchatDossier = params?.factureAchat;
            const numeroDossier = params?.dossiernum;

            let mediaobjectparamDossier;

            if (!factureAchatDossier.pdfFacture) {
                if (!params.mediaObjectId) {
                    return { status: 'KO', message: 'PDF manquant ! 💥' };
                }
                mediaobjectparamDossier = params.mediaObjectId;
            }

            const idMediaobjectDossier =
                mediaobjectparamDossier || factureAchatDossier.pdfFacture?.id;
            const dossierParams: {
                factureachatid: string;
                mediaobjectid: string;
                dossiernum: string;
            } = {
                factureachatid: `${factureAchatDossier.id}`,
                mediaobjectid: '',
                dossiernum: `${numeroDossier}`,
            };

            if (!idMediaobjectDossier)
                return { status: 'KO', message: "Impossible d'extraire l'id du PDF ! 💥" };

            dossierParams.mediaobjectid = String(idMediaobjectDossier);

            return {
                status: 'OK',
                path: generatePath(achatsViewPathResolver(direction), dossierParams),
            };
        case 'listmediaobject':
        case 'listmediaobjectinternally':
        case 'listfacture':
        case 'listfacturepause':
        case 'listInvoiceStatement':
        case 'downloadMediaObject':
        case 'uploadMediaObject':
        case 'achatsstats':
            return {
                status: 'OK',
                path: achatsViewPathResolver(direction),
            };
        default:
            return {
                status: 'STAY',
            };
    }
};

export default generateB2DPath;
