import { Box, Stack, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { FactureAchatApiObject } from '@europrocurement/l2d-domain';
import { CodeRejetsChipList, type CodeRejetsChipType } from '../../widgets/CodeRejetsChipList';
import FactureAchatEtats from '../../widgets/FactureAchatEtats';
import StatusList from '../StatusList';

type InvoiceDataCardProps = {
    invoice?: FactureAchatApiObject;
    rejectCodes?: Array<CodeRejetsChipType>;
};

const InvoiceDataCard: FunctionComponent<InvoiceDataCardProps> = function ({
    invoice,
    rejectCodes,
}: InvoiceDataCardProps) {
    return (
        <Stack
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'start',
                justifyContent: 'space-between',
                gap: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                    gap: 1,
                }}
            >
                {invoice && invoice.createdAt ? (
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                    >
                        {`Facture saisie le : ${new Date(invoice.createdAt).toLocaleDateString()}`}
                    </Typography>
                ) : null}
                {invoice && invoice.createdBy ? (
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                    >
                        {`Facture saisie par : ${invoice.createdBy}`}
                    </Typography>
                ) : null}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '15px',
                        padding: '0px',
                        alignItems: 'center',
                    }}
                >
                    {invoice && rejectCodes && rejectCodes.length > 0 ? (
                        <>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                            >
                                Codes rejets :{' '}
                            </Typography>
                            <CodeRejetsChipList
                                rejets={rejectCodes}
                                full
                            />
                        </>
                    ) : (
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                        >
                            Pas de code rejet
                        </Typography>
                    )}
                </Box>
                {invoice ? <FactureAchatEtats factureAchats={invoice} /> : null}
            </Box>
            <StatusList itemToWatch={invoice} />
        </Stack>
    );
};

export default InvoiceDataCard;
