import * as React from 'react';
import { ModalContext } from '@europrocurement/flexy-components';
import { ColumnDatatable } from '@europrocurement/flexy-datatable';
import { ArticlesJsonldArticleRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { useDispatch } from 'react-redux';
import ListingWithPreview from '@b2d/pages/Offres/components/ListingWithPreview';
import ArticleForm from '@b2d/pages/Offres/Article/Form';
import { addItem } from '@europrocurement/l2d-icons';
import model from '../models/article';
import ModalFormTitle from '../components/form/ModalFormTitle';
import { getPriceColumn, getStringColumn } from '../listings';

const Panel: React.FC = function () {
    const { modalActions } = React.useContext(ModalContext);

    const dispatch = useDispatch();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reloadList = () => dispatch(model.datasourcesThunks.main.getData({}) as any);

    const listingButton = {
        label: 'Créer un nouvel article',
        icon: addItem,
        action: () =>
            modalActions.call(
                <>
                    <ModalFormTitle
                        icon={model.icon}
                        title="Création d'un article"
                    />
                    <ArticleForm
                        isCreate
                        afterSubmit={reloadList}
                    />
                </>,
            ),
    };

    const columns: ColumnDatatable<ArticlesJsonldArticleRead>[] = [
        getStringColumn('libelle', 'Libellé'),
        getStringColumn('codeArticle'),
        getStringColumn('rubArticle', 'Rubrique'),
        getPriceColumn('prixUnitaire'),
    ];

    return (
        <ListingWithPreview
            listingTitle="Liste des articles"
            listingButton={listingButton}
            listingColumns={columns}
            form={ArticleForm}
            model={model}
        />
    );
};

export default Panel;
