import { prescribersApi } from '@b2d/redux/RootStore';
import usePromises from '@b2d/hooks/usePromises';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { useCallback, useState } from 'react';
import { useSociete } from '@b2d/hooks/societeHooks';

const useExistingPrescribers = (prescriberSiret: string | undefined) => {
    const [currentSiret, setCurrentSiret] = useState<string | undefined>(prescriberSiret);

    const xIdSociete = useSociete();

    const {
        isFetching: prescribersLoading,
        fetchedData: existingPrescribers,
        isLoaded: prescribersLoaded,
        call,
    } = usePromises();

    const fetchPrescribersFromSiret = useCallback(
        (siret: string) => {
            const elasticSearchParameters = `{"query":{"bool":{"must":[{"match":{"relationsSocietes.societe.id":${xIdSociete}}},{"match":{"isPrescripteur":true}},{"match":{"demo":false}},{"match":{"deleted":false}},{"query_string":{"query":"siret:*${siret}*"}}]}}}`;
            return prescribersApi.getListTiersTiersCollection({
                xIdSociete,
                search: elasticSearchParameters,
            });
        },
        [xIdSociete],
    );

    const getExistingPrescribers = useCallback(
        (siret: string | undefined = undefined) => {
            if (siret && siret !== currentSiret) {
                setCurrentSiret(siret);
            }
            if (
                (siret || currentSiret) &&
                xIdSociete &&
                !prescribersLoading &&
                !prescribersLoaded
            ) {
                call([
                    {
                        name: 'fetchPrescribersFromSiret',
                        promise: fetchPrescribersFromSiret((siret || currentSiret) as string),
                    },
                ]);
            } else {
                console.log(prescribersLoaded);
            }
        },
        [
            currentSiret,
            xIdSociete,
            prescribersLoading,
            prescribersLoaded,
            call,
            fetchPrescribersFromSiret,
        ],
    );

    const prescribersRelatedToSiret: Prescripteur[] | undefined =
        existingPrescribers?.fetchPrescribersFromSiret;

    return {
        prescribersRelatedToSiret,
        getExistingPrescribers,
        prescribersLoading,
        prescribersLoaded,
    };
};

export default useExistingPrescribers;
