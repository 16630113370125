import _ from 'lodash';
import type { Reducer } from '@reduxjs/toolkit';

/* eslint-disable no-case-declarations */
import {
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    DOSSIERS_FORMALITES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME,
    FACTURES_ACHATS_SLICE_NAME,
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
    FOURNISSEUR_SLICE_NAME,
    FilteredTauxTva,
    MEDIAOBJECT_SLICE_NAME,
    RubriqueFacturation,
    RubriqueFacturationApiObject,
} from '@europrocurement/l2d-domain';
import { FACTURE_VENTE_SLICE_NAME } from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';
import {
    LigneProduit,
    NoteFactureForm,
    TableauProduits,
    filterOptionsProps,
} from '@b2d/pages/Achats/components/forms/types';

import apiInvoiceSellProductToFormInvoicePurchaseProduct from '@b2d/pages/Achats/components/forms/functions/dataTransformers/apiToForm/apiInvoiceSellProductToFormInvoicePurchaseProduct';
import { pricelineType } from '../pages/Achats/components/forms/functions/calculsProduits';

export const ACTIONS = {
    RESET_STATE: 'factureform.resetState',
    FULL_RESET: 'factureform.fullReset',
    RESET: 'factureform.reset',
    RESET_DATASOURCE_FILTERS: 'factureform.datasourceFilters',
    SET_DEF_DOMAIN: 'factureform.setDefDomain',
    SET_DEF_DOMAIN_FORCED: 'factureform.setDefDomainForced',
    SET_NUM_DOSSIER: 'factureform.setNumeroDossier',
    SET_NUM_DOSSIER_FORMALITE: 'factureform.setNumeroDossierFormalite',
    SET_NUM_PRESTA: 'factureform.setNumeroPresta',
    SET_LAST_TOUCHED: 'factureform.setLastTouched',
    SET_SIREN: 'factureform.setSiren',
    REPLACE_PRODUITS_ACHAT: 'factureform.replaceProduitsAchat',
    INIT_PRODUITS_ACHAT: 'factureform.initProduitsAchat',
    ADD_PRODUIT_ACHAT: 'factureform.addProduitAchat',
    INIT_PRODUITS_VENTE: 'factureform.initProduitsVente',
    UPDATE_PRODUITS_VENTE: 'factureform.updateProduitsVente',
    ADD_PRODUIT_VENTE: 'factureform.addProduitVente',
    DISPLAY_SELLS: 'factureform.displaySells',
    ADD_ROW_TOTAUX: 'factureform.addRowTotaux',
    DELETE_ROW_TOTAUX: 'factureform.deleteRowTotaux',
    ORDER_RUB_FACTS: 'factureform.orderRubFacts',
    FILTER_RUB_FACTS: 'factureform.filterRubFacts',
    ADD_RUB_FACTS: 'factureform.addRubFacts',
    ADD_RUB_FACT: 'factureform.addRubFact',
    SET_RUB_FACT: 'factureform.setRubFact',
    SET_TAUX_TVA: 'factureform.setTva',
    SET_DOMAINES_FACTURATION: 'factureform.setDomainesFacturation',
    DISPLAY_SWITCH_TO_SEE_SELLS: 'factureform.displaySwitchToSeeSells',
    // SET_SWITCH_LIGNE_VENTE: 'factureform.setSwitchLigneVente',
    SET_DRAFT_NOTES: 'factureform.setDraftNotes',
};

type Totaux = {
    ht: number;
    tva: number;
    txtva: number;
    ttc: number;
};

export type DraftNote = { [key: string]: string };

export type SwitchLigneVenteType = {
    label: string;
    sx: {
        display: string;
    };
};

export type FactureFormReducerType = {
    defaultNumeroPresta: string;
    defaultNumeroDossier: string;
    defaultNumeroDossierFormalite: string;
    defaultSiren: string;
    produitsVente: TableauProduits;
    displaySells: boolean;
    lastTouched: [pricelineType | undefined, pricelineType | undefined];
    // produits: tableauProduits;
    totaux: Totaux;
    rubFacts: RubriqueFacturation[];
    txTva: Array<number>;
    defDomain: number;
    defDomainForced: number;
    displaySwitchToSeeSells: boolean;
    draftNotes: NoteFactureForm[];
};

export const INIT_STATE: FactureFormReducerType = {
    defaultNumeroPresta: '',
    defaultNumeroDossier: '',
    defaultNumeroDossierFormalite: '',
    defaultSiren: '',
    produitsVente: [],
    displaySells: true,
    lastTouched: [undefined, undefined],
    totaux: {
        ht: 0,
        tva: 0,
        txtva: 0,
        ttc: 0,
    },
    rubFacts: [],
    txTva: [],
    defDomain: 0,
    defDomainForced: 0,
    displaySwitchToSeeSells: false,
    draftNotes: [],
};

// eslint-disable-next-line default-param-last
export const FactureFormReducer: Reducer<FactureFormReducerType> = (state = INIT_STATE, action) => {
    let newState: FactureFormReducerType = { ...state };

    const orderRubFacts = (rubFacts: RubriqueFacturationApiObject[]) => {
        const orderedRubFacts = [
            ...rubFacts.filter((rubFact) => rubFact.code !== 'RSF'),
            ...rubFacts.filter((rubFact) => rubFact.code === 'RSF'),
        ];
        return orderedRubFacts;
    };

    switch (action.type) {
        case ACTIONS.RESET_STATE:
            const { rubFacts } = state;
            newState = { ...INIT_STATE, rubFacts };
            break;

        // clean les selected et le form
        case ACTIONS.FULL_RESET:
            // clean facture achat selected
            action.asyncDispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/deletemainSelected`,
            });

            // clean media object  achat selected
            action.asyncDispatch({
                type: `${MEDIAOBJECT_SLICE_NAME}/deletemainSelected`,
            });

            // clean media object  achat selected
            action.asyncDispatch({
                type: ACTIONS.RESET,
            });
            break;
        // le form
        case ACTIONS.RESET:
            // clean media object  achat selected
            action.asyncDispatch({
                type: ACTIONS.RESET_STATE,
            });

            // clean search dossier selected
            action.asyncDispatch({
                type: `${DOSSIERS_FORMALITES_SLICE_NAME}/set${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Search`,
                payload: { search: '' },
            });
            // clean du dossier selected
            action.asyncDispatch({
                type: `${DOSSIERS_FORMALITES_SLICE_NAME}/delete${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
            });

            // clean search dossier selected
            action.asyncDispatch({
                type: `${DOSSIERS_SLICE_NAME}/set${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Search`,
                payload: { search: '' },
            });
            // clean du dossier selected
            action.asyncDispatch({
                type: `${DOSSIERS_SLICE_NAME}/delete${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
            });

            // clean search fournisseur selected
            action.asyncDispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/set${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Search`,
                payload: { search: '' },
            });
            // clean du fournisseur selected
            action.asyncDispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/delete${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Selected`,
            });

            // clean search fournisseur
            action.asyncDispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/set${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Search`,
                payload: { search: '' },
            });
            // clear data fournisseur
            action.asyncDispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/clear${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Data`,
            });

            // clear data dossiers
            action.asyncDispatch({
                type: `${DOSSIERS_SLICE_NAME}/clear${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Data`,
            });

            // clear data dossiers
            action.asyncDispatch({
                type: `${DOSSIERS_FORMALITES_SLICE_NAME}/clear${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Data`,
            });

            // clear data achat
            action.asyncDispatch({
                type: `${FACTURES_ACHATS_SLICE_NAME}/clear${FACTURES_ACHATS_FROM_STATEMENT_DATASOURCE_NAME}Data`,
            });

            // clear data achat
            action.asyncDispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/clearmainData`,
            });

            // clear selected achat
            action.asyncDispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });

            break;
        case ACTIONS.RESET_DATASOURCE_FILTERS:
            const filterOptions: filterOptionsProps = action.payload;

            action.asyncDispatch({
                type: `${filterOptions.sliceName}/set${filterOptions.dataSourceName}Search`,
                payload: { search: '' },
            });

            action.asyncDispatch({
                type: `${filterOptions.sliceName}/reset${filterOptions.dataSourceName}Filter`,
            });

            action.asyncDispatch({
                type: `${filterOptions.sliceName}/clear${filterOptions.dataSourceName}Data`,
            });
            break;
        case ACTIONS.SET_DEF_DOMAIN:
            // param = factureVente?.idDdm: number

            newState = {
                ...state,
                defDomain: action.payload || 0,
            };
            break;
        case ACTIONS.SET_DEF_DOMAIN_FORCED:
            // param = factureVente?.idDdm: number

            newState = {
                ...state,
                defDomainForced: action.payload || 0,
            };
            break;
        case ACTIONS.SET_LAST_TOUCHED:
            // param = type: pricelineType

            newState = {
                ...state,
                lastTouched: [state.lastTouched[1], action.payload],
            };
            break;
        case ACTIONS.SET_NUM_DOSSIER:
            // param = dossier.numero: number | null | undefined
            if (state.defaultNumeroDossier === action.payload) {
                break;
            }

            newState = {
                ...state,
                defaultNumeroDossier: `${action.payload}`,
            };
            break;
        case ACTIONS.SET_NUM_DOSSIER_FORMALITE:
            // param = dossierFormalite.numero: string | null | undefined
            if (state.defaultNumeroDossierFormalite === action.payload) {
                break;
            }

            newState = {
                ...state,
                defaultNumeroDossierFormalite: action.payload,
            };
            break;
        case ACTIONS.SET_NUM_PRESTA:
            // param = dossier.prestations[0].numero: string | undefined
            if (state.defaultNumeroPresta === action.payload) {
                break;
            }
            newState = {
                ...state,
                defaultNumeroPresta: action.payload || '',
            };
            break;
        case ACTIONS.SET_SIREN:
            if (state.defaultSiren === action.payload) {
                break;
            }

            newState = {
                ...state,
                defaultSiren: action.payload,
            };
            break;
        case ACTIONS.REPLACE_PRODUITS_ACHAT:
            // param = produits: tableauProduits

            newState = {
                ...state,
                // produits: action.payload,
            };
            break;
        case ACTIONS.INIT_PRODUITS_ACHAT:
            // no param

            newState = {
                ...state,
                // produits: [
                //     {
                //         ht: 0,
                //         tva: 0,
                //         txtva: 0,
                //         ttc: 0,
                //     },
                // ],
            };
            break;
        case ACTIONS.ADD_PRODUIT_ACHAT:
            // param = produit: Partial<ligneProduit>

            newState = {
                ...state,
                // produits: [...state.produits, action.payload],
            };
            break;
        case ACTIONS.INIT_PRODUITS_VENTE:
            // no param

            newState = {
                ...state,
                produitsVente: [],
            };
            break;
        case ACTIONS.ADD_PRODUIT_VENTE:
            // param = produitVente: TblFligneFactureVenteRead

            newState = {
                ...state,
                produitsVente: [...state.produitsVente, action.payload],
            };
            break;
        case ACTIONS.UPDATE_PRODUITS_VENTE:
            // param = produitsVenteToDisplay (produitsVente): produitVente[]

            const updatedProduitsVente = action.payload.map((item: RubriqueFacturation) =>
                apiInvoiceSellProductToFormInvoicePurchaseProduct(item, state.rubFacts),
            );

            newState = {
                ...state,
                produitsVente: updatedProduitsVente,
            };
            break;
        case ACTIONS.ADD_ROW_TOTAUX:
            // param = produit: Partial<LigneProduit>
            const biggerTotal = state.totaux;
            const produitToAdd: Partial<LigneProduit> = action.payload;

            if (produitToAdd.tva && typeof produitToAdd.tva === 'string') {
                produitToAdd.tva = Number.parseFloat(produitToAdd.tva);
            }

            if (produitToAdd.ttc && typeof produitToAdd.ttc === 'string') {
                produitToAdd.ttc = Number.parseFloat(produitToAdd.ttc);
            }

            biggerTotal.ht += produitToAdd.ht ? produitToAdd.ht : 0;
            biggerTotal.tva += produitToAdd.tva ? (produitToAdd.tva as number) : 0;
            // eslint-disable-next-line no-unsafe-optional-chaining
            biggerTotal.txtva += produitToAdd.txtva?.value ? produitToAdd.txtva?.value : 0;
            biggerTotal.ttc += produitToAdd.ttc ? (produitToAdd.ttc as number) : 0;

            newState = {
                ...state,
                totaux: biggerTotal,
            };
            break;
        case ACTIONS.DELETE_ROW_TOTAUX:
            // param = produit: Partial<ligneProduit>
            const lowerTotal = state.totaux;
            const produitToDelete: Partial<LigneProduit> = action.payload;

            if (produitToDelete.tva && typeof produitToDelete.tva === 'string') {
                produitToDelete.tva = Number.parseFloat(produitToDelete.tva);
            }

            if (produitToDelete.ttc && typeof produitToDelete.ttc === 'string') {
                produitToDelete.ttc = Number.parseFloat(produitToDelete.ttc);
            }

            lowerTotal.ht -= produitToDelete.ht ? produitToDelete.ht : 0;
            lowerTotal.tva -= produitToDelete.tva ? (produitToDelete.tva as number) : 0;
            // eslint-disable-next-line no-unsafe-optional-chaining
            lowerTotal.txtva -= produitToDelete.txtva?.value ? produitToDelete.txtva?.value : 0;
            lowerTotal.ttc -= produitToDelete.ttc ? (produitToDelete.ttc as number) : 0;

            newState = {
                ...state,
                totaux: lowerTotal,
            };
            break;
        case ACTIONS.ORDER_RUB_FACTS:
            newState = {
                ...state,
                rubFacts: [
                    ...new Map(orderRubFacts(state.rubFacts).map((m) => [m.id, m])).values(),
                ],
            };
            break;
        case ACTIONS.FILTER_RUB_FACTS:
            // param = defDomain: number
            const selectedDomaine = action.payload.domaine;
            const selectedRubFacts = action.payload.rubfacts;

            // if (selectedDomaine === 0) break;

            /**
             * Garde uniquement les rubriques qui :
             *  1. Si le domaine sélectionné est une formalité (id 3) et que la rubrique n'est pas une vacation (VAC) ;
             *  2. Si le domaine sélectionné se trouve dans leur tableau de domaines.
             */
            const filteredRubFacts =
                selectedDomaine !== 0
                    ? selectedRubFacts.filter((rubFact: RubriqueFacturationApiObject) => {
                          if (selectedDomaine === 3 && rubFact.code === 'VAC') return false;
                          return !!rubFact.domainesAchats?.find(
                              (domaine) => domaine.domaine?.id === selectedDomaine,
                          );
                      })
                    : selectedRubFacts;

            newState = {
                ...state,
                rubFacts: [
                    ...new Map(orderRubFacts(filteredRubFacts).map((m) => [m.id, m])).values(),
                ],
            };
            break;
        case ACTIONS.ADD_RUB_FACTS:
            const newRubFacts: RubriqueFacturationApiObject[] = [
                ...state.rubFacts,
                ...action.payload,
            ];

            newState = {
                ...state,
                rubFacts: [...new Map(orderRubFacts(newRubFacts).map((m) => [m.id, m])).values()],
            };
            break;
        case ACTIONS.ADD_RUB_FACT:
            const newRubFactsOneElement: RubriqueFacturationApiObject[] = [
                ...state.rubFacts,
                action.payload,
            ];

            newState = {
                ...state,
                rubFacts: [
                    ...new Map(orderRubFacts(newRubFactsOneElement).map((m) => [m.id, m])).values(),
                ],
            };
            break;

        case ACTIONS.SET_RUB_FACT:
            const setRubFacts: RubriqueFacturationApiObject[] = [...action.payload];

            newState = {
                ...state,
                rubFacts: [...new Map(orderRubFacts(setRubFacts).map((m) => [m.id, m])).values()],
            };
            break;
        case ACTIONS.SET_TAUX_TVA:
            const tauxTvaEntityFormat: FilteredTauxTva[] = [...action.payload];
            const tauxTvaList: Array<number> = [];

            tauxTvaEntityFormat.forEach((tva) => {
                if (tva.tauxAchat !== null && tva.tauxAchat !== undefined) {
                    tauxTvaList.push(+tva.tauxAchat.toFixed(3));
                }
            });

            newState = {
                ...state,
                txTva: tauxTvaList,
            };

            break;
        case ACTIONS.DISPLAY_SELLS:
            newState = {
                ...state,
                displaySells: action.payload,
            };
            break;
        case ACTIONS.DISPLAY_SWITCH_TO_SEE_SELLS:
            if (!_.isEqual(state.displaySwitchToSeeSells, action.payload)) {
                newState = {
                    ...state,
                    displaySwitchToSeeSells: action.payload,
                };
            } else {
                return state;
            }
            break;
        case ACTIONS.SET_DRAFT_NOTES:
            if (!_.isEqual(state.draftNotes, action.payload)) {
                newState = {
                    ...state,
                    draftNotes: [...action.payload],
                };
            } else {
                return state;
            }
            break;
        default:
            newState = { ...state };
            break;
    }

    return newState;
};

export default FactureFormReducer;
