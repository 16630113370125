import {
    URLSearchParamsInit,
    createSearchParams,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';

/**
 * Usage :
 *
 *  - urlParameters : retreive searchParameters passed in url
 *  - setUrlParameters : set url parameters then navigate to this specific url
 */

export type URLParameters = URLSearchParamsInit | undefined;

const useUrlParameters = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams();

    const urlParameters = Object.fromEntries(searchParams.entries());

    const setUrlParameters = (newUrlParameters: URLSearchParamsInit) => {
        navigate({
            // set search params
            pathname: location.pathname,
            search: `?${createSearchParams(newUrlParameters)}`,
        });
    };

    const addUrlParameters = (toAddParameters: Record<string, string | string[]>) => {
        const parameters = {
            ...urlParameters,
            ...toAddParameters,
        };
        if (
            createSearchParams(parameters).toString() !==
            createSearchParams(urlParameters).toString()
        ) {
            navigate({
                pathname: location.pathname,
                search: `?${createSearchParams(parameters)}`,
            });
        }
    };

    return { urlParameters, setUrlParameters, addUrlParameters };
};

export default useUrlParameters;
