import * as React from 'react';
import { InformationCard, InformationCardProps } from '@europrocurement/flexy-components';
import { OffresOffreRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { JsonLdData } from '../forms/types';
import { EntityPreview } from '../components/types';

type EntityType = JsonLdData<OffresOffreRead>;

const OfferPreview: React.FC<EntityPreview<EntityType>> = function (props) {
    const { entity, onEdit } = props;

    const cardTitle = entity.libelle;
    const cardItems: InformationCardProps['items'] = [
        {
            label: 'Visibilité',
            value: entity.visibilite ?? null,
        },
        {
            label: 'Domaine',
            value: entity.domaine?.libelle ?? null,
        },
    ];

    return (
        <InformationCard
            title={cardTitle}
            items={cardItems}
            button={{
                label: 'Éditer',
                onClick: onEdit,
            }}
        />
    );
};

export default OfferPreview;
