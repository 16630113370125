import * as React from 'react';
import { ContactCard, ModalContext } from '@europrocurement/flexy-components';
import { Prescripteur } from '@europrocurement/l2d-domain';
import { Grid } from '@mui/material';
import ContactForm from '@b2d/pages/Prescripteurs/forms/ContactForm';
import { customizerSelector, prescribersApi } from '@b2d/redux/RootStore';
import ActionModal from '@b2d/pages/Prescripteurs/views/detail/forms/ActionModal';
import { ContactJsonldTiersRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';

import { useSelector } from 'react-redux';

type PrescriberContactsProps = {
    prescriber: Prescripteur;
    afterSubmit: () => void;
};

const PrescriberContacts: React.FunctionComponent<PrescriberContactsProps> = function (props) {
    const { prescriber, afterSubmit } = props;

    const { xIdSociete } = useSelector(customizerSelector);

    const { modalActions } = React.useContext(ModalContext);
    const { contacts } = prescriber;

    // Compare function to determine default order of contacts
    const defaultContactSort = (
        elementA: ContactJsonldTiersRead,
        elementB: ContactJsonldTiersRead,
    ) => {
        const aMain = elementA.principal;
        const bMain = elementB.principal;
        const aBilling = elementA.comptabilite;

        if (aMain) {
            return -1;
        }

        if (aBilling && !bMain) {
            return -1;
        }

        return 1;
    };

    const sortedContacts = [...contacts].sort(defaultContactSort);

    const handleEditContact = (contact: ContactJsonldTiersRead) => {
        if (prescriber.id) {
            modalActions.call(
                <ContactForm
                    entity={contact}
                    prescriber={prescriber}
                    afterSubmit={afterSubmit}
                />,
            );
        }
    };

    const deleteContact = async (contactId: number | undefined) => {
        if (!contactId) {
            return;
        }

        const prescriberContacts = prescriber.contacts.filter(
            (prescriberContact) => prescriberContact.id !== contactId,
        );
        if (prescriber && prescriber.id) {
            await prescribersApi.updateContactsPrescripteurTiersItem({
                xIdSociete,
                id: prescriber.id.toString(),
                tiersPrescripteurJsonldPrescripteurWriteContacts: {
                    contacts: prescriberContacts,
                },
            });
            afterSubmit();
        }
    };

    const handleDeleteContact = (contact: ContactJsonldTiersRead) => {
        if (prescriber.id) {
            modalActions.call(
                <ActionModal
                    onClose={modalActions.close}
                    asyncAction={() => deleteContact(contact.id)}
                    title="Suppression d'un contact"
                    actionText="Voulez-vous supprimer ce contact ?"
                    actionLabel="Confirmer suppression"
                />,
            );
        }
    };

    return (
        <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
            {sortedContacts.map((contact, _, array) => (
                <Grid
                    key={`contact_card_${contact.id}`}
                    item
                    xs={12}
                    md={6}
                    lg={array.length > 2 ? 4 : 6}
                >
                    <ContactCard
                        isBordered
                        contact={contact}
                        editAction={() => handleEditContact(contact)}
                        deleteAction={() => handleDeleteContact(contact)}
                        isDeletable={contact.principal === false && contact.comptabilite === false}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default PrescriberContacts;
