import {
    APIENV,
    FACTURE_FORMALITE_SLICE_NAME,
    FactureFormaliteConfiguration,
    FactureFormaliteConfigurationParameters,
    createFactureFormaliteReducer,
    getCustomiserState,
} from '@europrocurement/l2d-domain';
import { combineReducers } from '@reduxjs/toolkit';
import { FactureApi } from '@europrocurement/l2d-domain/openApi/ApiFacturation';
import { updateToken } from '../../../keycloak';

/**
 * Configuation
 */
const configFactureFormalites: FactureFormaliteConfigurationParameters = {
    accessToken: updateToken,
    basePath: APIENV.apis.formalite[getCustomiserState().api.formalite],
};

export const factureFormaliteApi = new FactureApi(
    new FactureFormaliteConfiguration(configFactureFormalites),
);

export const { factureFormaliteDataSourcesThunks, factureFormaliteSlice } =
    createFactureFormaliteReducer(configFactureFormalites);

export const combinedFactureFormaliteReducer = combineReducers({
    [FACTURE_FORMALITE_SLICE_NAME]: factureFormaliteSlice.reducer,
});
export const getFactureFormalite = factureFormaliteDataSourcesThunks.main.getData;
export const selectFactureFormalite = factureFormaliteDataSourcesThunks.main.getSelected;
