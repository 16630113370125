/* tslint:disable */
/* eslint-disable */
/**
 * Europrocurement Api Facturation
 * API de gestion des facturation
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiDossiersDossierFacturesGetCollection200Response
 */
export interface ApiDossiersDossierFacturesGetCollection200Response {
    /**
     * 
     * @type {Array<FactureJsonldFactureRead>}
     * @memberof ApiDossiersDossierFacturesGetCollection200Response
     */
    'hydra:member': Array<FactureJsonldFactureRead>;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierFacturesGetCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {ApiDossiersDossierFacturesGetCollection200ResponseHydraView}
     * @memberof ApiDossiersDossierFacturesGetCollection200Response
     */
    'hydra:view'?: ApiDossiersDossierFacturesGetCollection200ResponseHydraView;
    /**
     * 
     * @type {ApiDossiersDossierFacturesGetCollection200ResponseHydraSearch}
     * @memberof ApiDossiersDossierFacturesGetCollection200Response
     */
    'hydra:search'?: ApiDossiersDossierFacturesGetCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFacturesGetCollection200ResponseHydraSearch
 */
export interface ApiDossiersDossierFacturesGetCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<ApiDossiersDossierFacturesGetCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<ApiDossiersDossierFacturesGetCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFacturesGetCollection200ResponseHydraSearchHydraMappingInner
 */
export interface ApiDossiersDossierFacturesGetCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFacturesGetCollection200ResponseHydraView
 */
export interface ApiDossiersDossierFacturesGetCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFacturesGetCollection401Response
 */
export interface ApiDossiersDossierFacturesGetCollection401Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection401Response
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFacturesGetCollection403Response
 */
export interface ApiDossiersDossierFacturesGetCollection403Response {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection403Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiDossiersDossierFacturesGetCollection403ResponseException}
     * @memberof ApiDossiersDossierFacturesGetCollection403Response
     */
    'exception'?: ApiDossiersDossierFacturesGetCollection403ResponseException;
}
/**
 * 
 * @export
 * @interface ApiDossiersDossierFacturesGetCollection403ResponseException
 */
export interface ApiDossiersDossierFacturesGetCollection403ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiDossiersDossierFacturesGetCollection403ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiDossiersDossierFacturesGetCollection403ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiFacturesIdGet404Response
 */
export interface ApiFacturesIdGet404Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesIdGet404Response
     */
    'error'?: string;
    /**
     * 
     * @type {ApiFacturesIdGet404ResponseException}
     * @memberof ApiFacturesIdGet404Response
     */
    'exception'?: ApiFacturesIdGet404ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFacturesIdGet404ResponseException
 */
export interface ApiFacturesIdGet404ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesIdGet404ResponseException
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiFacturesIdGet404ResponseException
     */
    'code'?: number;
}
/**
 * 
 * @export
 * @interface ApiFacturesIdPut400Response
 */
export interface ApiFacturesIdPut400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesIdPut400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiFacturesIdPut400ResponseException}
     * @memberof ApiFacturesIdPut400Response
     */
    'exception': ApiFacturesIdPut400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFacturesIdPut400ResponseException
 */
export interface ApiFacturesIdPut400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesIdPut400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiFacturesIdPut400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiFacturesIdPut400ResponseExceptionViolationsInner>}
     * @memberof ApiFacturesIdPut400ResponseException
     */
    'violations'?: Array<ApiFacturesIdPut400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiFacturesIdPut400ResponseExceptionViolationsInner
 */
export interface ApiFacturesIdPut400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiFacturesIdPut400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiFacturesIdPut400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiFacturesMultiplesPost400Response
 */
export interface ApiFacturesMultiplesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesMultiplesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiFacturesMultiplesPost400ResponseException}
     * @memberof ApiFacturesMultiplesPost400Response
     */
    'exception': ApiFacturesMultiplesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFacturesMultiplesPost400ResponseException
 */
export interface ApiFacturesMultiplesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesMultiplesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiFacturesMultiplesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiFacturesMultiplesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiFacturesMultiplesPost400ResponseException
     */
    'violations'?: Array<ApiFacturesMultiplesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiFacturesMultiplesPost400ResponseExceptionViolationsInner
 */
export interface ApiFacturesMultiplesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiFacturesMultiplesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiFacturesMultiplesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApiFacturesPost400Response
 */
export interface ApiFacturesPost400Response {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesPost400Response
     */
    'error': string;
    /**
     * 
     * @type {ApiFacturesPost400ResponseException}
     * @memberof ApiFacturesPost400Response
     */
    'exception': ApiFacturesPost400ResponseException;
}
/**
 * 
 * @export
 * @interface ApiFacturesPost400ResponseException
 */
export interface ApiFacturesPost400ResponseException {
    /**
     * 
     * @type {string}
     * @memberof ApiFacturesPost400ResponseException
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof ApiFacturesPost400ResponseException
     */
    'code': number;
    /**
     * liste des propiétés invalides
     * @type {Array<ApiFacturesPost400ResponseExceptionViolationsInner>}
     * @memberof ApiFacturesPost400ResponseException
     */
    'violations'?: Array<ApiFacturesPost400ResponseExceptionViolationsInner>;
}
/**
 * 
 * @export
 * @interface ApiFacturesPost400ResponseExceptionViolationsInner
 */
export interface ApiFacturesPost400ResponseExceptionViolationsInner {
    /**
     * nom de la propriété invalide
     * @type {string}
     * @memberof ApiFacturesPost400ResponseExceptionViolationsInner
     */
    'property'?: string;
    /**
     * message descriptif de la violation
     * @type {string}
     * @memberof ApiFacturesPost400ResponseExceptionViolationsInner
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface FactureFactureCreate
 */
export interface FactureFactureCreate {
    /**
     * 
     * @type {string}
     * @memberof FactureFactureCreate
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureCreate>}
     * @memberof FactureFactureCreate
     */
    'lignes'?: Array<TblFligneFactureCreate>;
}
/**
 * 
 * @export
 * @interface FactureFactureRead
 */
export interface FactureFactureRead {
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idDossierFormalite'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureFactureRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureFactureRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureRead>}
     * @memberof FactureFactureRead
     */
    'lignes'?: Array<TblFligneFactureRead>;
}
/**
 * 
 * @export
 * @interface FactureFactureReadPdf
 */
export interface FactureFactureReadPdf {
    /**
     * Lien vers le pdf de la facture
     * @type {string}
     * @memberof FactureFactureReadPdf
     */
    'pdf'?: string;
    /**
     * Date d\'expiration des liens vers les pdf de la facture
     * @type {string}
     * @memberof FactureFactureReadPdf
     */
    'expires'?: string;
}
/**
 * 
 * @export
 * @interface FactureFactureUpdate
 */
export interface FactureFactureUpdate {
    /**
     * 
     * @type {string}
     * @memberof FactureFactureUpdate
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureFactureUpdate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureUpdate>}
     * @memberof FactureFactureUpdate
     */
    'lignes'?: Array<TblFligneFactureUpdate>;
}
/**
 * 
 * @export
 * @interface FactureJsonld
 */
export interface FactureJsonld {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof FactureJsonld
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonld
     */
    '@type'?: string;
}
/**
 * @type FactureJsonldContext
 * @export
 */
export type FactureJsonldContext = FactureJsonldContextOneOf | string;

/**
 * 
 * @export
 * @interface FactureJsonldContextOneOf
 */
export interface FactureJsonldContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof FactureJsonldContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldContextOneOf
     */
    'hydra': FactureJsonldContextOneOfHydraEnum;
}

export const FactureJsonldContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type FactureJsonldContextOneOfHydraEnum = typeof FactureJsonldContextOneOfHydraEnum[keyof typeof FactureJsonldContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface FactureJsonldFactureCreate
 */
export interface FactureJsonldFactureCreate {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof FactureJsonldFactureCreate
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureCreate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureCreate
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureCreate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureCreate>}
     * @memberof FactureJsonldFactureCreate
     */
    'lignes'?: Array<TblFligneJsonldFactureCreate>;
}
/**
 * 
 * @export
 * @interface FactureJsonldFactureRead
 */
export interface FactureJsonldFactureRead {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof FactureJsonldFactureRead
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idDossierFormalite'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FactureJsonldFactureRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FactureJsonldFactureRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureRead>}
     * @memberof FactureJsonldFactureRead
     */
    'lignes'?: Array<TblFligneJsonldFactureRead>;
}
/**
 * 
 * @export
 * @interface FactureJsonldFactureReadPdf
 */
export interface FactureJsonldFactureReadPdf {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof FactureJsonldFactureReadPdf
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureReadPdf
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureReadPdf
     */
    '@type'?: string;
    /**
     * Lien vers le pdf de la facture
     * @type {string}
     * @memberof FactureJsonldFactureReadPdf
     */
    'pdf'?: string;
    /**
     * Date d\'expiration des liens vers les pdf de la facture
     * @type {string}
     * @memberof FactureJsonldFactureReadPdf
     */
    'expires'?: string;
}
/**
 * 
 * @export
 * @interface FactureJsonldFactureUpdate
 */
export interface FactureJsonldFactureUpdate {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof FactureJsonldFactureUpdate
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureUpdate
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureUpdate
     */
    'numero'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FactureJsonldFactureUpdate
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureUpdate>}
     * @memberof FactureJsonldFactureUpdate
     */
    'lignes'?: Array<TblFligneJsonldFactureUpdate>;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesFactureMutlipleCreate
 */
export interface FacturesMultiplesFactureMutlipleCreate {
    /**
     * Liste des dossier à facturer
     * @type {Array<string>}
     * @memberof FacturesMultiplesFactureMutlipleCreate
     */
    'dossiers'?: Array<string>;
    /**
     * 
     * @type {Array<TblFligneFactureMutlipleCreate>}
     * @memberof FacturesMultiplesFactureMutlipleCreate
     */
    'lignes'?: Array<TblFligneFactureMutlipleCreate>;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesFactureRead
 */
export interface FacturesMultiplesFactureRead {
    /**
     * 
     * @type {FacturesMultiplesFactureReadFacture}
     * @memberof FacturesMultiplesFactureRead
     */
    'facture'?: FacturesMultiplesFactureReadFacture | null;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesFactureReadFacture
 */
export interface FacturesMultiplesFactureReadFacture {
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idDossierFormalite'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureRead>}
     * @memberof FacturesMultiplesFactureReadFacture
     */
    'lignes'?: Array<TblFligneFactureRead>;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesJsonldFactureMutlipleCreate
 */
export interface FacturesMultiplesJsonldFactureMutlipleCreate {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof FacturesMultiplesJsonldFactureMutlipleCreate
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureMutlipleCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureMutlipleCreate
     */
    '@type'?: string;
    /**
     * Liste des dossier à facturer
     * @type {Array<string>}
     * @memberof FacturesMultiplesJsonldFactureMutlipleCreate
     */
    'dossiers'?: Array<string>;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureMutlipleCreate>}
     * @memberof FacturesMultiplesJsonldFactureMutlipleCreate
     */
    'lignes'?: Array<TblFligneJsonldFactureMutlipleCreate>;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesJsonldFactureRead
 */
export interface FacturesMultiplesJsonldFactureRead {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof FacturesMultiplesJsonldFactureRead
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {FacturesMultiplesJsonldFactureReadFacture}
     * @memberof FacturesMultiplesJsonldFactureRead
     */
    'facture'?: FacturesMultiplesJsonldFactureReadFacture | null;
}
/**
 * 
 * @export
 * @interface FacturesMultiplesJsonldFactureReadFacture
 */
export interface FacturesMultiplesJsonldFactureReadFacture {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idDossierFormalite'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureRead>}
     * @memberof FacturesMultiplesJsonldFactureReadFacture
     */
    'lignes'?: Array<TblFligneJsonldFactureRead>;
}
/**
 * 
 * @export
 * @interface TblFacture2FactureRead
 */
export interface TblFacture2FactureRead {
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idDossierFormalite'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2FactureRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2FactureRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2FactureRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneFactureRead>}
     * @memberof TblFacture2FactureRead
     */
    'lignes'?: Array<TblFligneFactureRead>;
}
/**
 * 
 * @export
 * @interface TblFacture2JsonldFactureRead
 */
export interface TblFacture2JsonldFactureRead {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof TblFacture2JsonldFactureRead
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numero'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numeroDossier'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateFacture'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'prescripteur'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'client'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'raisonSociale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'codePostal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'ville'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tiersCorrespondance'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'raisonSocialeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse1C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse2C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse3C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adresse4C'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'codePostalC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'villeC'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cliCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'totalHt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'totalTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'totalTtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'paye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateExport'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'societe'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'contact'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'gratuit'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'remise'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'delaiPaiement'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'userCreation'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateCreation'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'contactClient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaBase1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaBase2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaBase3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaBase4'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaTx1'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaTx2'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaTx3'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaTx4'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaMontant1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaMontant2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaMontant3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tvaMontant4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'montantPaiementRecu'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'userMaj'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateMaj'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'compta'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'contactFacture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'contactCorrespondance'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'rrrPaye'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'rrrRefuse'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idDossierFormalite'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureEnPreparation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'societeFacture'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'facturationDiverse'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idDossierFactureDiverse'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'comptaCegid'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateExportCegid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidTvaEncaissement'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidRegimeTva'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidLibelle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'statFacture'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureAvoir'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureAvoirRemise'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidRejetExportCompta'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidRejetDateExport'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'formalite2'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjNomTgi'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjDateVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjAffaire'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjDetailVente'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateEcheanceFacture'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'adjDossier'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'presentationAdju'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateLettrageReglement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'editionReleve'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nonRelancable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idUserNonRelancable'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'rbtFraisAchats'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idFactureRefAvoir'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idFactureReleveAvoir'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'venteIndirecte'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'horsParrainage'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tiersParrain'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tauxParrainG'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'tauxParrainHg'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'traAchat'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateTraAchat'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'factureMultiple'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'comNonRelancable'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'exportG3'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'referenceInterne'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'parrainageEdition'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'retourNpai'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nImpNeotouch'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'brouillonMultiple'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'commande'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idDdm'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'intercoTraitee'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateTraitementInterco'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numeroFactureInterco'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'coef'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'comRegie'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateComRegie'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'fournisseurComRegie'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idTcf'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'releveRrrConfrereFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'fnpExclus'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'cegidFile'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'justifsDetailsInterdit'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nJustifsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nJustifsPayants'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'nJustifsGratuits'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idPfj'?: number;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numEngagement'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'kdoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateTraitementKdo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFacture2JsonldFactureRead
     */
    'idUserKdoTraite'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dnidTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateTraitementDnid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'numAchatRrr'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFacture2JsonldFactureRead
     */
    'dateAchatRrr'?: string | null;
    /**
     * 
     * @type {Array<TblFligneJsonldFactureRead>}
     * @memberof TblFacture2JsonldFactureRead
     */
    'lignes'?: Array<TblFligneJsonldFactureRead>;
}
/**
 * 
 * @export
 * @interface TblFligneFactureCreate
 */
export interface TblFligneFactureCreate {
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneFactureCreate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureCreate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureCreate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureCreate
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureCreate
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneFactureMutlipleCreate
 */
export interface TblFligneFactureMutlipleCreate {
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneFactureMutlipleCreate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureMutlipleCreate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureMutlipleCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureMutlipleCreate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureMutlipleCreate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureMutlipleCreate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureMutlipleCreate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureMutlipleCreate
     */
    'idAnnonce'?: number | null;
}
/**
 * 
 * @export
 * @interface TblFligneFactureRead
 */
export interface TblFligneFactureRead {
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'id'?: number;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'jal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'debours'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'compte'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'flux'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'factureDiverse'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'dprTypeTarif'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idTat'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idArticle'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrAvoInterdit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'htReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'dnidVersionPresta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idDrt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrPaye'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrRefuse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrAvoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'dateTraitementRrrAvo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'tarifLogoEnteteV3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrAvoReferenceTheorique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'rrrAvoReferenceReel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'tauxRrrAvoReel'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'dateRrrAvoReel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureRead
     */
    'idUserRrrAvoReel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneFactureRead
     */
    'cmdDrtIdDetails'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureRead
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneFactureUpdate
 */
export interface TblFligneFactureUpdate {
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneFactureUpdate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureUpdate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureUpdate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneFactureUpdate
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneFactureUpdate
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureCreate
 */
export interface TblFligneJsonldFactureCreate {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof TblFligneJsonldFactureCreate
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    '@type'?: string;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneJsonldFactureCreate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureCreate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureCreate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureCreate
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureCreate
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureMutlipleCreate
 */
export interface TblFligneJsonldFactureMutlipleCreate {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    '@type'?: string;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureMutlipleCreate
     */
    'idAnnonce'?: number | null;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureRead
 */
export interface TblFligneJsonldFactureRead {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof TblFligneJsonldFactureRead
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'id'?: number;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'jal'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'montant'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'debours'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'compte'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'affiche'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'flux'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'formalite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'factureDiverse'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidCompte'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidSp1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidSp2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidTl1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidTl2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'cegidTl3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'dossier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'dprTypeTarif'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idTat'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idArticle'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrAvoInterdit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'htReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'dnidVersionPresta'?: number;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idDrt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrPaye'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'datePaiementRrr'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrRefuse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrAvoTraite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'dateTraitementRrrAvo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'tarifLogoEnteteV3'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrAvoReferenceTheorique'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'rrrAvoReferenceReel'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'tauxRrrAvoReel'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'dateRrrAvoReel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureRead
     */
    'idUserRrrAvoReel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TblFligneJsonldFactureRead
     */
    'cmdDrtIdDetails'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureRead
     */
    'articleDossierKey'?: string | null;
}
/**
 * 
 * @export
 * @interface TblFligneJsonldFactureUpdate
 */
export interface TblFligneJsonldFactureUpdate {
    /**
     * 
     * @type {FactureJsonldContext}
     * @memberof TblFligneJsonldFactureUpdate
     */
    '@context'?: FactureJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    '@type'?: string;
    /**
     * Numero de la ligne (permet de jouer sur l\'ordre d\'apparition dans le pdf)
     * @type {number}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'numeroLigne'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'rubriqueFacturation': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'libelle': string | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'unite': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'quantite': number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'prixBase': string | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'tauxTva': number | null;
    /**
     * 
     * @type {number}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'idAnnonce'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TblFligneJsonldFactureUpdate
     */
    'articleDossierKey'?: string | null;
}

/**
 * FactureApi - axios parameter creator
 * @export
 */
export const FactureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtenir la liste des factures d\'un dossier
         * @summary Obtenir la liste des factures d\'un dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFacturesGetCollection: async (dossier: string, xIdSociete?: number, page?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dossier' is not null or undefined
            assertParamExists('apiDossiersDossierFacturesGetCollection', 'dossier', dossier)
            const localVarPath = `/facturation/dossiers/{dossier}/factures`
                .replace(`{${"dossier"}}`, encodeURIComponent(String(dossier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (dossierPrescripteurId !== undefined) {
                localVarQueryParameter['dossier.prescripteur.id'] = dossierPrescripteurId;
            }

            if (dossierPrescripteurId2) {
                localVarQueryParameter['dossier.prescripteur.id[]'] = dossierPrescripteurId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtenir la liste des factures
         * @summary Obtenir la liste des factures
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesGetCollection: async (xIdSociete?: number, page?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/facturation/factures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (dossierNumero !== undefined) {
                localVarQueryParameter['dossier.numero'] = dossierNumero;
            }

            if (dossierId !== undefined) {
                localVarQueryParameter['dossier.id'] = dossierId;
            }

            if (dossierId2) {
                localVarQueryParameter['dossier.id[]'] = dossierId2;
            }

            if (dossierPrescripteurId !== undefined) {
                localVarQueryParameter['dossier.prescripteur.id'] = dossierPrescripteurId;
            }

            if (dossierPrescripteurId2) {
                localVarQueryParameter['dossier.prescripteur.id[]'] = dossierPrescripteurId2;
            }

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer un avoir pour la facture
         * @summary Créer un avoir pour la facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonld} factureJsonld The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdAvoirPut: async (id: string, factureJsonld: FactureJsonld, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdAvoirPut', 'id', id)
            // verify required parameter 'factureJsonld' is not null or undefined
            assertParamExists('apiFacturesIdAvoirPut', 'factureJsonld', factureJsonld)
            const localVarPath = `/facturation/factures/{id}/avoir`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Supprimer une facture
         * @summary Supprimer une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdDelete: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdDelete', 'id', id)
            const localVarPath = `/facturation/factures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer les informations d\'une facture
         * @summary Récupérer les informations d\'une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdGet: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdGet', 'id', id)
            const localVarPath = `/facturation/factures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Récupérer le pdf d\'une facture
         * @summary Récupérer le pdf d\'une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdPdfGet: async (id: string, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdPdfGet', 'id', id)
            const localVarPath = `/facturation/factures/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mettre à jour une facture
         * @summary Mettre à jour une facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonldFactureUpdate} factureJsonldFactureUpdate The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdPut: async (id: string, factureJsonldFactureUpdate: FactureJsonldFactureUpdate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdPut', 'id', id)
            // verify required parameter 'factureJsonldFactureUpdate' is not null or undefined
            assertParamExists('apiFacturesIdPut', 'factureJsonldFactureUpdate', factureJsonldFactureUpdate)
            const localVarPath = `/facturation/factures/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureJsonldFactureUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Valider une facture
         * @summary Valider une facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonld} factureJsonld The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdValidatePut: async (id: string, factureJsonld: FactureJsonld, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFacturesIdValidatePut', 'id', id)
            // verify required parameter 'factureJsonld' is not null or undefined
            assertParamExists('apiFacturesIdValidatePut', 'factureJsonld', factureJsonld)
            const localVarPath = `/facturation/factures/{id}/validate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureJsonld, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Créer une facture
         * @summary Créer une facture
         * @param {FactureJsonldFactureCreate} factureJsonldFactureCreate The new Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesPost: async (factureJsonldFactureCreate: FactureJsonldFactureCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'factureJsonldFactureCreate' is not null or undefined
            assertParamExists('apiFacturesPost', 'factureJsonldFactureCreate', factureJsonldFactureCreate)
            const localVarPath = `/facturation/factures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(factureJsonldFactureCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FactureApi - functional programming interface
 * @export
 */
export const FactureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FactureApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtenir la liste des factures d\'un dossier
         * @summary Obtenir la liste des factures d\'un dossier
         * @param {string} dossier FproDossier identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDossiersDossierFacturesGetCollection(dossier: string, xIdSociete?: number, page?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierFacturesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDossiersDossierFacturesGetCollection(dossier, xIdSociete, page, dossierNumero, dossierId, dossierId2, dossierPrescripteurId, dossierPrescripteurId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtenir la liste des factures
         * @summary Obtenir la liste des factures
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {number} [page] The collection page number
         * @param {string} [dossierNumero] 
         * @param {number} [dossierId] 
         * @param {Array<number>} [dossierId2] 
         * @param {number} [dossierPrescripteurId] 
         * @param {Array<number>} [dossierPrescripteurId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesGetCollection(xIdSociete?: number, page?: number, dossierNumero?: string, dossierId?: number, dossierId2?: Array<number>, dossierPrescripteurId?: number, dossierPrescripteurId2?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiDossiersDossierFacturesGetCollection200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesGetCollection(xIdSociete, page, dossierNumero, dossierId, dossierId2, dossierPrescripteurId, dossierPrescripteurId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Créer un avoir pour la facture
         * @summary Créer un avoir pour la facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonld} factureJsonld The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdAvoirPut(id: string, factureJsonld: FactureJsonld, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdAvoirPut(id, factureJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Supprimer une facture
         * @summary Supprimer une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdDelete(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdDelete(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer les informations d\'une facture
         * @summary Récupérer les informations d\'une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdGet(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdGet(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Récupérer le pdf d\'une facture
         * @summary Récupérer le pdf d\'une facture
         * @param {string} id TblFacture2 identifier
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdPdfGet(id: string, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureReadPdf>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdPdfGet(id, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mettre à jour une facture
         * @summary Mettre à jour une facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonldFactureUpdate} factureJsonldFactureUpdate The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdPut(id: string, factureJsonldFactureUpdate: FactureJsonldFactureUpdate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdPut(id, factureJsonldFactureUpdate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Valider une facture
         * @summary Valider une facture
         * @param {string} id TblFacture2 identifier
         * @param {FactureJsonld} factureJsonld The updated Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesIdValidatePut(id: string, factureJsonld: FactureJsonld, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesIdValidatePut(id, factureJsonld, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Créer une facture
         * @summary Créer une facture
         * @param {FactureJsonldFactureCreate} factureJsonldFactureCreate The new Facture resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesPost(factureJsonldFactureCreate: FactureJsonldFactureCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FactureJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesPost(factureJsonldFactureCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FactureApi - factory interface
 * @export
 */
export const FactureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FactureApiFp(configuration)
    return {
        /**
         * Obtenir la liste des factures d\'un dossier
         * @summary Obtenir la liste des factures d\'un dossier
         * @param {FactureApiApiDossiersDossierFacturesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDossiersDossierFacturesGetCollection(requestParameters: FactureApiApiDossiersDossierFacturesGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierFacturesGetCollection200Response> {
            return localVarFp.apiDossiersDossierFacturesGetCollection(requestParameters.dossier, requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtenir la liste des factures
         * @summary Obtenir la liste des factures
         * @param {FactureApiApiFacturesGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesGetCollection(requestParameters: FactureApiApiFacturesGetCollectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ApiDossiersDossierFacturesGetCollection200Response> {
            return localVarFp.apiFacturesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer un avoir pour la facture
         * @summary Créer un avoir pour la facture
         * @param {FactureApiApiFacturesIdAvoirPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdAvoirPut(requestParameters: FactureApiApiFacturesIdAvoirPutRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesIdAvoirPut(requestParameters.id, requestParameters.factureJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Supprimer une facture
         * @summary Supprimer une facture
         * @param {FactureApiApiFacturesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdDelete(requestParameters: FactureApiApiFacturesIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiFacturesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer les informations d\'une facture
         * @summary Récupérer les informations d\'une facture
         * @param {FactureApiApiFacturesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdGet(requestParameters: FactureApiApiFacturesIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Récupérer le pdf d\'une facture
         * @summary Récupérer le pdf d\'une facture
         * @param {FactureApiApiFacturesIdPdfGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdPdfGet(requestParameters: FactureApiApiFacturesIdPdfGetRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureReadPdf> {
            return localVarFp.apiFacturesIdPdfGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Mettre à jour une facture
         * @summary Mettre à jour une facture
         * @param {FactureApiApiFacturesIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdPut(requestParameters: FactureApiApiFacturesIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesIdPut(requestParameters.id, requestParameters.factureJsonldFactureUpdate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Valider une facture
         * @summary Valider une facture
         * @param {FactureApiApiFacturesIdValidatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesIdValidatePut(requestParameters: FactureApiApiFacturesIdValidatePutRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesIdValidatePut(requestParameters.id, requestParameters.factureJsonld, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
        /**
         * Créer une facture
         * @summary Créer une facture
         * @param {FactureApiApiFacturesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesPost(requestParameters: FactureApiApiFacturesPostRequest, options?: AxiosRequestConfig): AxiosPromise<FactureJsonldFactureRead> {
            return localVarFp.apiFacturesPost(requestParameters.factureJsonldFactureCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiDossiersDossierFacturesGetCollection operation in FactureApi.
 * @export
 * @interface FactureApiApiDossiersDossierFacturesGetCollectionRequest
 */
export interface FactureApiApiDossiersDossierFacturesGetCollectionRequest {
    /**
     * FproDossier identifier
     * @type {string}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossier: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {number}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierPrescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureApiApiDossiersDossierFacturesGetCollection
     */
    readonly dossierPrescripteurId2?: Array<number>
}

/**
 * Request parameters for apiFacturesGetCollection operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesGetCollectionRequest
 */
export interface FactureApiApiFacturesGetCollectionRequest {
    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly xIdSociete?: number

    /**
     * The collection page number
     * @type {number}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly page?: number

    /**
     * 
     * @type {string}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierNumero?: string

    /**
     * 
     * @type {number}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierId2?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierPrescripteurId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof FactureApiApiFacturesGetCollection
     */
    readonly dossierPrescripteurId2?: Array<number>
}

/**
 * Request parameters for apiFacturesIdAvoirPut operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdAvoirPutRequest
 */
export interface FactureApiApiFacturesIdAvoirPutRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdAvoirPut
     */
    readonly id: string

    /**
     * The updated Facture resource
     * @type {FactureJsonld}
     * @memberof FactureApiApiFacturesIdAvoirPut
     */
    readonly factureJsonld: FactureJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdAvoirPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdDelete operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdDeleteRequest
 */
export interface FactureApiApiFacturesIdDeleteRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdDelete
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdDelete
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdGet operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdGetRequest
 */
export interface FactureApiApiFacturesIdGetRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdPdfGet operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdPdfGetRequest
 */
export interface FactureApiApiFacturesIdPdfGetRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdPdfGet
     */
    readonly id: string

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdPdfGet
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdPut operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdPutRequest
 */
export interface FactureApiApiFacturesIdPutRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdPut
     */
    readonly id: string

    /**
     * The updated Facture resource
     * @type {FactureJsonldFactureUpdate}
     * @memberof FactureApiApiFacturesIdPut
     */
    readonly factureJsonldFactureUpdate: FactureJsonldFactureUpdate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdPut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesIdValidatePut operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesIdValidatePutRequest
 */
export interface FactureApiApiFacturesIdValidatePutRequest {
    /**
     * TblFacture2 identifier
     * @type {string}
     * @memberof FactureApiApiFacturesIdValidatePut
     */
    readonly id: string

    /**
     * The updated Facture resource
     * @type {FactureJsonld}
     * @memberof FactureApiApiFacturesIdValidatePut
     */
    readonly factureJsonld: FactureJsonld

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesIdValidatePut
     */
    readonly xIdSociete?: number
}

/**
 * Request parameters for apiFacturesPost operation in FactureApi.
 * @export
 * @interface FactureApiApiFacturesPostRequest
 */
export interface FactureApiApiFacturesPostRequest {
    /**
     * The new Facture resource
     * @type {FactureJsonldFactureCreate}
     * @memberof FactureApiApiFacturesPost
     */
    readonly factureJsonldFactureCreate: FactureJsonldFactureCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FactureApiApiFacturesPost
     */
    readonly xIdSociete?: number
}

/**
 * FactureApi - object-oriented interface
 * @export
 * @class FactureApi
 * @extends {BaseAPI}
 */
export class FactureApi extends BaseAPI {
    /**
     * Obtenir la liste des factures d\'un dossier
     * @summary Obtenir la liste des factures d\'un dossier
     * @param {FactureApiApiDossiersDossierFacturesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiDossiersDossierFacturesGetCollection(requestParameters: FactureApiApiDossiersDossierFacturesGetCollectionRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiDossiersDossierFacturesGetCollection(requestParameters.dossier, requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtenir la liste des factures
     * @summary Obtenir la liste des factures
     * @param {FactureApiApiFacturesGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesGetCollection(requestParameters: FactureApiApiFacturesGetCollectionRequest = {}, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesGetCollection(requestParameters.xIdSociete, requestParameters.page, requestParameters.dossierNumero, requestParameters.dossierId, requestParameters.dossierId2, requestParameters.dossierPrescripteurId, requestParameters.dossierPrescripteurId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer un avoir pour la facture
     * @summary Créer un avoir pour la facture
     * @param {FactureApiApiFacturesIdAvoirPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdAvoirPut(requestParameters: FactureApiApiFacturesIdAvoirPutRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdAvoirPut(requestParameters.id, requestParameters.factureJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Supprimer une facture
     * @summary Supprimer une facture
     * @param {FactureApiApiFacturesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdDelete(requestParameters: FactureApiApiFacturesIdDeleteRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdDelete(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer les informations d\'une facture
     * @summary Récupérer les informations d\'une facture
     * @param {FactureApiApiFacturesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdGet(requestParameters: FactureApiApiFacturesIdGetRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Récupérer le pdf d\'une facture
     * @summary Récupérer le pdf d\'une facture
     * @param {FactureApiApiFacturesIdPdfGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdPdfGet(requestParameters: FactureApiApiFacturesIdPdfGetRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdPdfGet(requestParameters.id, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mettre à jour une facture
     * @summary Mettre à jour une facture
     * @param {FactureApiApiFacturesIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdPut(requestParameters: FactureApiApiFacturesIdPutRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdPut(requestParameters.id, requestParameters.factureJsonldFactureUpdate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Valider une facture
     * @summary Valider une facture
     * @param {FactureApiApiFacturesIdValidatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesIdValidatePut(requestParameters: FactureApiApiFacturesIdValidatePutRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesIdValidatePut(requestParameters.id, requestParameters.factureJsonld, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Créer une facture
     * @summary Créer une facture
     * @param {FactureApiApiFacturesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FactureApi
     */
    public apiFacturesPost(requestParameters: FactureApiApiFacturesPostRequest, options?: AxiosRequestConfig) {
        return FactureApiFp(this.configuration).apiFacturesPost(requestParameters.factureJsonldFactureCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FacturesMultiplesApi - axios parameter creator
 * @export
 */
export const FacturesMultiplesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Créer une facture multiple
         * @summary Créer une facture multiple
         * @param {FacturesMultiplesJsonldFactureMutlipleCreate} facturesMultiplesJsonldFactureMutlipleCreate The new Factures Multiples resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesMultiplesPost: async (facturesMultiplesJsonldFactureMutlipleCreate: FacturesMultiplesJsonldFactureMutlipleCreate, xIdSociete?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'facturesMultiplesJsonldFactureMutlipleCreate' is not null or undefined
            assertParamExists('apiFacturesMultiplesPost', 'facturesMultiplesJsonldFactureMutlipleCreate', facturesMultiplesJsonldFactureMutlipleCreate)
            const localVarPath = `/facturation/factures-multiples`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (xIdSociete != null) {
                localVarHeaderParameter['X-Id-Societe'] = typeof xIdSociete === 'string' 
                    ? xIdSociete 
                    : JSON.stringify(xIdSociete);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/ld+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facturesMultiplesJsonldFactureMutlipleCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacturesMultiplesApi - functional programming interface
 * @export
 */
export const FacturesMultiplesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacturesMultiplesApiAxiosParamCreator(configuration)
    return {
        /**
         * Créer une facture multiple
         * @summary Créer une facture multiple
         * @param {FacturesMultiplesJsonldFactureMutlipleCreate} facturesMultiplesJsonldFactureMutlipleCreate The new Factures Multiples resource
         * @param {number} [xIdSociete] Identifiant de la société
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFacturesMultiplesPost(facturesMultiplesJsonldFactureMutlipleCreate: FacturesMultiplesJsonldFactureMutlipleCreate, xIdSociete?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacturesMultiplesJsonldFactureRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFacturesMultiplesPost(facturesMultiplesJsonldFactureMutlipleCreate, xIdSociete, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacturesMultiplesApi - factory interface
 * @export
 */
export const FacturesMultiplesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacturesMultiplesApiFp(configuration)
    return {
        /**
         * Créer une facture multiple
         * @summary Créer une facture multiple
         * @param {FacturesMultiplesApiApiFacturesMultiplesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFacturesMultiplesPost(requestParameters: FacturesMultiplesApiApiFacturesMultiplesPostRequest, options?: AxiosRequestConfig): AxiosPromise<FacturesMultiplesJsonldFactureRead> {
            return localVarFp.apiFacturesMultiplesPost(requestParameters.facturesMultiplesJsonldFactureMutlipleCreate, requestParameters.xIdSociete, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiFacturesMultiplesPost operation in FacturesMultiplesApi.
 * @export
 * @interface FacturesMultiplesApiApiFacturesMultiplesPostRequest
 */
export interface FacturesMultiplesApiApiFacturesMultiplesPostRequest {
    /**
     * The new Factures Multiples resource
     * @type {FacturesMultiplesJsonldFactureMutlipleCreate}
     * @memberof FacturesMultiplesApiApiFacturesMultiplesPost
     */
    readonly facturesMultiplesJsonldFactureMutlipleCreate: FacturesMultiplesJsonldFactureMutlipleCreate

    /**
     * Identifiant de la société
     * @type {number}
     * @memberof FacturesMultiplesApiApiFacturesMultiplesPost
     */
    readonly xIdSociete?: number
}

/**
 * FacturesMultiplesApi - object-oriented interface
 * @export
 * @class FacturesMultiplesApi
 * @extends {BaseAPI}
 */
export class FacturesMultiplesApi extends BaseAPI {
    /**
     * Créer une facture multiple
     * @summary Créer une facture multiple
     * @param {FacturesMultiplesApiApiFacturesMultiplesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacturesMultiplesApi
     */
    public apiFacturesMultiplesPost(requestParameters: FacturesMultiplesApiApiFacturesMultiplesPostRequest, options?: AxiosRequestConfig) {
        return FacturesMultiplesApiFp(this.configuration).apiFacturesMultiplesPost(requestParameters.facturesMultiplesJsonldFactureMutlipleCreate, requestParameters.xIdSociete, options).then((request) => request(this.axios, this.basePath));
    }
}


