import React, { useCallback, useState, useMemo, useContext, FunctionComponent } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Chip, Stack, Typography } from '@mui/material';

import {
    ColumnDatatable,
    FiltersDatatableList,
    StoreDatatable,
} from '@europrocurement/flexy-datatable';
import {
    FactureAchatApiObject,
    BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID,
    MediaObject,
    isProcessingMediaObject,
    BDD_SWITCH_SOCIETES_IDS_TYPE,
    MediaObjectApiObject,
} from '@europrocurement/l2d-domain';
import { ListActionButton, ModalContext } from '@europrocurement/flexy-components';
import {
    FaOptionIcon,
    addFileIcon,
    changeCompanyIcon,
    deleteIcon,
    downloadIcon,
} from '@europrocurement/l2d-icons';

import { DataSource } from '@europrocurement/l2d-redux-utils';
import { KeycloakHasRole } from '@europrocurement/l2d-keycloak';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';

import generateB2DPath from '@b2d/utils/generateB2DPath';

import {
    AppDispatch,
    getFromSpecifiedDataSourceThunks,
    mediaObjectApi,
    mediaobjectDataSourcesThunks,
} from '@b2d/redux/RootStore';
import {
    MediaObjectApiGetMediaObjectItemRequest,
    MediaObjectApiPatchMediaObjectItemRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';

import {
    inUseFactureModalMessages,
    removeMediaObjectModalMessages,
    swapSocieteMediaObjectModalMessages,
} from '../../constants/wording/modals';
import ConfirmationModalContent from '../modals/ConfirmationModalContent';
import StatusList from '../fragments/StatusList';

export type UnregisteredInvoicesListProps = {
    dataSource: DataSource<MediaObjectApiObject>;
    additionalFilters?: FiltersDatatableList;
};

const UnregisteredInvoicesList: FunctionComponent<UnregisteredInvoicesListProps> = function ({
    dataSource,
    additionalFilters,
}: UnregisteredInvoicesListProps) {
    const getMediaObject = getFromSpecifiedDataSourceThunks(
        mediaobjectDataSourcesThunks,
        dataSource.name,
    );

    const dispatch = useDispatch<AppDispatch>();

    const { modalActions } = useContext(ModalContext);

    const { request } = useApiRequest();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedMediaObject, setSelectedMediaObject] = useState<MediaObject | undefined>(
        undefined,
    );

    const resetStates = useCallback(() => {
        // clean du state du formulaire
        dispatch({
            type: ACTIONS.RESET,
        });
    }, [dispatch]);

    type NavigateToMediaObjectProps = {
        mediaObjectId: number;
        openNewTab?: boolean;
    };

    /**
     * Génère le chemin d'accès vers le formulaire de saisie d'une facture,
     * puis s'y dirige.
     *
     * @param mediaObject MediaObject
     * @returns void
     */
    const navigateToMediaObject = useCallback(
        ({ mediaObjectId, openNewTab = false }: NavigateToMediaObjectProps) => {
            if (dataSource.selected) {
                resetStates();
            }

            const response = generateB2DPath('formmediaobject', { mediaObjectId });

            if (response.status === 'STAY') {
                return;
            }

            if (response.status === 'KO') {
                enqueueSnackbar(<Typography>{response.message}</Typography>, {
                    variant: 'warning',
                });
                return;
            }

            if (openNewTab) {
                window.open(response.path, '_blank', 'rel=noopener noreferrer');
                return;
            }

            navigate(response.path);
        },
        [enqueueSnackbar, dataSource.selected, navigate, resetStates],
    );

    type DebutTraitementCheckModalProps = {
        mediaObject: MediaObject;
        openNewTab?: boolean;
    };

    /**
     * Test si le MediaObject sélectionné est en cours d'utilisation.
     * Si oui, affiche une modal d'avertissement à l'utilisateur.
     * Sinon, se dirige vers le formulaire de saisie.
     *
     * @param mediaObject MediaObject
     * @returns void
     */
    const debutTraitementCheckModal = useCallback(
        async ({ mediaObject, openNewTab = false }: DebutTraitementCheckModalProps) => {
            if (!mediaObject.id) {
                enqueueSnackbar(<Typography>L&apos;ID du PDF est introuvable !</Typography>, {
                    variant: 'error',
                });
                return;
            }

            const requestParameters: MediaObjectApiGetMediaObjectItemRequest = {
                id: `${mediaObject.id}`,
            };

            const res = await mediaObjectApi.getMediaObjectItem(requestParameters);

            if (res.data) {
                setSelectedMediaObject(res.data as MediaObject);

                if (isProcessingMediaObject(res.data as MediaObject)) {
                    <ConfirmationModalContent
                        messages={inUseFactureModalMessages}
                        actionOnValidation={() => {
                            if (!selectedMediaObject) {
                                enqueueSnackbar(
                                    <Typography>Il n&apos;y a aucun PDF sélectionné !</Typography>,
                                    {
                                        variant: 'error',
                                    },
                                );
                                return;
                            }

                            if (selectedMediaObject.id) {
                                navigateToMediaObject({
                                    mediaObjectId: selectedMediaObject.id,
                                    openNewTab,
                                });
                            }
                        }}
                        actionOnCancellation={() => {
                            dispatch(getMediaObject({}));
                        }}
                    />;
                } else if (res.data.id) {
                    navigateToMediaObject({ mediaObjectId: res.data.id, openNewTab });
                }
            }
        },
        [dispatch, enqueueSnackbar, getMediaObject, navigateToMediaObject, selectedMediaObject],
    );

    /**
     * Action lors du clique du bouton de téléchargement du PDF.
     */
    const onDownload = (mediaObject: MediaObject) => {
        if (mediaObject.contentUrl && mediaObject.name) {
            const link = document.createElement('a');
            link.href = mediaObject.contentUrl;
            link.target = '_blank';
            link.type = 'application/octet-stream';
            link.download = `${mediaObject.name}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const onDelete = useCallback(
        async (mediaObject: MediaObject) => {
            modalActions.call(
                <ConfirmationModalContent
                    messages={removeMediaObjectModalMessages}
                    actionOnValidation={() => {
                        if (!mediaObject || !mediaObject.id) {
                            enqueueSnackbar(
                                <Typography>Il n&apos;y a aucun PDF sélectionné !</Typography>,
                                {
                                    variant: 'error',
                                },
                            );
                            return;
                        }

                        // Suppression physique actuellement impossible à cause de :
                        // 1. Contrainte SQL (même si aucune facture liée n'est active, elle reste liée en base)
                        if (mediaObject && mediaObject.facturesAchat) {
                            const isOneFactureInUse = mediaObject.facturesAchat.find(
                                (facture) => !facture.deleted,
                            );

                            if (isOneFactureInUse) {
                                enqueueSnackbar(
                                    <Typography>
                                        Ce PDF ne peut pas être supprimé car il est lié à une ou
                                        plusieurs facture(s) d&apos;achat en cours de traitement(s),
                                        ou traitée(s).
                                    </Typography>,
                                    {
                                        variant: 'error',
                                    },
                                );

                                return;
                            }
                        }

                        const successCallback = () => {
                            dispatch(getMediaObject({}));
                        };

                        const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                            id: mediaObject.id.toString(),
                            mediaObject: { deleted: true },
                        };

                        // Suppression logique
                        request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                            withToaster: true,
                            successCallback,
                        });
                    }}
                />,
            );
        },
        [dispatch, enqueueSnackbar, getMediaObject, modalActions, request],
    );

    const switchSociete = useCallback(
        async (mediaObject: MediaObject) => {
            modalActions.call(
                <ConfirmationModalContent
                    messages={swapSocieteMediaObjectModalMessages}
                    actionOnValidation={() => {
                        if (!mediaObject || !mediaObject.id) {
                            enqueueSnackbar(
                                <Typography>Il n&apos;y a aucun PDF sélectionné !</Typography>,
                                {
                                    variant: 'error',
                                },
                            );
                            return;
                        }

                        // Suppression physique actuellement impossible à cause de :
                        // 1. Contrainte SQL (même si aucune facture liée n'est active, elle reste liée en base)
                        if (mediaObject && mediaObject.facturesAchat) {
                            const isOneFactureInUse = mediaObject.facturesAchat.find(
                                (facture) => !facture.deleted,
                            );

                            if (isOneFactureInUse) {
                                enqueueSnackbar(
                                    <Typography>
                                        Ce PDF ne peut pas être supprimé car il est lié à une ou
                                        plusieurs facture(s) d&apos;achat en cours de traitement(s),
                                        ou traitée(s).
                                    </Typography>,
                                    {
                                        variant: 'error',
                                    },
                                );

                                return;
                            }
                        }

                        const successCallback = () => {
                            dispatch(getMediaObject({}));
                        };

                        const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                            id: mediaObject.id.toString(),
                            mediaObject: { idSociete: mediaObject.idSociete === 12 ? 2 : 12 },
                        };

                        // Suppression logique
                        request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                            withToaster: true,
                            successCallback,
                        });
                    }}
                />,
            );
        },
        [dispatch, enqueueSnackbar, getMediaObject, modalActions, request],
    );

    const columns: ColumnDatatable<MediaObject>[] = useMemo(
        () => [
            {
                label: 'Nom du fichier',
                render: (mediaObject: MediaObject) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                    >
                        <Typography>{mediaObject.originalName}</Typography>
                        <StatusList itemToWatch={mediaObject} />
                    </Stack>
                ),
                isDisplayed: true,
            },
            {
                label: 'Date de dépôt',
                render: (mediaObject: MediaObject) => {
                    let moCreationDate = null;

                    if (mediaObject.createdAt) {
                        moCreationDate = new Date(
                            Date.parse(mediaObject.createdAt),
                        ).toLocaleDateString();
                    }

                    return <Typography>{moCreationDate}</Typography>;
                },
                field: 'createdAt',
                sortable: true,
                isDisplayed: true,
            },
            {
                label: 'Déposé par',
                render: 'createdBy',
                isDisplayed: true,
            },
            {
                label: 'Société',
                render: (mediaObject: MediaObject) => {
                    let moSociete = null;

                    if (mediaObject.idSociete) {
                        moSociete =
                            BDD_SWITCH_SOCIETES_FULLNAME_FROM_ID[
                                mediaObject.idSociete as BDD_SWITCH_SOCIETES_IDS_TYPE
                            ];
                    }

                    return <Typography>{moSociete}</Typography>;
                },
                isDisplayed: true,
            },
            {
                label: 'Statut',
                isDisplayed: false,
                render: (mediaObject: MediaObject) => {
                    const debug = false;
                    if (debug) {
                        console.log(mediaObject);
                    }
                    // Nouveau champs du MediaObject : Statut
                    // Qui détermine dans quel état est le traitement du MediaObject :
                    // À traiter - Tout est normal - vert
                    // Refusé - Doit être résolu par un interne - rouge
                    // Annoté - Note fournie par interne pour traiter la facture - orange
                    return (
                        <Chip
                            color="success"
                            label="À traiter"
                        />
                    );
                },
            },
            {
                label: 'Facture liée',
                onClickCell: () => {},
                render: (mediaObject: MediaObject) =>
                    !mediaObject.facturesAchat || mediaObject.facturesAchat.length === 0
                        ? null
                        : mediaObject.facturesAchat.map((facture: FactureAchatApiObject) => (
                              <Chip
                                  color="warning"
                                  label={`${facture.numeroFacture}`}
                                  size="small"
                                  key={`${facture.numeroFacture}-${facture.id}-${mediaObject.id}`}
                                  onClick={() => {
                                      navigate(`modifier/${facture.id}/${mediaObject.id}`);
                                  }}
                                  sx={{
                                      color: '#3e3e3e', // Nécessaire pour l'accessibilité
                                  }}
                              />
                          )),
                isDisplayed: false,
            },
            {
                label: 'Actions',
                onClickCell: () => {},
                render: (mediaObject: MediaObject) =>
                    mediaObject.id ? (
                        <>
                            <ListActionButton
                                tooltip="Saisir"
                                onClick={() => {
                                    debutTraitementCheckModal({ mediaObject });
                                }}
                                iconComponent={<FaOptionIcon {...addFileIcon.props} />}
                            />

                            <ListActionButton
                                tooltip="Télécharger"
                                onClick={() => {
                                    onDownload(mediaObject);
                                }}
                                iconComponent={<FaOptionIcon {...downloadIcon.props} />}
                            />
                            <KeycloakHasRole kcRole="realm:interne">
                                <ListActionButton
                                    tooltip="Supprimer le pdf"
                                    onClick={() => {
                                        onDelete(mediaObject);
                                    }}
                                    iconComponent={<FaOptionIcon {...deleteIcon.props} />}
                                />
                                <ListActionButton
                                    tooltip="Modifier la société"
                                    onClick={() => {
                                        switchSociete(mediaObject);
                                    }}
                                    iconComponent={<FaOptionIcon {...changeCompanyIcon.props} />}
                                />
                            </KeycloakHasRole>
                        </>
                    ) : null,
                isDisplayed: true,
            },
        ],
        [debutTraitementCheckModal, navigate, onDelete, switchSociete],
    );

    const filters: FiltersDatatableList = [
        {
            field: 'processingFacture',
            label: 'Pdf en traitement',
            type: 'boolean',
        },
    ];

    return dataSource ? (
        <StoreDatatable
            dataSource={dataSource}
            columns={columns}
            fetchData={getMediaObject}
            filters={[...(additionalFilters || []), ...filters]}
            localStorageKey="FlexyStoreDatatableMediaObject"
            onClickRow={(e: React.MouseEvent<HTMLElement>, item: MediaObject) =>
                debutTraitementCheckModal({ mediaObject: item })
            } // Empêche la bonne redirection vers la facture liée (même en utilisant un Link)
            onWheelClickRow={(e: React.MouseEvent<HTMLElement>, item: MediaObject) =>
                debutTraitementCheckModal({ mediaObject: item, openNewTab: true })
            } // Empêche la bonne redirection vers la facture liée (même en utilisant un Link)
        />
    ) : null;
};

export default UnregisteredInvoicesList;
