/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
    Commande,
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    DOSSIERS_FORMALITES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    Dossier,
    DossierFormalite,
    FactureAchat,
    FactureVente,
    Fournisseur,
    RubriqueFacturation,
    RubriqueFacturationApiObject,
    TauxTvaApiObject,
} from '@europrocurement/l2d-domain';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
    AppDispatch,
    FactureFormSelector,
    FactureVenteSelector,
    RootStateType,
    RubriqueFacturationSelector,
    TauxTvaSelector,
    customizerSelector,
    dossierDataSourcesThunks,
    factureAchatLigneApi,
    selectFactureAchat,
    selectFactureVente,
    selectFournisseur,
} from '@b2d/redux/RootStore';
import {
    CenterCircularProgress,
    HeaderSwitch,
    Modalizer,
    useModal,
} from '@europrocurement/flexy-components';
import { ExtandedFormContext, FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import { Box, Button, ButtonGroup, InputAdornment, Typography } from '@mui/material';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { useSnackbar } from 'notistack';
import { TypeRemiseFournisseurRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import {
    FactureAchatFactureAchatReadDossierDossiersInner,
    FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import _ from 'lodash';

import {
    useBackFromMediaObjectEdit,
    useNavigateToFactureAchatNextDossier,
    useNavigateToFactureAchatRecap,
    useNavigateToFactureAchatRecapFormalite,
    useNavigateToMediaObjectsList,
} from '@b2d/pages/Achats/achatRouterHooks';
import useFolioPublicationSectionStructure from '@b2d/pages/Achats/components/forms/formElements/hooks/useFolioPublicationSectionStructure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dossierFormaliteDataSourcesThunks } from '@b2d/redux/subReducers/FormalityReducer';
import { faForward, faListCheck, faPause } from '@fortawesome/pro-solid-svg-icons';
import useSwitchLockValues from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import useUpdateTotals from '@b2d/pages/Achats/hooks/useUpdateTotals';
import { Nullable } from '@b2d/pages/Achats/constants/types';
import useDomain from '@b2d/hooks/useDomain';
import { EnteteFacture } from '../EnteteFacture';

import {
    filterOptionsProps,
    type FactureFormObject,
    type LigneProduit,
    type TableauProduits,
} from '../../types';
import {
    resetDossiersFields,
    useUpdateFormWithDossier,
    useUpdateFormWithDossierFormalite,
} from '../../functions/dossierFonctions';
import {
    ajouterLigneRSF,
    isDossierFromFournisseur,
    useSyncAchatProduitsWithVente,
    useUpdateFormWithVentesAndEvents,
    fillProduits,
    useActionOnChangeTauxRemise,
    replaceProduits,
    defaultProduits,
} from '../../functions/produitFunctions';
import { useResetAllForm, useResetFactureAchatState } from '../../functions/generalFunctions';
import { formalitesVentesToProduitsAchats } from '../../functions/venteFunctions';
import { useMultiAnnonceContext } from '../../../../views/forms/multi/RegisterMultiInvoiceView';
import { updateFormWithFournisseur } from '../../functions/fournisseurFunctions';
import { round, updateFormTotaux } from '../../functions/calculsProduits';
import ConfirmationModal, { MessagesTypes } from '../../../modals/ConfirmationModal';
import {
    cancelAddDossier,
    confirmationSaisieFactureModalMessages,
    defaultModalMessages,
    pauseSaisieFacture,
    saisieDossierCommandeModalMessages,
    saisieDossierFormaliteModalMessages,
} from '../../../../constants/wording/modals';
import { useGetDossiersFromAchat } from '../../functions/dataHooks';
import NotesButton from '../../../fragments/NotesButton';
import DossiersList from '../../../fragments/DossiersList';
import SearchDossierFormaliteModal from '../../../modals/SearchDossierFormaliteModal';
import SearchDossierModal from '../../../modals/SearchDossierModal';
import { ModeProps } from '../../formElements/types';
import useProductsSectionStructure from '../../formElements/hooks/useProductsSectionStructure';
import useTotalsSectionStructure from '../../formElements/hooks/useTotalsSectionStructure';
import useFolioFormalitySectionStructure from '../../formElements/hooks/useFolioFormalitySectionStructure';
import SearchDossierFormaliteModalContent from '../../../modals/SearchDossierFormaliteModalContent';
import formProductLinesToApiProductLines from '../../functions/dataTransformers/formToApi/formProductLinesToApiProductLines';

export const DossierSaisieMultiple = function () {
    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const formContext = useForm({
        mode: 'onTouched',
    });

    const { modalActions } = useModal();
    const { getSwitchLockValues, stateSwitchLockValues } = useSwitchLockValues();
    const { updateTotals } = useUpdateTotals({ formContext, stateSwitchLockValues });

    const [validSubmit, setValidSubmit] = useState<boolean>(false);
    const [hasBlured, setHasBlured] = useState<boolean>(false);
    const [modalRemiseOpen, setModalRemiseOpen] = useState<boolean>(false);
    const [modalDossierOpen, setModalDossierOpen] = useState<boolean>(false);
    const [modalConfirmSubmit, setModalConfirmSubmit] = useState<boolean>(false);
    const [modalDossierFormaliteSearchOpen, setModalDossierFormaliteSearchOpen] =
        useState<boolean>(false);
    const [afficherBoutonTxRemise, setAfficherBoutonTxRemise] = useState<boolean>(false);
    const [modalRemoveProduitsOpen, setModalRemoveProduitsOpen] = useState<boolean>(false);
    const [modalDossierCommandeOpen, setModalDossierCommandeOpen] = useState<boolean>(false);
    const [modalDossierFormaliteOpen, setModalDossierFormaliteOpen] = useState<boolean>(false);
    const [rsfInclusTxremise, setRsfInclusTxremise] = useState<string>();
    const [lastVenteUpdate, setLastVenteUpdate] = useState<number | null>(null);
    const [confirmationModalState, setConfirmationModalState] = useState<{
        isModalOpen: boolean;
        messages: MessagesTypes;
        actionOnValidation: () => void;
        actionOnCancellation: () => void;
        isDraggable?: boolean;
    }>({
        isModalOpen: false,
        messages: defaultModalMessages,
        actionOnValidation: () => {},
        actionOnCancellation: () => {},
    });

    const { factureachatid } = useParams();
    const { mediaObject } = useMultiAnnonceContext();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch<AppDispatch>();
    const resetForm = useResetAllForm();

    const [dossiers, setdossiers] = useState<FactureAchatFactureAchatReadDossierDossiersInner[]>(
        [],
    );
    const [dossiersStatus, setdossiersStatus] = useState<EuroprocApiResponseStatus>('idle');

    const getDossiers = useGetDossiersFromAchat();

    useEffect(() => {
        if (!factureachatid || dossiersStatus === 'loading' || dossiersStatus === 'succeeded') {
            return;
        }

        setdossiersStatus('loading');
        getDossiers(+factureachatid).then((data) => {
            setdossiersStatus('succeeded');
            setdossiers(data);
        });
    }, [dossiersStatus, factureachatid, getDossiers]);

    const state = useSelector(FactureFormSelector);

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    const { xIdSociete } = useSelector(customizerSelector);

    const factureAchat: FactureAchat | undefined = useSelector(
        (s: RootStateType) => s.achats.factureachat.main.selected,
        _.isEqual,
    );
    const commandeSelected: Commande | undefined = useSelector(
        (s: RootStateType) => s.dossiers.commande.main.selected,
        _.isEqual,
    );
    const venteSelected: FactureVente | undefined = useSelector(
        (s: RootStateType) => s.dossiers.facturevente.main.selected,
        _.isEqual,
    );
    const dossierSelected: Dossier | undefined = useSelector(
        (s: RootStateType) => s.dossiers.dos[DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME].selected,
        _.isEqual,
    );
    const dossierFormaliteSelected: DossierFormalite | undefined = useSelector(
        (s: RootStateType) =>
            s.formalites.dosform[DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME].selected,
        _.isEqual,
    );
    const fournisseurSelected: Fournisseur | undefined = useSelector(
        (s: RootStateType) => s.tiers.fournisseur.main.selected,
        _.isEqual,
    );

    const dossiersDataSource: DataSource<Dossier> = useSelector(
        (s: RootStateType) => s.dossiers.dos.autocompleteDossiers,
        _.isEqual,
    );

    const formalitesDataSource: DataSource<DossierFormalite> = useSelector(
        (s: RootStateType) => s.formalites.dosform.autocompleteDossiersFormalites,
        _.isEqual,
    );

    const rubFactsDataSource: DataSource<RubriqueFacturation> = useSelector(
        RubriqueFacturationSelector,
    ).main;

    const tauxTvaDataSource: DataSource<TauxTvaApiObject> = useSelector(TauxTvaSelector).main;

    const ventesDataSource: DataSource<FactureVente> = useSelector(FactureVenteSelector).main;

    const { searchByNumeroPresta } = dossierDataSourcesThunks.autocompleteDossiers.data;

    const navigateToMediaObjectsList = useNavigateToMediaObjectsList();
    const navigateToFactureAchatRecap = useNavigateToFactureAchatRecap();
    const navigateToFactureAchatRecapFormalite = useNavigateToFactureAchatRecapFormalite();
    const navigateToFactureAchatNextDossier = useNavigateToFactureAchatNextDossier();
    const updateFormWithDossier = useUpdateFormWithDossier();
    const updateFormWithDossierFormalite = useUpdateFormWithDossierFormalite();
    const updateFormWithVentesAndEvents = useUpdateFormWithVentesAndEvents();
    const syncAchatProduitsWithVente = useSyncAchatProduitsWithVente();
    const resetStates = useResetFactureAchatState();
    const actionOnChangeTauxRemise = useActionOnChangeTauxRemise();

    const modeFolioPublication = useMemo<ModeProps>(
        () => ({
            type: 'register',
            nature: 'simple',
        }),
        [],
    );

    const { folioPublicationStructure } = useFolioPublicationSectionStructure({
        dossiersDataSource,
        dossierFetchData: dossierDataSourcesThunks.autocompleteDossiers.getData,
        mode: modeFolioPublication,
        searchByNumeroPresta,
        state,
        handleClickModalSearchDossierAnnonce: () => setModalDossierOpen(true),
    });

    const modeFolioFormality = useMemo<ModeProps>(
        () => ({
            type: 'register',
            nature: 'simple',
        }),
        [],
    );

    const handleClickModalSearchDossierFormalite = useCallback(() => {
        modalActions.call(
            <SearchDossierFormaliteModalContent
                onCloseModal={(dossier: Nullable<DossierFormalite>) => {
                    if (dossier) {
                        dispatch({
                            type: `${DOSSIERS_FORMALITES_SLICE_NAME}/set${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
                            payload: dossier,
                        });
                    }
                }}
            />,
            true,
            true,
        );
    }, [dispatch, modalActions]);

    const { folioFormalitySectionStructure } = useFolioFormalitySectionStructure({
        dataSource: formalitesDataSource,
        fetchData: dossierFormaliteDataSourcesThunks.autocompleteDossiersFormalites.getData,
        mode: modeFolioFormality,
        state,
        onClickModal: handleClickModalSearchDossierFormalite,
    });

    const switchDisplaySells = useMemo<HeaderSwitch>(() => {
        const switchComponent: HeaderSwitch = {
            label: '',
            sx: {
                display: 'none',
            },
        };

        if (state.displaySwitchToSeeSells && isInterne) {
            switchComponent.label = 'Afficher les lignes de vente';
            switchComponent.defaultChecked = state.displaySells;
            switchComponent.onChange = () => {
                dispatch({
                    type: ACTIONS.DISPLAY_SELLS,
                    payload: !state.displaySells,
                });
            };
            switchComponent.checked = state.displaySells;
            switchComponent.sx = {};
        }
        return switchComponent;
    }, [dispatch, state.displaySells, state.displaySwitchToSeeSells, isInterne]);

    const buttonDiscountRate = useMemo<ReactNode>(
        () =>
            afficherBoutonTxRemise ? (
                <Button
                    onClick={() => {
                        actionOnChangeTauxRemise({
                            formContext,
                            stateSwitchLockValues,
                            produitConcernedByRsfExist: () => {
                                setModalRemiseOpen(true);
                            },
                            produitConcernedByRsfDoNotExist: (message) => {
                                enqueueSnackbar(<Typography>{message}</Typography>, {
                                    variant: 'error',
                                });
                            },
                        });
                    }}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    sx={{ marginLeft: 'auto' }}
                >
                    Remise intégrée
                </Button>
            ) : null,
        [
            actionOnChangeTauxRemise,
            afficherBoutonTxRemise,
            enqueueSnackbar,
            formContext,
            stateSwitchLockValues,
        ],
    );

    const switchesList = useMemo<Array<HeaderSwitch>>(() => {
        const component = [];

        if (switchDisplaySells && state.displaySwitchToSeeSells) {
            component.push(switchDisplaySells);
        }

        return component;
    }, [switchDisplaySells, state.displaySwitchToSeeSells]);

    const buttonsList = useMemo<Array<ReactNode>>(() => {
        const component = [];

        if (buttonDiscountRate && afficherBoutonTxRemise) {
            component.push(buttonDiscountRate);
        }

        return component;
    }, [afficherBoutonTxRemise, buttonDiscountRate]);

    const { productsSectionStructure } = useProductsSectionStructure({
        formContext,
        rubFacts: state.rubFacts,
        txTva: state.txTva,
        mode: { type: 'register' },
        headerSwitches: switchesList,
        headerChildren: buttonsList,
        hasBlured,
        openModal: () => {
            setModalRemiseOpen(true);
        },
        handleTotalsLock: (formContextOnChangeInput) => {
            updateFormTotaux({
                formContext: formContextOnChangeInput,
                reasonToTriggerUpdate: stateSwitchLockValues.totals.value,
            });
        },
        produitsVente: state.produitsVente,
        displaySells: isInterne ? state.displaySells : false,
        stateSwitchLockValues,
    });

    const modeTotals = useMemo<ModeProps>(
        () => ({
            type: 'register',
            nature: 'multiple',
            lock: stateSwitchLockValues.totals.value,
        }),
        [stateSwitchLockValues.totals.value],
    );

    const totalsSwitchesList = useMemo<Array<HeaderSwitch>>(() => {
        const component = [];

        if (!factureAchat?.syncCegid) {
            component.push(
                getSwitchLockValues({
                    sectionToBlock: 'totals',
                    overwriteAction: () => {
                        updateTotals({
                            reasonToTriggerConfirmationModal: !stateSwitchLockValues.totals.value,
                        });
                    },
                }),
            );
        }

        return component;
    }, [
        factureAchat?.syncCegid,
        getSwitchLockValues,
        stateSwitchLockValues.totals.value,
        updateTotals,
    ]);

    const { totalsSectionStructure } = useTotalsSectionStructure({
        mode: modeTotals,
        headerSwitches: totalsSwitchesList,
        isMulti: true,
    });

    const actionOnRSF: any | undefined = (
        tableauProduits: TableauProduits,
        actionIfRSF: (produitRSF?: Partial<LigneProduit> | undefined) => any | undefined,
        actionNoRSF: () => any | undefined,
    ) => {
        const produits = tableauProduits;
        const produitRSF = produits.find((item) => item.rubriqueFacturation?.id === 99);

        if (produitRSF && actionIfRSF !== undefined) {
            actionIfRSF(produitRSF);
        }

        if (!produitRSF && actionNoRSF !== undefined) {
            actionNoRSF();
        }
    };

    const updateProduitsOnRemise = useCallback(
        (txRemise: string, pubLineNumber: number) => {
            // Sert pour relever les fournisseurs dont la RSF a été modifiée manuellement.
            ajouterLigneRSF(formContext, fournisseurSelected);
            setAfficherBoutonTxRemise(true);

            const produits = formContext.getValues(`produits`) as TableauProduits;

            if (fournisseurSelected && parseFloat(txRemise) > 0 && pubLineNumber !== undefined) {
                // Calcul de la ligne de PUB
                const pubHt = formContext.getValues(`produits.${pubLineNumber}.ht`);

                if (pubHt !== undefined) {
                    const newPubHt = round(pubHt / (1 - parseFloat(txRemise) / 100)) as number;
                    const newRsfHt = round(newPubHt - pubHt);

                    const txtva = formContext.getValues(`produits.${pubLineNumber}.txtva`);

                    formContext.setValue(`produits.${pubLineNumber}.ht`, newPubHt);

                    fillProduits(
                        formContext,
                        {
                            idLigneVente: formContext.getValues(
                                `produits.${pubLineNumber}.idLigneVente`,
                            ),
                            ht: newPubHt,
                            txtva: formContext.getValues(`produits.${pubLineNumber}.txtva`),
                        },
                        pubLineNumber,
                    );

                    // On cherche la ligne de rsf
                    actionOnRSF(
                        produits,
                        (produitRSF: Partial<LigneProduit>) => {
                            const index = produits.indexOf(produitRSF);

                            produits[index].ht = newRsfHt;
                            produits[index].txtva = txtva;

                            formContext.setValue('produits', produits);
                        },
                        undefined,
                    );

                    const produitsAfterAddRsf = formContext.getValues(
                        `produits`,
                    ) as TableauProduits;

                    const RSF = produitsAfterAddRsf.find(
                        (item) => item.rubriqueFacturation?.id === 99,
                    );

                    if (RSF) {
                        fillProduits(formContext, RSF, produitsAfterAddRsf.indexOf(RSF));
                        formContext.trigger(`produits.${produitsAfterAddRsf.indexOf(RSF)}`);
                    }
                }
            }
        },
        [formContext, fournisseurSelected],
    );

    /**
     * Actions à l'ouverture de la page de saisie de facture multiple
     */
    useEffect(() => {
        const filterOptions: filterOptionsProps = {
            sliceName: DOSSIERS_SLICE_NAME,
            dataSourceName: 'main',
        };

        dispatch({
            type: ACTIONS.RESET_DATASOURCE_FILTERS,
            payload: filterOptions,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Si un id de factureAchat est trouvé dans l'url,
     * alors on sélectionne la facture d'achat concernée.
     */
    useEffect(() => {
        if (factureachatid) {
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureachatid]);

    /**
     * Si aucun fournisseur n'est sélectionné lorsque l'on a une facture d'achat,
     * alors on sélectionne le fournisseur lié à la facture d'achat.
     */
    useEffect(() => {
        if (
            factureAchat &&
            factureAchat.idEntiteFacturante &&
            (!fournisseurSelected || factureAchat.idEntiteFacturante !== fournisseurSelected.id)
        ) {
            dispatch(selectFournisseur({ id: +factureAchat.idEntiteFacturante }));
        }
    }, [dispatch, factureAchat, formContext, fournisseurSelected]);

    /**
     * Affiche un toast si le fournisseur de la facture d'achat
     * ne correspond à aucun fournisseur des prestations du dossier.
     */
    useEffect(() => {
        if (factureAchat && dossierSelected) {
            if (!isDossierFromFournisseur(factureAchat, dossierSelected)) {
                enqueueSnackbar(
                    <Typography>
                        Le dossier sélectionné n&apos;est pas lié au fournisseur{' '}
                        {factureAchat.libelleFacturante}.
                    </Typography>,
                    {
                        variant: 'warning',
                        autoHideDuration: 10000,
                    },
                );
            }
        }
    }, [factureAchat, enqueueSnackbar, dossierSelected]);

    /**
     * Affiche un toast si le dossier a déjà été traité
     * dans une facture d'achat clôturée.
     */
    useEffect(() => {
        if (factureAchat && (dossierSelected || dossierFormaliteSelected)) {
            if (!dossierSelected?.numero && !dossierFormaliteSelected?.numero) return;

            const numeroDossierSelected = dossierSelected?.numero
                ? dossierSelected?.numero.toString()
                : dossierFormaliteSelected?.numero;

            const isOneNumDossierEqual = dossiers.find(
                (dossierRecapItem) => dossierRecapItem.numeroDossier === numeroDossierSelected,
            );

            if (isOneNumDossierEqual) {
                enqueueSnackbar(
                    <Typography>
                        Le dossier sélectionné a déjà été traité dans cette facture.
                    </Typography>,
                    {
                        variant: 'warning',
                        autoHideDuration: 10000,
                    },
                );
            }
        }
    }, [enqueueSnackbar, dossierSelected, factureAchat, dossiers, dossierFormaliteSelected]);

    /**
     * Ajoute la ligne RSF au tableau de produit,
     * si un fournisseur est sélectionné
     */
    useEffect(() => {
        if (!factureAchat || !fournisseurSelected) return;

        updateFormWithFournisseur(formContext, fournisseurSelected, {
            typeRemise: (type: TypeRemiseFournisseurRead) => {
                if (type.code === 'RSF' || type.code === 'RSF Inclus') {
                    // ajouterLigneRSF(formContext, fournisseurSelected);
                    setAfficherBoutonTxRemise(true);
                }
            },
        });
    }, [dispatch, factureAchat, formContext, fournisseurSelected]);

    /**
     * Affiche le bouton "Modifier taux remise".
     */
    useEffect(() => {
        if (!factureAchat || !rubFactsDataSource.data || !dossierSelected || !fournisseurSelected)
            return;

        if (fournisseurSelected.typeRemise?.code === 'RSF') {
            setAfficherBoutonTxRemise(true);
        } else {
            setAfficherBoutonTxRemise(false);
        }
    }, [dossierSelected, factureAchat, formContext, fournisseurSelected, rubFactsDataSource.data]);

    /**
     * Si le dossier sélectionné possède une commande hors Annonce Légale (domaine 1) et que l'utilisateur a le rôle mada,
     * alors empêcher la saisie de la facture avec ce dossier.
     */
    useEffect(() => {
        const isDossierOfCommande: boolean = !!(
            dossierSelected &&
            commandeSelected &&
            dossierSelected?.commande !== null &&
            dossierSelected?.commande !== undefined &&
            commandeSelected?.idDomaine !== 1
        );

        if (isDossierOfCommande && !isInterne) {
            setModalDossierCommandeOpen(true);
        }
    }, [
        dossierSelected,
        commandeSelected,
        dossierSelected?.commande,
        commandeSelected?.idDomaine,
        isInterne,
    ]);

    /**
     * Si le dossier sélectionné provient d'une formalité (domaine 3 = formalité) et que l'utilisateur a leur rôle mada,
     * alors empêcher la saisie de la facture avec ce dossier.
     */
    useEffect(() => {
        const isDossierFormalite: boolean = !!(dossierFormaliteSelected && state.defDomain === 3);

        if (isDossierFormalite && !isInterne) {
            setModalDossierFormaliteOpen(true);
        }
    }, [dossierFormaliteSelected, state.defDomain, isInterne]);

    /**
     * Création groupé des lignes et notes.
     * Nécessaire avant la création de la facture.
     *
     * @param factureForm
     * @returns Promise<any>;
     */
    const createLignesAndNotes = useCallback(
        async (factureForm: FactureFormObject) => {
            if (!factureAchat || !factureAchat['@id']) return;

            const lines = formProductLinesToApiProductLines(factureForm, factureAchat['@id']);

            const promises = lines.map((line) => {
                const requestParameters: FactureAchatLigneApiCreateFactureAchatLigneFactureAchatLigneCollectionRequest =
                    {
                        factureAchatLigneJsonldFactureAchatLigneWriteFactureAchatLigneCreate: line,
                        xIdSociete,
                    };

                return factureAchatLigneApi.createFactureAchatLigneFactureAchatLigneCollection(
                    requestParameters,
                );
            });

            // eslint-disable-next-line consistent-return
            return await Promise.all(promises);
        },
        [factureAchat, xIdSociete],
    );

    /**
     * Formate le formulaire avant de mettre à jour les infos de la facture.
     * Si la mise à jour est un succès, alors l'utilisateur est envoyé vers
     * le récapitulatif.
     *
     * @param factureForm
     * @returns void
     */
    const handleSubmit = useCallback(
        async (factureForm: FactureFormObject & { end?: boolean; pause?: boolean }) => {
            if (!factureachatid) return;

            createLignesAndNotes(factureForm);

            resetForm(formContext);
            dispatch(selectFactureAchat({ id: +factureachatid }));

            if (factureForm.end) {
                if (state.defDomain === 3) {
                    navigateToFactureAchatRecapFormalite(factureAchat as FactureAchat, mediaObject);
                } else {
                    navigateToFactureAchatRecap(factureAchat as FactureAchat, mediaObject);
                }
            } else if (factureForm.pause) {
                navigateToMediaObjectsList();
            } else {
                navigateToFactureAchatNextDossier(factureAchat as FactureAchat, mediaObject);
            }
        },
        [
            createLignesAndNotes,
            dispatch,
            factureAchat,
            factureachatid,
            formContext,
            mediaObject,
            navigateToFactureAchatNextDossier,
            navigateToFactureAchatRecap,
            navigateToFactureAchatRecapFormalite,
            navigateToMediaObjectsList,
            resetForm,
            state.defDomain,
        ],
    );

    /**
     * Déclenché lors de la soumission du formulaire de la modal.
     * Siren exemple RSF Inclus : 987654321
     */
    const handleModalSubmit: SubmitHandler<{
        'facture-form-modal-txremise': string;
    }> = useCallback(
        (data: { 'facture-form-modal-txremise': string }) => {
            actionOnChangeTauxRemise({
                formContext,
                stateSwitchLockValues,
                produitConcernedByRsfExist: (produitConcernedByRsf) => {
                    const txRemise = data['facture-form-modal-txremise'];
                    updateProduitsOnRemise(txRemise, produitConcernedByRsf);
                    setHasBlured(true);
                    setModalRemiseOpen(false);
                },
                produitConcernedByRsfDoNotExist: (message) => {
                    enqueueSnackbar(<Typography>{message}</Typography>, { variant: 'error' });
                },
            });
        },
        [
            actionOnChangeTauxRemise,
            enqueueSnackbar,
            formContext,
            stateSwitchLockValues,
            updateProduitsOnRemise,
        ],
    );

    /**
     * Infos dossier par N° de dossier / d'annonce
     *
     * Si un dossier est sélectionné, met à jour les informations.
     *
     */
    useEffect(() => {
        if (dossierFormaliteSelected) return;

        if (
            dossierSelected &&
            dossierSelected.numero &&
            `${dossierSelected.numero}` !== state.defaultNumeroDossier
        ) {
            updateFormWithDossier(formContext, dossierSelected);
        } else if (!dossierSelected) {
            resetDossiersFields(formContext);
            resetStates(formContext);
        }
    }, [
        formContext,
        dossierSelected,
        dossierFormaliteSelected,
        state.defaultNumeroDossier,
        updateFormWithDossier,
        resetStates,
    ]);

    /**
     * Infos dossier par N° de dossier de formalité
     *
     * Si un dossier de formalité est sélectionné, met à jour les informations.
     */
    useEffect(() => {
        if (dossierSelected) return;

        if (
            dossierFormaliteSelected &&
            dossierFormaliteSelected.numero &&
            dossierFormaliteSelected.numero !== state.defaultNumeroDossierFormalite
        ) {
            updateFormWithDossierFormalite(formContext, dossierFormaliteSelected);
        } else if (!dossierFormaliteSelected) {
            resetStates(formContext);
        }
    }, [
        dossierFormaliteSelected,
        dossierSelected,
        formContext,
        resetStates,
        state.defaultNumeroDossierFormalite,
        updateFormWithDossierFormalite,
    ]);

    /**
     * Met à jour les informations de ventes.
     */
    useEffect(() => {
        if (!dossierSelected && !venteSelected) return;

        updateFormWithVentesAndEvents(
            formContext,
            dossierSelected || null,
            dossierFormaliteSelected || null,
            fournisseurSelected || null,
            venteSelected || null,
            ventesDataSource.selectedStatus,
        );
    }, [
        formContext,
        dossierSelected,
        dossierFormaliteSelected,
        venteSelected,
        ventesDataSource.selectedStatus,
        fournisseurSelected,
        updateFormWithVentesAndEvents,
        enqueueSnackbar,
    ]);

    /**
     * S'il n'y plus de dossier ou de fournisseur sélectionné,
     * alors on remplace le tableau de produits par la valeur par défaut.
     */
    useEffect(() => {
        if (!dossierSelected && !fournisseurSelected) {
            replaceProduits(formContext, defaultProduits(state.defDomain, false));
        } else if (!dossierSelected && fournisseurSelected) {
            setTimeout(() => {
                replaceProduits(
                    formContext,
                    defaultProduits(
                        state.defDomain,
                        fournisseurSelected.typeRemise?.code === 'RSF',
                    ),
                );
            }, 1);
        }
    }, [dossierSelected, formContext, fournisseurSelected, state.defDomain]);

    /**
     * Ajout des RubFacts généraux
     *
     * @see dispatch
     * @see rubFacts.data
     */
    useEffect(() => {
        const rubFactsToAdd: RubriqueFacturationApiObject[] = rubFactsDataSource.data;

        dispatch({ type: ACTIONS.ADD_RUB_FACTS, payload: rubFactsToAdd });
    }, [dispatch, rubFactsDataSource.data]);

    /**
     * Alimente la liste des taux de TVA dans le state
     *
     * @see tauxTvaDataSource.data
     * @see state.txTva
     */
    useEffect(() => {
        if (state.txTva.length < 1) {
            dispatch({ type: ACTIONS.SET_TAUX_TVA, payload: tauxTvaDataSource.data });
        }
    }, [dispatch, state.txTva.length, tauxTvaDataSource.data]);

    /**
     * Filtre sur les rubriques de facturation
     *
     * Conserver les rubriques de facturation liées au domaine (i.e : Publication, Adjudication, ...)
     *
     * @see dispatch
     * @see state.defDomain
     * @see state.rubFacts
     */
    useEffect(() => {
        dispatch({ type: ACTIONS.SET_RUB_FACT, payload: rubFactsDataSource.data });

        if (state.defDomain !== 0) {
            dispatch({
                type: ACTIONS.FILTER_RUB_FACTS,
                payload: {
                    domaine: state.defDomain,
                    rubfacts: rubFactsDataSource.data,
                },
            });
        }
    }, [dispatch, rubFactsDataSource.data, state.defDomain]);

    useEffect(() => {
        const produitsValues: TableauProduits =
            formContext.getValues('produits') &&
            Array.isArray(formContext.getValues('produits')) &&
            formContext.getValues('produits').length > 0
                ? formContext.getValues('produits')
                : [];

        const bonsProduits = produitsValues.filter((produit) => {
            if (
                !produit.rubriqueFacturation ||
                !produit.rubriqueFacturation.id ||
                produit.rubriqueFacturation.id === 99
            ) {
                return true;
            }
            let ok = false;
            state.rubFacts.forEach((rubFact) => {
                if (produit.rubriqueFacturation?.id === rubFact.id) {
                    ok = true;
                }
            });

            return ok;
        });

        if (bonsProduits.length !== produitsValues.length) {
            replaceProduits(formContext, bonsProduits);
        }
    }, [formContext, state.rubFacts]);

    useDomain();

    const domaine = useSelector((s: RootStateType) => s.factureForm.defDomain);

    /**
     * Select la facture de vente de la commande,
     * si le dossier n'a pas lui-même de facture de vente.
     *
     * On cherche dans la commande le dossier qui correspond au dossier sélectionné
     * pour s'assurer que la facture soit la bonne.
     *
     * E.g. :
     *     - Dossier sans facture : 1272353
     *     - Dossier avec facture : 802308
     */
    useEffect(() => {
        if (domaine === 3) {
            return;
        }
        const commandWithVenteAndDossierWithout: boolean = !!(
            commandeSelected?.idFacture &&
            dossierSelected &&
            dossierSelected.id &&
            !dossierSelected.facture
        );

        if (commandWithVenteAndDossierWithout) {
            const matchedDossier = commandeSelected?.dossiers?.find((dossier) => {
                if (!dossierSelected?.id) return false;
                return dossier.includes(dossierSelected?.id.toString());
            });

            const newIdVenteFromCommandeAndDifferentThanVenteSelected: boolean = !!(
                venteSelected &&
                commandeSelected &&
                commandeSelected?.idFacture !== venteSelected.id
            );

            if (
                matchedDossier &&
                ventesDataSource.selectedStatus !== 'loading' &&
                (!venteSelected || newIdVenteFromCommandeAndDifferentThanVenteSelected)
            ) {
                dispatch(selectFactureVente({ id: commandeSelected?.idFacture as number }));
            }
        }
    }, [
        commandeSelected,
        dispatch,
        domaine,
        dossierSelected,
        venteSelected,
        ventesDataSource.selectedStatus,
    ]);

    /**
     * Ajout des lignes d'achat correspondant à la vente
     *
     * RG : https://legal2digital.atlassian.net/browse/PA-54
     *
     * Si une facture de vente est trouvée :
     * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
     * la propriété 'affiche' est à false.
     *
     * @see venteSelected
     * @see formContext
     * @see addProduit
     * @see state.rubFacts
     */
    useEffect(() => {
        // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect
        if (venteSelected && venteSelected.id && venteSelected.id !== lastVenteUpdate) {
            setLastVenteUpdate(venteSelected.id);
            syncAchatProduitsWithVente(
                venteSelected?.lignes || undefined,
                formContext,
                state.rubFacts,
                dossierSelected,
            );
        }
    }, [
        lastVenteUpdate,
        dossierSelected,
        formContext,
        state.rubFacts,
        syncAchatProduitsWithVente,
        venteSelected,
    ]);

    /**
     * Ajout des lignes d'achat correspondant à la vente pour un dossier de formalites
     *
     * RG : https://legal2digital.atlassian.net/browse/PA-54
     *
     * Si une facture de vente est trouvée :
     * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
     * la propriété 'affiche' est à false.
     */
    useEffect(() => {
        if (dossierSelected) return;

        if (
            dossierFormaliteSelected &&
            dossierFormaliteSelected.id &&
            dossierFormaliteSelected.id !== lastVenteUpdate
        ) {
            setLastVenteUpdate(dossierFormaliteSelected.id);
            formalitesVentesToProduitsAchats(`${dossierFormaliteSelected.id}`, xIdSociete)
                .then((lignes) => {
                    if (lignes && lignes.length > 0) {
                        formContext.setValue('produits', []);
                        syncAchatProduitsWithVente(
                            lignes,
                            formContext,
                            state.rubFacts,
                            dossierSelected,
                            true,
                            true,
                        );
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [
        dossierFormaliteSelected,
        dossierSelected,
        formContext,
        lastVenteUpdate,
        state.rubFacts,
        syncAchatProduitsWithVente,
        xIdSociete,
    ]);

    const modalFactureFormStructure: FormStructure[] = useMemo(
        () => [
            {
                type: 'number',
                name: 'facture-form-modal-txremise',
                inputlabel: 'Taux de remise',
                autoFocus: true,
                placeholder: '0',
                InputProps: {
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                },

                defaultValue: '20',
                inputProps: {
                    step: 1,
                    min: 0,
                },
                onChangeInput: (
                    event: React.SyntheticEvent<Element, Event>,
                    input: {
                        base: string;
                        name: string;
                        path: string;
                        objectPath: string;
                    },
                    changeFormContext: ExtandedFormContext,
                ) => {
                    const value: string = changeFormContext.getValue(input.path);
                    setRsfInclusTxremise(value);
                },
                onPaste: (event: React.ClipboardEvent) => {
                    if (event.clipboardData.getData('text').match(/\D/g)) {
                        event.preventDefault();
                    }
                },
                onKeyDown: (event: React.KeyboardEvent) => {
                    if (['e', 'E', '+', '-'].includes(event.key)) {
                        event.preventDefault();
                    }
                },
                rules: {
                    required: 'Taux de remise manquant',
                },
                xs: 10,
                sm: 10,
                md: 10,
                lg: 10,
            },
        ],
        [],
    );

    const factureFormStructure: FormStructure[] = useMemo(() => {
        const structure: FormStructure[] = [];

        const dossierStructure =
            state.defDomain === 3 ? folioFormalitySectionStructure : folioPublicationStructure;
        structure.push(
            {
                type: 'hidden',
                name: 'avoir',
                value: factureAchat?.avoir || false,
            },
            ...dossierStructure,
            ...productsSectionStructure,
            ...totalsSectionStructure,
        );

        return structure;
    }, [
        folioFormalitySectionStructure,
        folioPublicationStructure,
        productsSectionStructure,
        totalsSectionStructure,
        factureAchat?.avoir,
        state.defDomain,
    ]);

    /**
     * Assigne le focus sur le premier champs du formulaire.
     */
    useEffect(() => {
        if (factureAchat && fournisseurSelected) {
            setTimeout(() => {
                formContext.setFocus('numero_dossier');
            }, 200);
        }
    }, [formContext, factureAchat, fournisseurSelected]);

    /**
     * Si la mise en pause est déclenchée pour une saisie valide,
     * alors enregistre les informations saisies avant de retourner sur la liste des MediaObjects.
     */
    const onValidSaisiePaused = useCallback(
        (factureForm: FieldValues) => {
            const factureToSubmit = { ...factureForm };
            factureToSubmit.pause = true;
            handleSubmit(factureToSubmit as FactureFormObject).then(
                () => {
                    enqueueSnackbar(
                        <Typography>
                            Le dossier a bien été enregistré et la saisie mise en pause.
                        </Typography>,
                        { variant: 'success' },
                    );
                },
                (err) => {
                    console.error(err);
                    enqueueSnackbar(
                        <Typography>
                            Une erreur est survenue lors de l&apos;enregistrement du dossier.
                        </Typography>,
                        { variant: 'error' },
                    );
                },
            );
        },
        [enqueueSnackbar, handleSubmit],
    );

    /**
     * Si la mise en pause est déclenchée pour une saisie invalide,
     * alors affiche une modal de confirmation.
     */
    const onInvalidSaisiePaused = useCallback(() => {
        setModalRemoveProduitsOpen(true);
        setConfirmationModalState({
            isModalOpen: modalRemoveProduitsOpen,
            messages: pauseSaisieFacture,
            actionOnValidation: () => {
                navigateToMediaObjectsList();
            },
            actionOnCancellation: () => {
                setModalRemoveProduitsOpen(false);
            },
        });
    }, [modalRemoveProduitsOpen, navigateToMediaObjectsList]);

    /**
     * Actions déclenchées pour mettre en pause une saisie de facture multi-annonces.
     */
    const onSaisiePaused = useCallback(() => {
        formContext.handleSubmit(
            (factureForm) => {
                onValidSaisiePaused(factureForm);
            },
            () => {
                onInvalidSaisiePaused();
            },
        )();
    }, [formContext, onInvalidSaisiePaused, onValidSaisiePaused]);

    /**
     * Actions déclenchées pour annuler une saisie de facture multi-annonces.
     * TODO: L'action d'annulation ne doit pas ramener un utilisateur mada vers la liste, mais plutôt vers la prochaine saisie en ne soumettant pas la saisie courante ? A confirmer.
     */
    const onSaisieCanceled = useBackFromMediaObjectEdit();

    return !factureAchat || !fournisseurSelected ? (
        <CenterCircularProgress sx={{ height: '500px' }} />
    ) : (
        <>
            <SearchDossierModal
                isModalOpen={modalDossierOpen}
                isDraggable
                closeModal={(dossier: Dossier | null) => {
                    if (dossier) {
                        dispatch({
                            type: `${DOSSIERS_SLICE_NAME}/set${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
                            payload: dossier,
                        });
                    }
                    setModalDossierOpen(false);
                }}
            />

            <SearchDossierFormaliteModal
                isModalOpen={modalDossierFormaliteSearchOpen}
                isDraggable
                closeModal={(dossier: DossierFormalite | null) => {
                    if (dossier) {
                        dispatch({
                            type: `${DOSSIERS_FORMALITES_SLICE_NAME}/set${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
                            payload: dossier,
                        });
                    }
                    setModalDossierFormaliteSearchOpen(false);
                }}
            />

            <ConfirmationModal
                isModalOpen={modalConfirmSubmit}
                closeModal={() => {
                    setModalConfirmSubmit(false);
                }}
                messages={confirmationSaisieFactureModalMessages}
                actionOnValidation={() => {
                    formContext.handleSubmit(
                        (d) => {
                            setValidSubmit(true);
                            handleSubmit(d as FactureFormObject);
                            setModalConfirmSubmit(false);
                        },
                        (e) => console.error(e),
                    )();
                    setModalConfirmSubmit(false);
                }}
                actionOnCancellation={() => {
                    setValidSubmit(false);
                    setModalConfirmSubmit(false);
                }}
            />

            {/* Modal produits */}
            <ConfirmationModal
                isModalOpen={modalRemoveProduitsOpen}
                closeModal={() => {
                    confirmationModalState.actionOnCancellation();
                }}
                messages={confirmationModalState.messages}
                actionOnValidation={() => {
                    confirmationModalState.actionOnValidation();
                    setModalRemoveProduitsOpen(false);
                }}
                actionOnCancellation={() => {
                    confirmationModalState.actionOnCancellation();
                }}
            />

            {/* Modal dossier commande - only for mada */}
            <ConfirmationModal
                isModalOpen={modalDossierCommandeOpen}
                closeModal={() => {
                    setModalDossierCommandeOpen(false);
                }}
                messages={saisieDossierCommandeModalMessages}
                actionOnValidation={() => {
                    setModalDossierCommandeOpen(false);
                    onSaisieCanceled();
                }}
                actionOnCancellation={() => {
                    setModalDossierCommandeOpen(false);
                    window.location.reload();
                }}
            />

            {/* Modal dossier formalité - only for mada */}
            <ConfirmationModal
                isModalOpen={modalDossierFormaliteOpen}
                closeModal={() => {
                    setModalDossierFormaliteOpen(false);
                }}
                messages={saisieDossierFormaliteModalMessages}
                actionOnValidation={() => {
                    setModalDossierFormaliteOpen(false);
                    onSaisieCanceled();
                }}
                actionOnCancellation={() => {
                    setModalDossierFormaliteOpen(false);
                    window.location.reload();
                }}
            />

            <Modalizer
                open={modalRemiseOpen}
                onClose={() => {
                    setModalRemiseOpen(false);
                }}
            >
                <Box>
                    <FlexyForm
                        formObject={{
                            'facture-form-modal-txremise': rsfInclusTxremise,
                        }}
                        formStructure={modalFactureFormStructure}
                        onSubmit={handleModalSubmit}
                        submitButton={{
                            render: ({
                                isSubmitSuccessful,
                                isSubmitting,
                                isLoading,
                                isValidating,
                            }) => (
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="end"
                                >
                                    <Button
                                        type="submit"
                                        disabled={
                                            isSubmitSuccessful ||
                                            isSubmitting ||
                                            isLoading ||
                                            isValidating
                                        }
                                        variant="contained"
                                        style={{ marginTop: '16px', marginBottom: '16px' }}
                                    >
                                        Appliquer la remise
                                    </Button>
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </Modalizer>

            {factureAchat.idEntiteFacturante === fournisseurSelected.id ? (
                <EnteteFacture
                    invoice={factureAchat}
                    publisher={fournisseurSelected}
                    headerControllers={
                        <ButtonGroup
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '10px',
                                flex: 1,
                            }}
                        >
                            {dossiers ? (
                                <DossiersList
                                    facture={factureAchat}
                                    dossiers={dossiers}
                                />
                            ) : null}
                            {dossierSelected && dossierSelected.id && factureAchat.id ? (
                                <NotesButton
                                    idMediaObject={Number(mediaObject.id)}
                                    idFacture={factureAchat.id}
                                    idDossier={dossierSelected.id}
                                />
                            ) : null}
                        </ButtonGroup>
                    }
                />
            ) : null}

            <FlexyForm
                formObject={{}}
                formStructure={factureFormStructure}
                onSubmit={() => setModalConfirmSubmit(true)}
                formContext={formContext}
                submitButton={{
                    render: ({ isSubmitSuccessful, isSubmitting, isLoading, isValidating }) => {
                        const reasonToLock: boolean =
                            (isSubmitSuccessful || isSubmitting || isLoading || isValidating) &&
                            validSubmit;

                        return (
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                            >
                                <Button
                                    color="error"
                                    disabled={reasonToLock}
                                    variant="contained"
                                    endIcon={<FontAwesomeIcon icon={faPause} />}
                                    onClick={() => onSaisiePaused()}
                                    style={{
                                        marginTop: '16px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    Mettre en pause la saisie
                                </Button>
                                <Button
                                    color="primary"
                                    disabled={reasonToLock}
                                    variant="contained"
                                    endIcon={<FontAwesomeIcon icon={faListCheck} />}
                                    onClick={() => {
                                        formContext.handleSubmit(
                                            (factureForm) => {
                                                const factureToSubmit = { ...factureForm };
                                                factureToSubmit.end = true;
                                                handleSubmit(
                                                    factureToSubmit as FactureFormObject,
                                                ).then(
                                                    () => {
                                                        enqueueSnackbar(
                                                            <Typography>
                                                                Le dossier a bien été enregistré.
                                                            </Typography>,
                                                            { variant: 'success' },
                                                        );
                                                    },
                                                    (err) => {
                                                        console.error(err);
                                                        enqueueSnackbar(
                                                            <Typography>
                                                                Une erreur est survenue lors de
                                                                l&apos;enregistrement du dossier.
                                                            </Typography>,
                                                            { variant: 'error' },
                                                        );
                                                    },
                                                );
                                            },
                                            () => {
                                                setModalRemoveProduitsOpen(true);
                                                setConfirmationModalState({
                                                    isModalOpen: modalRemoveProduitsOpen,
                                                    messages: cancelAddDossier,
                                                    actionOnValidation: () => {
                                                        if (state.defDomain === 3) {
                                                            navigateToFactureAchatRecapFormalite(
                                                                factureAchat,
                                                                mediaObject,
                                                            );
                                                        } else {
                                                            navigateToFactureAchatRecap(
                                                                factureAchat,
                                                                mediaObject,
                                                            );
                                                        }
                                                    },
                                                    actionOnCancellation: () => {
                                                        setModalRemoveProduitsOpen(false);
                                                    },
                                                });
                                            },
                                        )();
                                    }}
                                    style={{
                                        marginTop: '16px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    Consulter le récapitulatif
                                </Button>
                                <Button
                                    color="primary"
                                    disabled={reasonToLock}
                                    type="submit"
                                    variant="outlined"
                                    endIcon={<FontAwesomeIcon icon={faForward} />}
                                    style={{
                                        marginTop: '16px',
                                        marginBottom: '16px',
                                    }}
                                >
                                    Dossier suivant
                                </Button>
                            </Box>
                        );
                    },
                }}
            />
        </>
    );
};

export default DossierSaisieMultiple;
