import React from 'react';
import { DataCard } from '@europrocurement/flexy-components';
import {
    FaOptionIcon,
    invoiceStatementIcon,
    pausedStatementIcon,
} from '@europrocurement/l2d-icons';
import { Button, Grid, Typography } from '@mui/material';
import { faFileInvoice } from '@fortawesome/pro-duotone-svg-icons';
import { faPause, faPenToSquare, faSync, faBug, faUser } from '@fortawesome/pro-solid-svg-icons';
import {
    AppDispatch,
    CategorieStatsSelector,
    customizerSelector,
    factureAchatApi,
    getFactureAchatStats,
    mediaObjectApi,
} from '@b2d/redux/RootStore';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    CategorieStats,
    FACTURES_ACHATS_SLICE_NAME,
    FACTURES_ACHATS_STATS_DATASOURCE_NAME,
} from '@europrocurement/l2d-domain';
import {
    KeycloakHasRole,
    UseKeycloakCheckRole,
    UseKeycloakService,
} from '@europrocurement/l2d-keycloak';
import achatsViewPathResolver from '@b2d/pages/Achats/achatsViewPathResolver';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import models from '@b2d/pages/Achats/models';

const PortailAchatWidgets = function () {
    const { xIdSociete } = useSelector(customizerSelector);

    const categoriesDataSource: DataSource<CategorieStats> =
        useSelector(CategorieStatsSelector).main;

    const kc = UseKeycloakService();
    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const [nbATraiter, setNbATraiter] = React.useState<string>('...');
    const [mesImports, setMesImports] = React.useState<string>('...');
    const [unassignedImports, setUnassignedImports] = React.useState<string>('...');
    const [nbASync, setNbASync] = React.useState<string>('...');
    const [nbEnPause, setNbEnPause] = React.useState<string>('...');
    const [countUnclosedStatements, setCountUnclosedStatements] = React.useState<string>('...');
    const [nbEnErdo5, setNbEnErdo5] = React.useState<string>('...');
    const [nbErreur, setNbErreur] = React.useState<string>('...');

    React.useEffect(() => {
        // a traiter
        mediaObjectApi
            .listMediaObjectMediaObjectCollection({
                page: 1,
                itemsPerPage: 0,
                // createdBy: '',
                forceInterne: false,
                dateDebutTraitementBefore: new Date(
                    new Date().getTime() - 2 * 60 * 1000,
                ).toISOString(),
                available: true,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setNbATraiter(`${res.data['hydra:totalItems']}`);
                }
            });
        // mes imports
        mediaObjectApi
            .listMediaObjectMediaObjectCollection({
                page: 1,
                itemsPerPage: 0,
                createdBy: kc.getEmail(),
                dateDebutTraitementBefore: new Date(
                    new Date().getTime() - 2 * 60 * 1000,
                ).toISOString(),
                available: true,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setMesImports(`${res.data['hydra:totalItems']}`);
                }
            });
        // non assigné imports
        mediaObjectApi
            .listMediaObjectMediaObjectCollection({
                page: 1,
                itemsPerPage: 0,
                createdBy: 'service-account-api-formalite',
                forceInterne: true,
                dateDebutTraitementBefore: new Date(
                    new Date().getTime() - 2 * 60 * 1000,
                ).toISOString(),
                available: true,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setUnassignedImports(`${res.data['hydra:totalItems']}`);
                }
            });
        // a sync
        factureAchatApi
            .getFacturesAchatsFactureAchatCollection({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: true,
                syncG3: false,
                deleted: false,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setNbASync(`${res.data['hydra:totalItems']}`);
                }
            });
        // en pause
        factureAchatApi
            .getFacturesAchatsFactureAchatCollection({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: false,
                deleted: false,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setNbEnPause(`${res.data['hydra:totalItems']}`);
                }
            });
        // relevés non clôturés
        models.invoiceStatement
            .list({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: false,
                deleted: false,
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setCountUnclosedStatements(`${res.data['hydra:totalItems']}`);
                }
            });
        // en ERDO5
        factureAchatApi
            .getFacturesAchatsFactureAchatCollection({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: true,
                deleted: false,
                codesRejetsCodeRejetCode: 'ERDO5',
                codesRejetsEtat: 'todo',
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setNbEnErdo5(`${res.data['hydra:totalItems']}`);
                }
            });

        // en erreur tech
        factureAchatApi
            .getFacturesAchatsFactureAchatCollection({
                xIdSociete,
                page: 1,
                itemsPerPage: 0,
                cloture: true,
                deleted: false,
                codesRejetsEtat: 'error',
            })
            .then((res) => {
                if (
                    res.data['hydra:totalItems'] !== null ||
                    res.data['hydra:totalItems'] !== undefined
                ) {
                    setNbErreur(`${res.data['hydra:totalItems']}`);
                }
            });
    }, [kc, xIdSociete]);

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    return (
        <>
            <Grid
                container
                columns={isInterne ? 5 : 2}
            >
                <Grid
                    sx={{
                        flex: 1,
                    }}
                    item
                    lg={1}
                    md={1}
                    xs={12}
                >
                    <DataCard
                        title="Factures à traiter"
                        color="success"
                        iconBgColor="transparent"
                        iconColor="transparent"
                        icon={
                            <FaOptionIcon
                                icon={faFileInvoice}
                                option={faPenToSquare}
                                size="2x"
                                color="success.main"
                                optionColor="success.dark"
                                iconProps={{
                                    radius: 100,
                                }}
                            />
                        }
                        tools={
                            <Button
                                variant="outlined"
                                color="success"
                                onClick={() => {
                                    navigate(achatsViewPathResolver('listmediaobject'));
                                }}
                            >
                                Traiter
                            </Button>
                        }
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                textAlign: 'center',
                            }}
                        >
                            Factures à traiter :
                        </Typography>
                        <Typography
                            component="h2"
                            color="success.main"
                            sx={{
                                fontSize: '32px',
                                textAlign: 'center',
                            }}
                        >
                            <b>{nbATraiter}</b>
                        </Typography>
                    </DataCard>
                </Grid>

                <Grid
                    sx={{
                        flex: 1,
                    }}
                    item
                    lg={1}
                    md={1}
                    xs={12}
                >
                    <DataCard
                        title="Factures en pause"
                        color="warning"
                        iconBgColor="transparent"
                        iconColor="transparent"
                        icon={<FaOptionIcon {...invoiceStatementIcon.props} />}
                        tools={
                            <Button
                                color="warning"
                                variant="outlined"
                                onClick={() => {
                                    navigate(achatsViewPathResolver('listfacturepause'));
                                }}
                            >
                                Traiter
                            </Button>
                        }
                    >
                        <Typography
                            sx={{
                                fontSize: '18px',
                                textAlign: 'center',
                            }}
                        >
                            Factures en pause :
                        </Typography>
                        <Typography
                            color="warning.main"
                            component="h2"
                            sx={{
                                fontSize: '32px',
                                textAlign: 'center',
                            }}
                        >
                            <b>{nbEnPause}</b>
                        </Typography>
                    </DataCard>
                </Grid>

                <KeycloakHasRole kcRole="realm:interne">
                    <>
                        <Grid
                            sx={{
                                flex: 1,
                            }}
                            item
                            lg={1}
                            md={1}
                            xs={12}
                        >
                            <DataCard
                                title="Relevés non clôturés"
                                color={models.invoiceStatement.color}
                                iconBgColor="transparent"
                                iconColor="transparent"
                                icon={<FaOptionIcon {...pausedStatementIcon.props} />}
                                tools={
                                    <Button
                                        color={models.invoiceStatement.color}
                                        variant="outlined"
                                        onClick={() => {
                                            navigate(
                                                achatsViewPathResolver('listInvoiceStatement'),
                                            );
                                        }}
                                    >
                                        Traiter
                                    </Button>
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Relevés non clôturés :
                                </Typography>
                                <Typography
                                    color={`${models.invoiceStatement.color}.main`}
                                    component="h2"
                                    sx={{
                                        fontSize: '32px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <b>{countUnclosedStatements}</b>
                                </Typography>
                            </DataCard>
                        </Grid>

                        <Grid
                            sx={{
                                flex: 1,
                            }}
                            item
                            lg={1}
                            md={1}
                            xs={12}
                        >
                            <DataCard
                                title="Mes factures à traiter"
                                color="blueGrey"
                                iconBgColor="transparent"
                                iconColor="transparent"
                                icon={
                                    <FaOptionIcon
                                        icon={faFileInvoice}
                                        option={faUser}
                                        size="2x"
                                        color="blueGrey.main"
                                        optionColor="blueGrey.dark"
                                    />
                                }
                                tools={
                                    <Button
                                        color="blueGrey"
                                        variant="outlined"
                                        onClick={() => {
                                            navigate(
                                                achatsViewPathResolver('listmediaobjectinternally'),
                                            );
                                        }}
                                    >
                                        Traiter
                                    </Button>
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Mes factures à traiter :
                                </Typography>
                                <Typography
                                    color="blueGrey.main"
                                    component="h2"
                                    sx={{
                                        fontSize: '32px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <b>{mesImports}</b>
                                </Typography>
                            </DataCard>
                        </Grid>

                        <Grid
                            sx={{
                                flex: 1,
                            }}
                            item
                            lg={1}
                            md={1}
                            xs={12}
                        >
                            <DataCard
                                title="Factures internes non attribuées"
                                color="purple"
                                iconBgColor="transparent"
                                iconColor="transparent"
                                icon={
                                    <FaOptionIcon
                                        icon={faFileInvoice}
                                        option={faUser}
                                        size="2x"
                                        color="purple.main"
                                        optionColor="purple.dark"
                                    />
                                }
                                tools={
                                    <Button
                                        color="purple"
                                        variant="outlined"
                                        onClick={() => {
                                            navigate(
                                                `${achatsViewPathResolver(
                                                    'listmediaobjectinternally',
                                                )}?tab=Factures+non+attribuées`,
                                            );
                                        }}
                                    >
                                        Traiter
                                    </Button>
                                }
                            >
                                <Typography
                                    sx={{
                                        fontSize: '18px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Factures int. non attribuées :
                                </Typography>
                                <Typography
                                    color="purple.main"
                                    component="h2"
                                    sx={{
                                        fontSize: '32px',
                                        textAlign: 'center',
                                    }}
                                >
                                    <b>{unassignedImports}</b>
                                </Typography>
                            </DataCard>
                        </Grid>
                    </>
                </KeycloakHasRole>
            </Grid>

            <KeycloakHasRole kcRole="realm:interne">
                <Grid
                    container
                    columns={isInterne ? 3 : 2}
                >
                    <Grid
                        sx={{
                            flex: 1,
                        }}
                        item
                        lg={1}
                        md={1}
                        xs={12}
                    >
                        <DataCard
                            title="Factures à synchroniser"
                            color="primary"
                            iconBgColor="transparent"
                            iconColor="transparent"
                            icon={
                                <FaOptionIcon
                                    icon={faFileInvoice}
                                    option={faSync}
                                    size="2x"
                                    color="primary.main"
                                    optionColor="primary.dark"
                                />
                            }
                            tools={
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        dispatch({
                                            type: `${FACTURES_ACHATS_SLICE_NAME}/resetmainFilter`,
                                        });

                                        dispatch({
                                            type: `${FACTURES_ACHATS_SLICE_NAME}/setmainFilter`,
                                            payload: {
                                                key: 'syncG3',
                                                value: false,
                                            },
                                        });

                                        navigate(achatsViewPathResolver('listfacture'));
                                    }}
                                >
                                    Consulter
                                </Button>
                            }
                        >
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    textAlign: 'center',
                                }}
                            >
                                Factures à synchroniser avec G3 :
                            </Typography>
                            <Typography
                                color="primary"
                                component="h2"
                                sx={{
                                    fontSize: '32px',
                                    textAlign: 'center',
                                }}
                            >
                                <b>{nbASync}</b>
                            </Typography>
                        </DataCard>
                    </Grid>

                    <Grid
                        sx={{
                            flex: 1,
                        }}
                        item
                        lg={1}
                        md={1}
                        xs={12}
                    >
                        <DataCard
                            title="Factures avec erreurs techniques"
                            color="error"
                            iconBgColor="transparent"
                            iconColor="transparent"
                            icon={
                                <FaOptionIcon
                                    icon={faFileInvoice}
                                    option={faBug}
                                    size="2x"
                                    color="error.main"
                                    optionColor="error.dark"
                                />
                            }
                            tools={
                                <Button
                                    color="error"
                                    variant="outlined"
                                    onClick={() => {
                                        dispatch({
                                            type: `${FACTURES_ACHATS_SLICE_NAME}/resetmainFilter`,
                                        });

                                        dispatch({
                                            type: `${FACTURES_ACHATS_SLICE_NAME}/setmainFilter`,
                                            payload: {
                                                key: 'codesRejetsEtat',
                                                value: 'error',
                                            },
                                        });

                                        navigate(achatsViewPathResolver('listfacture'));
                                    }}
                                >
                                    Consulter
                                </Button>
                            }
                        >
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    textAlign: 'center',
                                }}
                            >
                                Factures avec erreur technique :
                            </Typography>
                            <Typography
                                color="error.main"
                                component="h2"
                                sx={{
                                    fontSize: '32px',
                                    textAlign: 'center',
                                }}
                            >
                                <b>{nbErreur}</b>
                            </Typography>
                        </DataCard>
                    </Grid>
                    <Grid
                        sx={{
                            flex: 1,
                        }}
                        item
                        lg={1}
                        md={1}
                        xs={12}
                    >
                        <DataCard
                            title="Factures en ERDO5"
                            color="ERDO"
                            iconBgColor="transparent"
                            iconColor="transparent"
                            icon={
                                <FaOptionIcon
                                    icon={faFileInvoice}
                                    option={faPause}
                                    size="2x"
                                    color="ERDO.main"
                                    optionColor="ERDO.dark"
                                />
                            }
                            tools={
                                <Button
                                    color="ERDO"
                                    variant="outlined"
                                    onClick={() => {
                                        dispatch({
                                            type: `${categoriesDataSource.slicename}/set${categoriesDataSource.name}Filter`,
                                            payload: {
                                                key: 'codesRejets',
                                                value: ['ERDO5'],
                                            },
                                        });
                                        dispatch({
                                            type: `${FACTURES_ACHATS_SLICE_NAME}/set${FACTURES_ACHATS_STATS_DATASOURCE_NAME}Filter`,
                                            payload: {
                                                key: 'codesRejetsEtat',
                                                value: 'todo',
                                            },
                                        });

                                        dispatch(getFactureAchatStats({}));

                                        navigate('/achats/stats?tab=detail');
                                    }}
                                >
                                    Consulter
                                </Button>
                            }
                        >
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    textAlign: 'center',
                                }}
                            >
                                Factures en ERDO5 :
                            </Typography>
                            <Typography
                                color="ERDO.main"
                                component="h2"
                                sx={{
                                    fontSize: '32px',
                                    textAlign: 'center',
                                }}
                            >
                                <b>{nbEnErdo5}</b>
                            </Typography>
                        </DataCard>
                    </Grid>
                </Grid>
            </KeycloakHasRole>
        </>
    );
};
export default PortailAchatWidgets;
