import { TypeDeDocumentJsonldTypedocumentRead } from '@europrocurement/l2d-domain/openApi/ApiOffre';
import { createSlice } from '@reduxjs/toolkit';

export type GenericCachedResult<T> = Array<T> | null;

export type CachedStore = {
    documents: GenericCachedResult<TypeDeDocumentJsonldTypedocumentRead['typeDocument']>;
};

export const OFFER_CACHED_KEY = 'cached';

const initialCacheState: CachedStore = {
    documents: null,
};

const offerCacheSlice = createSlice({
    name: OFFER_CACHED_KEY,
    initialState: initialCacheState,
    reducers: {
        updateCachedDocuments(state, action) {
            // eslint-disable-next-line no-param-reassign
            state.documents = action.payload;
        },
    },
});

export const { updateCachedDocuments } = offerCacheSlice.actions;

export default offerCacheSlice;
