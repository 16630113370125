/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import html2canvas from 'html2canvas';

/** layouts */
import {
    FullLayout,
    FullLayoutProps,
    SnackbarCloseButton,
    useModal,
} from '@europrocurement/flexy-components';

import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
/** end layouts */

/** pages */
import { Box } from '@mui/material';
import { SnackbarAction, SnackbarKey, SnackbarProvider } from 'notistack';
import {
    B2DLogoIcon,
    B2DSymboleIcon,
    FaOptionIcon,
    L2DFLogoSmallIcon,
    supportIcon,
} from '@europrocurement/l2d-icons';
import SupportForm from '@b2d/components/SupportForm';

/** end pages */

/** menu */

/** end menu */

import generateB2DPath, { GenerateB2DPathResponseType } from '../utils/generateB2DPath';

function srcToFile(src: string, fileName: string, mimeType: string) {
    return fetch(src)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], fileName, { type: mimeType }));
}

const B2DLayout: FunctionComponent<Omit<FullLayoutProps, 'logo' | 'logoFolded'>> = function (
    props,
) {
    const ToCaptureRef = React.useRef<HTMLDivElement>(null); // ref

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    const location = useLocation();
    const navigate = useNavigate();

    const closeBtn: SnackbarAction = (key: SnackbarKey) => (
        <SnackbarCloseButton snackbarKey={key} />
    );

    const { modalActions } = useModal();

    const captureScreenshot = () => {
        if (ToCaptureRef.current) {
            const canvasPromise = html2canvas(ToCaptureRef.current, {
                useCORS: true, // in case you have images stored in your application
                logging: false,
            });
            canvasPromise
                .then((canvas: { toDataURL: (arg0: string) => any }) => {
                    const dataURL = canvas.toDataURL('screeshoot/png');
                    // Create an image element from the data URL
                    const img = new Image();
                    img.src = dataURL;
                    return Promise.resolve(img);
                })
                .then((img: HTMLImageElement) => srcToFile(img.src, 'screenShoot.png', 'image/png'))
                .then((screenShoot: File) => {
                    modalActions.call(<SupportForm screenShoot={screenShoot} />, true, true);
                });
        }
    };

    const layoutProps: FullLayoutProps = {
        ...props,
        defaultContextActions: [
            {
                name: 'Support',
                action: () => {
                    captureScreenshot();
                },
                icon: <FaOptionIcon {...supportIcon.props} />,
            },
        ],
        contextActionMainIcon: (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <B2DSymboleIcon style={{ maxHeight: '24px' }} />
            </Box>
        ),
        logo: <B2DLogoIcon style={{ width: '100%', height: '100%' }} />,
        logoFolded: <L2DFLogoSmallIcon style={{ width: '100%', height: '100%' }} />,
    };

    const returnArrowClickCallbackHandle = useCallback(async () => {
        let response: GenerateB2DPathResponseType = {
            status: 'STAY',
        };

        if (location.pathname.match(/\/achats\/liste\/saisir\/(\d+)/)) {
            response = isInterne
                ? generateB2DPath('listmediaobjectinternally')
                : generateB2DPath('listmediaobject');
        }
        if (
            location.pathname.match(/\/achats\/liste\/modifier/) ||
            location.pathname.match(/\/achats\/liste\/recap/)
        )
            response = generateB2DPath('listfacture');

        if (location.pathname.match(/\/achats\/statement/))
            response = generateB2DPath('listInvoiceStatement');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    }, [isInterne, location.pathname, navigate]);

    return (
        <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            action={closeBtn}
            variant="success"
        >
            <div ref={ToCaptureRef}>
                <FullLayout
                    {...layoutProps}
                    headerProps={{
                        // returnArrowClickCallback: ()=>{navigateTo()} // quand mon callback est trigger, j'execute navigateTo()
                        handleClickExitButton: returnArrowClickCallbackHandle,
                    }}
                />
            </div>
        </SnackbarProvider>
    );
};

export default B2DLayout;
