import { useDispatch, useSelector } from 'react-redux';
import { UseFormReturn } from 'react-hook-form';
import { AxiosResponse } from 'axios';

import {
    COMMANDES_SLICE_NAME,
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    DOSSIERS_FORMALITES_SLICE_NAME,
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    FACTURE_VENTE_SLICE_NAME,
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
    FOURNISSEUR_SLICE_NAME,
} from '@europrocurement/l2d-domain';
import { FormObject } from '@europrocurement/flexy-form';
import {
    FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest,
    FactureAchatNoteJsonldFactureAchatRead,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { FactureFormSelector, RootStateType, factureAchatNoteApi } from '@b2d/redux/RootStore';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';

import { NoteFactureForm } from '../types';
import { resetDossierFormaliteFields, resetDossiersFields } from './dossierFonctions';
import { resetFournisseursFields } from './fournisseurFunctions';
import formNoteToApiInvoicePurchaseNoteArrayPost from './dataTransformers/formToApi/formNoteToApiInvoicePurchaseNoteArrayPost';

/**
 *
 * @param formContext
 */
export const resetDossierAndFournisseurs = function (formContext: UseFormReturn<FormObject>) {
    resetDossiersFields(formContext);
    resetFournisseursFields(formContext);
};

export const resetDossierFormalite = function (formContext: UseFormReturn<FormObject>) {
    resetDossierFormaliteFields(formContext);
};

/**
 * Vide les champs du formulaire et les DataSources selected
 *
 * @returns void
 */
export const useResetFactureAchatState = function () {
    const state = useSelector(FactureFormSelector);

    const dispatch = useDispatch();
    const {
        defaultNumeroDossierFormalite,
        defaultNumeroDossier,
        defaultNumeroPresta,
        defaultSiren,
    } = state;

    const factureVente = useSelector((s: RootStateType) => s.dossiers.facturevente.main.selected);
    const dossierFormalite = useSelector(
        (s: RootStateType) =>
            s.formalites.dosform[DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME].selected,
    );
    const dossier = useSelector(
        (s: RootStateType) => s.dossiers.dos[DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME].selected,
    );
    const commande = useSelector((s: RootStateType) => s.dossiers.commande.main.selected);

    const fournisseur = useSelector(
        (s: RootStateType) =>
            s.tiers[FOURNISSEUR_SLICE_NAME][FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME]
                .selected,
    );

    return (formContext: UseFormReturn<FormObject>) => {
        // resetDossierAndFournisseurs(formContext);
        resetDossierFormalite(formContext);

        formContext.setValue('fournisseur', {});
        formContext.setValue('numero_dossier_formalite', null);
        formContext.setValue('numero_dossier', null);
        formContext.setValue('numero_annonce', null);

        if (defaultNumeroDossierFormalite !== '') {
            dispatch({
                type: ACTIONS.SET_NUM_DOSSIER_FORMALITE,
                payload: '',
            });
        }
        if (defaultNumeroDossier !== '') {
            dispatch({
                type: ACTIONS.SET_NUM_DOSSIER,
                payload: '',
            });
        }
        if (defaultNumeroPresta !== '') {
            dispatch({
                type: ACTIONS.SET_NUM_PRESTA,
                payload: '',
            });
        }
        if (defaultSiren !== '') {
            dispatch({
                type: ACTIONS.SET_SIREN,
                payload: '',
            });
        }
        if (fournisseur) {
            dispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/delete${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Selected`,
            });
        }
        if (factureVente) {
            dispatch({
                type: `${FACTURE_VENTE_SLICE_NAME}/deletemainSelected`,
            });
        }
        if (dossierFormalite) {
            dispatch({
                type: `${DOSSIERS_FORMALITES_SLICE_NAME}/delete${DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
            });
        }
        if (dossier) {
            dispatch({
                type: `${DOSSIERS_SLICE_NAME}/delete${DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME}Selected`,
            });
        }
        if (commande) {
            dispatch({
                type: `${COMMANDES_SLICE_NAME}/deletemainSelected`,
            });
        }
    };
};

/**
 *
 * @returns
 */
export const useResetAllForm = function () {
    const dispatch = useDispatch();
    const resetStates = useResetFactureAchatState();

    return (formContext: UseFormReturn<FormObject>) => {
        resetStates(formContext);
        dispatch({
            type: ACTIONS.RESET,
        });
    };
};

/**
 * Création des notes.
 *
 * @param factureForm
 * @returns Promise<any>;
 */
export const createNotes = async (factureAchatIri: string, notes: NoteFactureForm[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Promise<AxiosResponse<FactureAchatNoteJsonldFactureAchatRead, any>>[] = [];
    const formatedFactureAchatNotes = formNoteToApiInvoicePurchaseNoteArrayPost(
        notes,
        factureAchatIri,
    );

    formatedFactureAchatNotes.map((note) => {
        const requestParameters: FactureAchatNoteApiCreateFactureAchatNoteFactureAchatNoteCollectionRequest =
            {
                factureAchatNoteJsonldFactureAchatNoteCreate: note,
            };

        return promises.push(
            factureAchatNoteApi.createFactureAchatNoteFactureAchatNoteCollection(requestParameters),
        );
    });

    return await Promise.all(promises);
};
