import { FormStructure } from '@europrocurement/flexy-form';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons';
import { DossierFormalite } from '@europrocurement/l2d-domain';
import { Button } from '@mui/material';
import HeaderStructure from '../../../fragments/HeaderStructure';
import { resetDossierFormaliteFields } from '../../functions/dossierFonctions';
import {
    FolioFormalitySectionWithAdvancedResearchStructureProps,
    FolioFormalitySectionStructureProps,
    GetFormalityAutocompleteStructureProps,
    SectionHeadControlProps,
    AutocompleteFormalityStructureProps,
} from '../types';
import { autocompleteStructureReadonly } from './common';

const autocompleteFormalityStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    onSelectOption,
    mode,
}: AutocompleteFormalityStructureProps): FormStructure | null => {
    if (!dataSource || !fetchData) {
        console.error('Missing prop to render formality autocomplete field !');

        return null;
    }

    return {
        type: 'autocompletestore',
        name: 'numero_dossier_formalite',
        inputlabel: 'Recherche par numéro de dossier de formalité',
        dataSource,
        fetchData,
        renderField: 'numero',
        defaultInputValue,
        idProperty: 'id',
        debounce: 1000,
        rules: {
            required:
                mode.type === 'register'
                    ? 'Vous devez renseigner le numero de dossier de formalité'
                    : undefined,
        },
        events: {
            onClear: (event, newValue, reason, details, formContext) => {
                resetDossierFormaliteFields(formContext);
            },
            onSelectOption,
        },
        getOptionLabel: (item: DossierFormalite) => `${item.numero}` || '',
        // Se rends dans les options
        simpleRenderOption: (item: DossierFormalite) => `${item.numero}`,
        searchability: (terms) =>
            terms.length >= 5 ? true : 'Veuiez saisir au moins 5 caractères',
        xs: 12,
        sm: 12,
        md: 12,
        lg: 6,
    };
};

const getFormalityAutocompleteStructure = ({
    dataSource,
    fetchData,
    defaultInputValue,
    onSelectOption,
    mode,
}: GetFormalityAutocompleteStructureProps): FormStructure | null => {
    const booleanEditMode = mode.type === 'update' && mode.lock;

    if (booleanEditMode)
        return autocompleteStructureReadonly({
            name: 'numero_dossier_formalite',
            inputlabel: 'Numéro de dossier de formalité',
        });

    return autocompleteFormalityStructure({
        mode,
        dataSource,
        fetchData,
        defaultInputValue,
        onSelectOption,
    });
};

const folioFormalitySectionStructure = ({
    disabled,
    mode,
    autocompleteProps,
    headerSwitches = [],
    headerChildren = [],
    hideHeader = false,
    onSelectOption = () => {},
}: FolioFormalitySectionStructureProps): Array<FormStructure> => {
    const folioFormalityAutocompleteStructure = getFormalityAutocompleteStructure({
        dataSource: autocompleteProps?.formality?.dataSource,
        fetchData: autocompleteProps?.formality?.fetchData,
        defaultInputValue: autocompleteProps?.formality?.defaultValue,
        onSelectOption,
        mode,
    });

    const autocompleteStructures = [];

    if (folioFormalityAutocompleteStructure !== null) {
        autocompleteStructures.push(folioFormalityAutocompleteStructure);
    }

    let headerStructure: Array<FormStructure> = [];

    if (!hideHeader) {
        headerStructure = [
            {
                type: 'header',
                label: (
                    <HeaderStructure
                        icon={<FontAwesomeIcon icon={faFolderOpen} />}
                        title="Dossier Formalité"
                        controls={headerChildren}
                        switches={headerSwitches}
                    />
                ),
                name: 'file_header',
            },
        ];
    }

    return [
        ...headerStructure,
        ...autocompleteStructures,
        {
            type: 'text',
            name: 'denomination_societe_formalite',
            inputlabel: 'Dénomination société',
            disabled,
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
        },
    ];
};

const folioFormalitySectionWithAdvancedResearchStructure = ({
    onClickModal,
    autocompleteProps,
    mode,
    headerSwitches = [],
    headerChildren = [],
}: FolioFormalitySectionWithAdvancedResearchStructureProps): Array<FormStructure> => {
    let overwriteHeaderChildren: Array<SectionHeadControlProps> = [];

    if (headerChildren && Array.isArray(headerChildren)) {
        overwriteHeaderChildren = [...overwriteHeaderChildren, ...headerChildren];
    } else {
        overwriteHeaderChildren.push(headerChildren);
    }

    if (mode.type === 'register') {
        overwriteHeaderChildren.push(
            <Button
                onClick={() => onClickModal()}
                variant="outlined"
                size="small"
                color="secondary"
                sx={{ marginLeft: 'auto' }}
            >
                Recherche avancée
            </Button>,
        );
    }

    return folioFormalitySectionStructure({
        disabled: true,
        mode,
        autocompleteProps,
        headerSwitches,
        headerChildren: overwriteHeaderChildren,
    });
};

export {
    autocompleteFormalityStructure,
    getFormalityAutocompleteStructure,
    folioFormalitySectionStructure,
    folioFormalitySectionWithAdvancedResearchStructure,
};
