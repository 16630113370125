// React imports
import React, { useEffect } from 'react';

// Components imports
import { Avatar, Box, Typography } from '@mui/material';
import { CenterCircularProgress, LazyBreadcrumb } from '@europrocurement/flexy-components';

// Data fetching / utils / custom hooks
import ApartmentIcon from '@mui/icons-material/Apartment';
import PersonIcon from '@mui/icons-material/Person';
import { useSociete } from '@b2d/hooks/societeHooks';
import { useParams } from 'react-router';
import { useCurrentPrescriber, useSelectPrescriber } from '@b2d/hooks/prescriberHooks';

import PrescripteurInformationsCard from './PrescripteurInformationsCard';

export type ModalFunctions = {
    toggleModal: () => void;
    closeModal: () => void;
    openModal: () => void;
    setModalContent: (element: React.ReactNode | null) => void;
};

const PrescripteurFiche: React.FunctionComponent = function () {
    const { idPrescripteur: urlPrescriberId } = useParams();

    const prescriber = useCurrentPrescriber();
    const companyId = useSociete();
    const selectPrescriber = useSelectPrescriber();

    const isLoading = !prescriber;

    useEffect(() => {
        const { signal } = new AbortController();
        if (urlPrescriberId) {
            console.log('here', urlPrescriberId);
            selectPrescriber(Number(urlPrescriberId), {
                signal,
            });
        }
    }, [selectPrescriber, urlPrescriberId]);

    if (isLoading) {
        return <CenterCircularProgress sx={{ height: '70vh' }} />;
    }

    const formatTitle = () => {
        const { raisonSociale: socialReason, relationsSocietes: companyRelation } = prescriber;

        const relation = companyRelation.find((item) => item.societe?.id === companyId);

        const customerCode = relation?.codeClient;
        const separator = ' - ';

        return customerCode ? socialReason + separator + customerCode : socialReason;
    };
    const title = formatTitle();

    return (
        prescriber?.id && (
            <Box
                sx={{
                    overflow: 'hidden',
                }}
            >
                <LazyBreadcrumb
                    path={[
                        { path: 'Prescripteurs', link: '/prescripteurs/liste' },
                        "Details d'un prescripteur",
                        // '',
                        // title,
                    ]}
                />

                <Box display="flex">
                    <Avatar sx={{ width: 50, height: 50, margin: '0px 15px' }}>
                        {prescriber.personnePhysique ? <PersonIcon /> : <ApartmentIcon />}
                    </Avatar>

                    <Typography variant="h1">{title}</Typography>
                    {prescriber.demo && (
                        <Avatar sx={{ bgcolor: 'red', width: 50, height: 50, margin: '0px 15px' }}>
                            <Typography fontSize="50">Demo</Typography>
                        </Avatar>
                    )}
                </Box>

                <PrescripteurInformationsCard />
            </Box>
        )
    );
};

export default React.memo(PrescripteurFiche);
