import type { Reducer } from '@reduxjs/toolkit';
import { APIS_ENUM, BDD_SWITCH_SOCIETES_IDS_TYPE, ENV, ENV_API } from '../../constants/env';
import {
    THEME_COLOR,
    DARK_THEME,
    SET_ID_SOCIETE,
    SET_ENV,
    type THEME_LIST,
    SET_ENV_API,
    SET_ALL_ENVS,
} from '../../constants';
import { defaultEnv } from './defaultEnv';

export type CustomizerReducerActiveModeType = 'light' | 'dark';
export type CustomizerReducerActiveThemeType = THEME_LIST;

export type CustomizerReducetApiType = {
    achats: ENV_API;
    tiers: ENV_API;
    dossiers: ENV_API;
    offre: ENV_API;
    formalite: ENV_API;
    facturation: ENV_API;
    sign: ENV_API;
    mail: ENV_API;
    formulaire: ENV_API;
};

export type CustomizerReducerType = {
    activeMode: CustomizerReducerActiveModeType; // This can be light or dark
    activeTheme: CustomizerReducerActiveThemeType; // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, ORANGE_THEME
    xIdSociete: BDD_SWITCH_SOCIETES_IDS_TYPE;
    sideBarState: 'open' | 'close';
    env: ENV;
    api: CustomizerReducetApiType;
};

const customizerLocalStorageState = localStorage.getItem('customizerState');

export const INIT_STATE: CustomizerReducerType = {
    activeMode: 'light', // This can be light or dark
    activeTheme: 'L2D_THEME', // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME, ORANGE_THEME, L2D_THEME
    xIdSociete: 2,
    sideBarState: 'open',
    env: defaultEnv,
    api: {
        achats: defaultEnv,
        tiers: defaultEnv,
        dossiers: defaultEnv,
        offre: defaultEnv,
        formalite: defaultEnv,
        facturation: defaultEnv,
        formulaire: defaultEnv,
        sign: defaultEnv,
        mail: defaultEnv,
    },
};

let state = {
    ...INIT_STATE,
};

if (customizerLocalStorageState && customizerLocalStorageState !== '') {
    try {
        state = {
            ...INIT_STATE,
            ...(JSON.parse(customizerLocalStorageState) as CustomizerReducerType),
        };

        if (localStorage.getItem('customizerState') !== JSON.stringify(state)) {
            localStorage.setItem('customizerState', JSON.stringify(state));
            window.location.reload();
        }
    } catch (e) {
        localStorage.setItem('customizerState', JSON.stringify(INIT_STATE));
        console.warn(e);
        window.location.reload();
    }
}

export const CustomizerReducer: Reducer<CustomizerReducerType> = (
    customizerState = state,
    action = { type: 'nothing' },
) => {
    let newState;
    let mustReload = false;
    let mustSave = false;
    switch (action.type) {
        case DARK_THEME:
            newState = {
                ...INIT_STATE,
                ...customizerState,
                activeMode: action.payload,
            };
            mustSave = true;
            break;
        case THEME_COLOR:
            newState = {
                ...INIT_STATE,
                ...customizerState,
                activeTheme: action.payload,
            };
            mustSave = true;
            break;
        case SET_ID_SOCIETE:
            newState = {
                ...INIT_STATE,
                ...customizerState,
                xIdSociete: action.payload,
            };
            mustSave = true;
            break;
        case SET_ALL_ENVS:
            newState = {
                ...INIT_STATE,
                ...customizerState,
                env: action.payload.keycloak,
                api: action.payload.apis,
            };
            mustSave = true;
            mustReload = true;
            break;
        case SET_ENV:
            newState = {
                ...INIT_STATE,
                ...customizerState,
                env: action.payload,
            };
            mustSave = true;
            mustReload = true;
            break;

        case SET_ENV_API:
            newState = {
                ...INIT_STATE,
                ...customizerState,
                api: { ...INIT_STATE.api, ...customizerState.api },
            };

            if (action.payload.api && action.payload.env) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const overide: any = {};
                overide[action.payload.api as APIS_ENUM] = action.payload.env as ENV_API;
                newState.api = {
                    ...INIT_STATE.api,
                    ...newState.api,
                    ...overide,
                };
            }
            mustReload = true;
            mustSave = true;
            break;
        default:
            newState = { ...customizerState };
            break;
    }

    if (mustSave) {
        localStorage.setItem('customizerState', JSON.stringify(newState));
    }

    if (mustReload) {
        window.location.reload();
    }

    return newState;
};

export default CustomizerReducer;
