import { modulePathResolver } from '../../utils/moduleResolver';
import {
    listePath,
    saisirFacturePath,
    saisirMultiFacturePath,
    saisirEnteteMultiFacturePath,
    saisirDossierMultiFacturePath,
    nextDossierMultiFacturePath,
    saisirEditDossierMultiFacturePath,
    nextEditDossierMultiFacturePath,
    recapMultiFacturePath,
    modifierFacturePath,
    consulterMultiFacturePath,
    traitePath,
    pausePath,
    downloadPath,
    uploadPath,
    statsPath,
    nextEditFproDossierMultiFacturePath,
    saisirEditFproDossierMultiFacturePath,
    recapFproMultiFacturePath,
    invoiceStatementPath,
    readUnclosedStatementPath,
    summaryStatementPath,
    registerStatementPath,
    saisirEnteteMultiFactureStatementPath,
    internMediaObjectsPath,
} from './constants/paths';

export type AchatsSuportedViewPathResolver =
    | 'formmediaobject'
    | 'formmultimediaobject'
    | 'formmultimediaobjectstatement'
    | 'formfactureachat'
    | 'listmediaobject'
    | 'listmediaobjectinternally'
    | 'listfacture'
    | 'listfacturepause'
    | 'listInvoiceStatement'
    | 'statementRegister'
    | 'unclosedStatementView'
    | 'statementRecap'
    | 'downloadMediaObject'
    | 'uploadMediaObject'
    | 'achatsstats'
    | 'formmultiadddossier'
    | 'formmultirecap'
    | 'formmultirecapfpro'
    | 'formmultinextdossier'
    | 'nextEditDossierMultiFacturePath'
    | 'nextEditFproDossierMultiFacturePath'
    | 'consulterMultiFacture'
    | 'formmultieditfprodossier'
    | 'formmultieditdossier';

export const baseMulti = [modulePathResolver('achats'), listePath, saisirMultiFacturePath].join(
    '/',
);

const achatsViewPathResolver = function (view: AchatsSuportedViewPathResolver) {
    const baseAchats = modulePathResolver('achats');

    let path = '';

    switch (view) {
        case 'listmediaobject':
            path = [baseAchats, listePath].join('/');
            break;
        case 'listmediaobjectinternally':
            path = [baseAchats, listePath, internMediaObjectsPath].join('/');
            break;
        case 'listInvoiceStatement':
            path = [baseAchats, listePath, invoiceStatementPath].join('/');
            break;
        case 'statementRegister':
            path = [baseAchats, registerStatementPath].join('/');
            break;
        case 'unclosedStatementView':
            path = [baseAchats, readUnclosedStatementPath].join('/');
            break;
        case 'statementRecap':
            path = [baseAchats, summaryStatementPath].join('/');
            break;
        case 'formmediaobject':
            path = [baseAchats, listePath, saisirFacturePath].join('/');
            break;
        case 'formmultimediaobject':
            path = [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                saisirEnteteMultiFacturePath,
            ].join('/');
            break;
        case 'formmultimediaobjectstatement':
            path = [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                saisirEnteteMultiFactureStatementPath,
            ].join('/');
            break;
        case 'formmultiadddossier':
            path = [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                saisirDossierMultiFacturePath,
            ].join('/');
            break;
        case 'formmultinextdossier':
            path = [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                nextDossierMultiFacturePath,
            ].join('/');

            break;
        case 'formmultieditdossier':
            path = [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                saisirEditDossierMultiFacturePath,
            ].join('/');
            break;
        case 'formmultieditfprodossier':
            path = [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                saisirEditFproDossierMultiFacturePath,
            ].join('/');
            break;
        case 'nextEditDossierMultiFacturePath':
            path = [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                nextEditDossierMultiFacturePath,
            ].join('/');
            break;
        case 'nextEditFproDossierMultiFacturePath':
            path = [
                baseAchats,
                listePath,
                saisirMultiFacturePath,
                nextEditFproDossierMultiFacturePath,
            ].join('/');
            break;
        case 'formmultirecap':
            path = [baseAchats, listePath, saisirMultiFacturePath, recapMultiFacturePath].join('/');
            break;
        case 'formmultirecapfpro':
            path = [baseAchats, listePath, saisirMultiFacturePath, recapFproMultiFacturePath].join(
                '/',
            );
            break;
        case 'formfactureachat':
            path = [baseAchats, listePath, modifierFacturePath].join('/');
            break;
        case 'consulterMultiFacture':
            path = [baseAchats, listePath, consulterMultiFacturePath].join('/');
            break;
        case 'listfacture':
            path = [baseAchats, listePath, traitePath].join('/');
            break;
        case 'listfacturepause':
            path = [baseAchats, listePath, pausePath].join('/');
            break;
        case 'downloadMediaObject':
            path = [baseAchats, listePath, downloadPath].join('/');
            break;
        case 'uploadMediaObject':
            path = [baseAchats, uploadPath].join('/');
            break;
        case 'achatsstats':
            path = [baseAchats, statsPath].join('/');
            break;
        default:
            throw new Error('unimplemented path');
    }

    return path;
};

export default achatsViewPathResolver;
