/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faServer, faTrashCan } from '@fortawesome/pro-duotone-svg-icons';
import { Card, Grid, Pagination, Stack, Typography } from '@mui/material';

import {
    CenterCircularProgress,
    PdfViewer,
    footerHeight,
    TopbarHeight,
    FlexyTabs,
    ContextActionType,
    useContextActions,
    ModalContext,
    HeaderSwitch,
    FlexyIconButton,
} from '@europrocurement/flexy-components';

import type {
    FactureAchatApiDeleteFactureAchatFactureAchatItemRequest,
    FactureAchatApiExportCodesRejetsRecapFactureAchatCollectionRequest,
    FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest,
    FactureAchatFactureAchatReadDossierDossiersInner,
    FactureAchatLigneJsonldFactureAchatRead,
    MediaObjectApiPatchMediaObjectItemRequest,
} from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { FlexyForm, FormStructure } from '@europrocurement/flexy-form';
import {
    CustomizerReducerType,
    FOURNISSEUR_SLICE_NAME,
    FactureAchat,
    Fournisseur,
    MediaObject,
    setXIdSociete,
} from '@europrocurement/l2d-domain';
import { DataSource, EuroprocApiResponseStatus } from '@europrocurement/l2d-redux-utils';
import { envBaseUrl } from '@b2d/utils/getBaseUrl';
import { useExport } from '@b2d/hooks/useExport';

import useToaster from '@b2d/hooks/useToaster';

import { useSnackbar } from 'notistack';
import useApiRequest from '@b2d/hooks/useApiRequest';
import { AxiosError } from 'axios';
import generateB2DPath, { GenerateB2DPathResponseType } from '@b2d/utils/generateB2DPath';
import { faRotate } from '@fortawesome/pro-solid-svg-icons';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { navigateToRecapStatement } from '@b2d/utils/navigationHelper';
import { FaOptionIcon, syncIcon } from '@europrocurement/l2d-icons';
import { filtersHasher } from '@europrocurement/l2d-utils';

import useTotalsSectionStructure from '@b2d/pages/Achats/components/forms/formElements/hooks/useTotalsSectionStructure';
import useSwitchLockValues from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import useUpdateTotals from '@b2d/pages/Achats/hooks/useUpdateTotals';
import { ModeProps } from '@b2d/pages/Achats/components/forms/formElements/types';
import useSyncG3 from '@b2d/pages/Achats/hooks/contextActions/useSyncG3';
import { contactSupport, refreshPage } from '@b2d/utils/wording';
import { convertToNumberAndFormatToString } from '@b2d/pages/Achats/components/forms/functions/calculsProduits';
import {
    MediaObjectSelector,
    selectMediaObject,
    selectFactureAchat,
    FactureachatSelector,
    FournisseurSelector,
    selectFournisseur,
    factureAchatApi,
    AppDispatch,
    customizerSelector,
    mediaObjectApi,
} from '../../../../../redux/RootStore';
import { exportDataDashboard } from '../../../constants/wording/toasts';

import { EnteteFacture } from '../../../components/forms/multi/EnteteFacture';
import { groupLinesByDossiers } from '../../../components/forms/functions/dossierFonctions';

import { DossierLinesAccordeon } from '../../../components/informationWidget/DossierLinesAccordeon';
import { getTotaux } from '../../../components/forms/functions/produitFunctions';
import { CodeRejetsChipType } from '../../../components/widgets/CodeRejetsChipList';

import {
    useGetDossiersFromAchat,
    useGetLignes,
    useGetRejets,
} from '../../../components/forms/functions/dataHooks';
import { NotesFacture } from '../../../components/widgets/NotesFacture';
import ConfirmationModalContent from '../../../components/modals/ConfirmationModalContent';
import { removeFactureModalMessages } from '../../../constants/wording/modals';
import HeaderButton from '../../../components/fragments/HeaderButton';
import { NotesMediaObject } from '../../../components/widgets/NotesMediaObject';

const UpdateMultiInvoiceView: FunctionComponent = function () {
    const { mediaobjectid, factureachatid } = useParams();
    const mediaObjectSelected: MediaObject = useSelector(MediaObjectSelector).main.selected;
    const factureAchatSelected: FactureAchat = useSelector(FactureachatSelector).main.selected;
    const fournisseurSelected: DataSource<Fournisseur> =
        useSelector(FournisseurSelector).main.selected;

    const formContext = useForm({
        mode: 'onTouched',
    });

    const [dossiersofFacture, setdossiersOfFacture] = useState<
        FactureAchatFactureAchatReadDossierDossiersInner[]
    >([]);
    const [lignes, setLignes] = useState<FactureAchatLigneJsonldFactureAchatRead[]>([]);
    const [rejets, setRejets] = useState<CodeRejetsChipType[]>([]);

    const [dossiersOfFactureStatus, setDossiersOfFactureStatus] =
        useState<EuroprocApiResponseStatus>('idle');
    const [lignesStatus, setLignesStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [rejetsStatus, setRejetsStatus] = useState<EuroprocApiResponseStatus>('idle');
    const [grouped, setGrouped] = useState<{
        [x: string]: FactureAchatLigneJsonldFactureAchatRead[];
    }>({});

    const [page, setPage] = useState<number>(1);
    const [itemPerPage] = useState<number>(5);

    const { enqueueSnackbar } = useSnackbar();
    const { modalActions } = useContext(ModalContext);
    const { defineContextActions, resetContextActions } = useContextActions();
    const { xIdSociete, api } = useSelector(customizerSelector);

    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const { request } = useApiRequest();
    const navigate = useNavigate();

    const getDossiers = useGetDossiersFromAchat();
    const getRejets = useGetRejets();
    const getLignes = useGetLignes();

    const { exportToXlsx } = useExport();
    const { handleApiResponse } = useToaster();
    const { getSwitchLockValues, stateSwitchLockValues } = useSwitchLockValues();
    const { updateTotals } = useUpdateTotals({ formContext, stateSwitchLockValues });

    const modeTotals = useMemo<ModeProps>(
        () => ({
            type: 'register',
            nature: 'simple',
            lock: stateSwitchLockValues.totals.value,
        }),
        [stateSwitchLockValues.totals.value],
    );

    const totalsSwitchesList = useMemo<Array<HeaderSwitch>>(() => {
        const component = [];

        if (!factureAchatSelected?.syncCegid) {
            component.push(
                getSwitchLockValues({
                    sectionToBlock: 'totals',
                    overwriteAction: () => {
                        if (stateSwitchLockValues.totals.value) {
                            updateTotals({
                                reasonToTriggerConfirmationModal:
                                    !stateSwitchLockValues.totals.value,
                            });
                        }
                    },
                }),
            );
        }

        return component;
    }, [
        factureAchatSelected?.syncCegid,
        getSwitchLockValues,
        stateSwitchLockValues.totals.value,
        updateTotals,
    ]);

    const { totalsSectionStructure } = useTotalsSectionStructure({
        mode: modeTotals,
        headerSwitches: totalsSwitchesList,
        isMulti: false,
    });

    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    function getDateString() {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        return `${year}${month}${day}`;
    }

    const exportFactureMulti = useCallback(
        (numeroFacture: string, idSociete?: number) => {
            const etats = '["todo", "except"]';

            const requestParameters: FactureAchatApiExportCodesRejetsRecapFactureAchatCollectionRequest =
                {
                    xIdSociete,
                    idSociete,
                    numeroFacture,
                    etats,
                };

            handleApiResponse(
                factureAchatApi.exportCodesRejetsRecapFactureAchatCollection(requestParameters),
                undefined,
                exportDataDashboard,
            ).then(
                (response) => {
                    const filename: string = `export-recap-${getDateString()}`;

                    const formattedData: (string | number | Date)[][] = response.data.map(
                        (item: any) => {
                            let formattedDateFacture;
                            let formattedDateSaisie;
                            let formattedLien;

                            if (item.dateFacture && item.dateFacture.date) {
                                formattedDateFacture = new Date(
                                    item.dateFacture.date,
                                ).toLocaleDateString('FR-fr');
                            }

                            if (item.dateSaisie && item.dateSaisie.date) {
                                formattedDateSaisie = new Date(
                                    item.dateSaisie.date,
                                ).toLocaleDateString('FR-fr');
                            }

                            if (item.lien) {
                                formattedLien = `${envBaseUrl(api.achats)}${item.lien}`;
                            }

                            return {
                                ...item,
                                dateFacture: formattedDateFacture || item.dateFacture,
                                dateSaisie: formattedDateSaisie || item.dateSaisie,
                                lien: formattedLien || item.lien,
                            };
                        },
                    );

                    exportToXlsx(formattedData, filename, filename);
                },
                (reason) => {
                    console.error(reason);
                },
            );
        },
        [api.achats, exportToXlsx, handleApiResponse, xIdSociete],
    );

    const synchroG3Facture = useCallback(
        async (factureAchat: FactureAchat) => {
            if (!factureAchat.id) {
                enqueueSnackbar(
                    <Typography>
                        Erreur technique la facture n&apos;a pas d&apos;identifiant
                    </Typography>,
                    { variant: 'warning' },
                );
                return;
            }
            try {
                const requestParameters: FactureAchatApiSyncG3FactureAchatFactureAchatItemRequest =
                    {
                        xIdSociete,
                        id: `${factureAchat.id}`,
                        factureAchatJsonldFactureAchatSyncG3: { syncG3: true, forced: true },
                    };

                const res =
                    await factureAchatApi.syncG3FactureAchatFactureAchatItem(requestParameters);

                if (res.status >= 200 && res.status < 300) {
                    enqueueSnackbar(<Typography>Synchronisation effectuée</Typography>, {
                        variant: 'success',
                    });
                } else {
                    throw new Error(`Erreur ${res.status}`);
                }
            } catch (error) {
                if (
                    error instanceof AxiosError &&
                    error.response &&
                    error.response.data.exception &&
                    error.response.data.exception.message
                ) {
                    enqueueSnackbar(
                        <Typography>{error.response.data.exception.message}</Typography>,
                        {
                            variant: 'error',
                        },
                    );
                } else if (error instanceof Error) {
                    enqueueSnackbar(<Typography>{error.message}</Typography>, {
                        variant: 'error',
                    });
                }
            }
        },
        [enqueueSnackbar, xIdSociete],
    );

    const exitFormAfterDelete = useCallback(async () => {
        let response: GenerateB2DPathResponseType = {
            status: 'STAY',
        };

        if (
            location.pathname.match(/\/achats\/liste\/modifier/) ||
            location.pathname.match(/\/achats\/liste\/recap/)
        )
            response = generateB2DPath('listfacture');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    }, [location.pathname, navigate]);

    const onDelete = useCallback(
        async (factureAchatToDelete: FactureAchat, isMediaObjectDelete?: boolean) => {
            if (!factureAchatToDelete.id) return;

            let mediaObjectId: string | null = null;

            if (factureAchatToDelete.pdfFacture && factureAchatToDelete.pdfFacture.id) {
                mediaObjectId = factureAchatToDelete.pdfFacture.id?.toString();
            }

            const successCallback = () => {
                // Pour l'instant ne s'applique qu'à la liste des factures en pause.
                // Plus tard, si on ajoute d'autres listes ce dispatch devra peut-être changer.

                exitFormAfterDelete();

                if (isMediaObjectDelete && mediaObjectId) {
                    const requestParameters: MediaObjectApiPatchMediaObjectItemRequest = {
                        id: mediaObjectId,
                        mediaObject: {
                            deleted: true,
                        },
                    };

                    request(mediaObjectApi.patchMediaObjectItem(requestParameters), {
                        withToaster: true,
                    });
                }
            };

            const requestParameters: FactureAchatApiDeleteFactureAchatFactureAchatItemRequest = {
                id: factureAchatToDelete.id.toString(),
                xIdSociete,
            };

            request(factureAchatApi.deleteFactureAchatFactureAchatItem(requestParameters), {
                withToaster: true,
                successCallback,
            });
        },
        [exitFormAfterDelete, request, xIdSociete],
    );

    const contextActions: ContextActionType[] = useMemo(
        () => [
            {
                name: 'Exporter les données',
                icon: (
                    <FontAwesomeIcon
                        icon={faDownload}
                        size="xl"
                    />
                ),
                action: () => {
                    if (factureAchatSelected.numeroFacture) {
                        exportFactureMulti(
                            factureAchatSelected.numeroFacture,
                            factureAchatSelected.idSociete || undefined,
                        );
                    }
                },
                resetHash: filtersHasher(factureAchatSelected),
            },
            ...(isInterne
                ? [
                      {
                          name: 'Syncro G3',
                          icon: (
                              <FaOptionIcon
                                  icon={faServer}
                                  option={faRotate}
                                  size="1x"
                                  color="blueGrey.main"
                                  optionColor="blueGrey.dark"
                                  iconProps={{
                                      radius: 100,
                                  }}
                              />
                          ),
                          action: () => {
                              synchroG3Facture(factureAchatSelected);
                          },
                      },
                      {
                          name: 'Supprimer la facture',
                          icon: (
                              <FontAwesomeIcon
                                  icon={faTrashCan}
                                  size="xl"
                              />
                          ),
                          action: () => {
                              modalActions.call(
                                  <ConfirmationModalContent
                                      key={`${factureAchatSelected?.['@id']}-${removeFactureModalMessages}`}
                                      messages={removeFactureModalMessages}
                                      actionOnValidation={() => {
                                          onDelete(factureAchatSelected, true);
                                      }}
                                      actionOnAlternative={() => {
                                          onDelete(factureAchatSelected, false);
                                      }}
                                  />,
                              );
                          },
                          resetHash: filtersHasher(factureAchatSelected),
                      },
                  ]
                : []),
        ],
        [
            exportFactureMulti,
            factureAchatSelected,
            isInterne,
            modalActions,
            onDelete,
            synchroG3Facture,
        ],
    );

    useEffect(() => {
        defineContextActions(contextActions);
    }, [contextActions, defineContextActions]);

    useEffect(() => () => resetContextActions(), [resetContextActions]);

    /**
     * Si le PDF a un idSociete,
     * alors le définir comme xIdSociete de l'utilisateur
     */
    useEffect(() => {
        if (factureAchatSelected?.idSociete) {
            dispatch(
                setXIdSociete(
                    factureAchatSelected?.idSociete as CustomizerReducerType['xIdSociete'],
                ),
            );
        }
    }, [dispatch, factureAchatSelected?.idSociete]);

    useEffect(() => {
        if (
            !factureAchatSelected ||
            !factureAchatSelected.id ||
            dossiersOfFactureStatus === 'loading' ||
            dossiersOfFactureStatus === 'succeeded'
        ) {
            return;
        }

        setDossiersOfFactureStatus('loading');
        getDossiers(factureAchatSelected.id).then((data) => {
            setDossiersOfFactureStatus('succeeded');
            setdossiersOfFacture(data);
        });
    }, [dossiersOfFactureStatus, factureAchatSelected, getDossiers]);

    useEffect(() => {
        if (
            dossiersofFacture.length > 0 &&
            factureAchatSelected &&
            factureAchatSelected.id &&
            lignesStatus !== 'loading' &&
            lignesStatus !== 'succeeded' &&
            rejetsStatus !== 'loading' &&
            rejetsStatus !== 'succeeded'
        ) {
            const ids = dossiersofFacture
                .map((item: FactureAchatFactureAchatReadDossierDossiersInner) => item.idDossier)
                .filter((id: number | undefined) => id !== undefined)
                .slice(itemPerPage * (page - 1), itemPerPage * page);

            setLignesStatus('loading');
            getLignes(factureAchatSelected.id, ids as number[]).then((data) => {
                setLignesStatus('succeeded');
                setLignes(data);
            });

            setRejetsStatus('loading');
            getRejets(factureAchatSelected.id, ids as number[]).then((data) => {
                setRejetsStatus('succeeded');
                setRejets(data);
            });
            // setFacture(apiInvoicePurchaseToFormInvoicePurchase(factureAchatSelected));
        }
    }, [
        dossiersofFacture,
        factureAchatSelected,
        getLignes,
        getRejets,
        itemPerPage,
        lignesStatus,
        page,
        rejetsStatus,
    ]);

    if (!factureachatid) {
        console.error('FactureAchatId is undefined !');
    }

    useEffect(() => {
        if (factureachatid) {
            dispatch(selectFactureAchat({ id: +factureachatid }));
        }
    }, [dispatch, factureachatid]);

    /**
     * Select MediaObject corresponding url param mediaobjectid.
     *
     * Should select MediaObject on mount.
     */
    useEffect(() => {
        if (mediaobjectid) {
            dispatch(selectMediaObject({ id: +mediaobjectid }));
        } else {
            enqueueSnackbar(
                `Le PDF n'est peut-être pas le bon, car son identifiant n'a pas été trouvé dans l'url. ${refreshPage} ${contactSupport}`,
                {
                    variant: 'warning',
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!factureAchatSelected || lignes.length === 0) return;

        setGrouped(groupLinesByDossiers(lignes));

        if (factureAchatSelected.idEntiteFacturante && !fournisseurSelected) {
            dispatch(selectFournisseur({ id: +factureAchatSelected.idEntiteFacturante }));
        }
    }, [dispatch, factureAchatSelected, fournisseurSelected, lignes]);

    const changePage = useCallback((newPage: number) => {
        setLignes([]);
        setRejets([]);
        setLignesStatus('idle');
        setRejetsStatus('idle');
        setPage(newPage);
    }, []);

    const factureFormStructure: FormStructure[] = useMemo(
        () => [...totalsSectionStructure],
        [totalsSectionStructure],
    );

    useEffect(() => {
        if (!factureAchatSelected) return;

        const totaux = getTotaux(factureAchatSelected);
        if (factureAchatSelected.avoir === true) {
            formContext.setValue('total_ht', convertToNumberAndFormatToString(-totaux.ht));
            formContext.setValue('total_tva', convertToNumberAndFormatToString(-totaux.tva));
            formContext.setValue('total_ttc', convertToNumberAndFormatToString(-totaux.ttc));
        } else {
            formContext.setValue('total_ht', convertToNumberAndFormatToString(totaux.ht));
            formContext.setValue('total_tva', convertToNumberAndFormatToString(totaux.tva));
            formContext.setValue('total_ttc', convertToNumberAndFormatToString(totaux.ttc));
        }
    }, [factureAchatSelected, formContext]);

    useEffect(
        () => () => {
            dispatch({
                type: `${FOURNISSEUR_SLICE_NAME}/deletemainSelected`,
            });
        },
        [dispatch],
    );

    const handleClickButtonToStatement = useCallback(() => {
        if (factureAchatSelected && factureAchatSelected.idReleveAchat) {
            navigateToRecapStatement(navigate, factureAchatSelected.idReleveAchat);
        }
    }, [factureAchatSelected, navigate]);

    const syncG3 = useSyncG3({
        invoice: factureAchatSelected,
        forced: false,
    });

    return !mediaObjectSelected || !factureAchatSelected || !fournisseurSelected ? (
        <CenterCircularProgress sx={{ height: '500px' }} />
    ) : (
        <Grid container>
            <Grid
                item
                lg={6}
                sm={12}
            >
                <Card
                    id="cardPdfReader"
                    sx={{
                        height: `calc(100vh - ${footerHeight} - ${TopbarHeight})`,
                    }}
                >
                    <PdfViewer
                        title={mediaObjectSelected?.originalName}
                        pdfUrl={mediaObjectSelected?.contentUrl ?? undefined}
                    />
                </Card>
            </Grid>
            <Grid
                item
                lg={6}
                sm={12}
            >
                <Card>
                    <EnteteFacture
                        invoice={factureAchatSelected}
                        publisher={fournisseurSelected}
                        headerControllers={
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    gap: '10px',
                                    flex: 1,
                                }}
                            >
                                {factureAchatSelected.idReleveAchat ? (
                                    <HeaderButton
                                        content="Récapitulatif de Relevé"
                                        onClick={handleClickButtonToStatement}
                                    />
                                ) : null}
                                <FlexyIconButton
                                    displayTooltip
                                    icon={syncIcon}
                                    onClick={syncG3.action}
                                    tooltipOverwriteProps={{
                                        title: `${syncIcon.displayName} avec G3`,
                                    }}
                                    iconOverwriteProps={{
                                        color: 'primary',
                                    }}
                                />
                            </Stack>
                        }
                    />
                    {dossiersOfFactureStatus === 'loading' ||
                    rejetsStatus === 'loading' ||
                    lignesStatus === 'loading' ? (
                        <CenterCircularProgress sx={{ height: '500px' }} />
                    ) : null}

                    <FlexyTabs
                        scrollbar={false}
                        tabs={[
                            {
                                tabName: 'dossiers',
                                tabTitle: 'Dossiers',
                                tabPanel: (
                                    <>
                                        {Object.keys(grouped).map((libelle) => (
                                            <DossierLinesAccordeon
                                                key={libelle}
                                                mediaObject={mediaObjectSelected}
                                                facture={factureAchatSelected}
                                                lines={grouped[libelle]}
                                                rejets={rejets}
                                                displayName={libelle}
                                            />
                                        ))}

                                        <Stack
                                            alignItems="center"
                                            sx={{ marginTop: '15px' }}
                                        >
                                            <Pagination
                                                page={page}
                                                count={Math.ceil(
                                                    dossiersofFacture.length / itemPerPage,
                                                )}
                                                onChange={(
                                                    event: React.ChangeEvent<unknown>,
                                                    newPage: number,
                                                ) => {
                                                    changePage(newPage);
                                                }}
                                            />
                                        </Stack>
                                    </>
                                ),
                            },
                            {
                                tabName: 'notes-generales',
                                tabTitle: 'Notes générales',
                                tabPanel: (
                                    <>
                                        <NotesMediaObject title="Notes de traitement du PDF" />
                                        <NotesFacture
                                            title="Notes de la facture"
                                            facture={factureAchatSelected}
                                            displayNoteAllDossiers={false}
                                        />
                                    </>
                                ),
                            },
                        ]}
                    />

                    <FlexyForm
                        formObject={{}}
                        formContext={formContext}
                        formStructure={factureFormStructure}
                        onSubmit={() => {}}
                        submitButton={{
                            displayed: false,
                        }}
                    />
                </Card>
            </Grid>
        </Grid>
    );
};

export default UpdateMultiInvoiceView;
