import * as React from 'react';

import { Box, SxProps } from '@mui/system';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlaceIcon from '@mui/icons-material/Place';

export type ActionsBarProps = {
    deleteAction?: React.MouseEventHandler<SVGSVGElement>;
    editAction?: React.MouseEventHandler<SVGSVGElement>;
    localisationAction?: React.MouseEventHandler<SVGSVGElement>;
    color?:
        | 'secondary'
        | 'disabled'
        | 'action'
        | 'inherit'
        | 'primary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning'
        | undefined;
    sx?: SxProps;
};

const ActionsBar: React.FunctionComponent<ActionsBarProps> = function ({
    deleteAction,
    editAction,
    localisationAction,
    color = 'secondary',
    sx,
}) {
    const iconSize = '40px';
    const actionStyle = {
        cursor: 'pointer',
        border: '1px solid lightgray',
        borderRadius: '25px',
        width: iconSize,
        height: iconSize,
        padding: '5px',
    };
    return (
        <Box
            role="listbox"
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                ...sx,
            }}
        >
            {editAction ? (
                <DriveFileRenameOutlineIcon
                    color={color}
                    onClick={editAction}
                    sx={actionStyle}
                />
            ) : null}
            {deleteAction ? (
                <DeleteOutlineIcon
                    color={color}
                    onClick={deleteAction}
                    sx={actionStyle}
                />
            ) : null}
            {localisationAction ? (
                <PlaceIcon
                    color={color}
                    onClick={localisationAction}
                    sx={actionStyle}
                />
            ) : null}
        </Box>
    );
};

export default ActionsBar;
