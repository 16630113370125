import { DataCard } from '@europrocurement/flexy-components';
import { Box, Grid, Palette, Typography, useTheme } from '@mui/material';
import React from 'react';

const PanelColors = function () {
    const theme = useTheme();

    const getPaletteLength = (color: keyof Palette) => {
        const defaultLength = Object.keys(theme.palette[color] as keyof Palette).filter(
            (variant) => variant !== 'contrastText',
        ).length;
        if (defaultLength > 2) {
            return 3;
        }
        return defaultLength;
    };

    return (
        <Grid container>
            {Object.keys(theme.palette).map((color) => {
                if (
                    [
                        'mode',
                        'contrastThreshold',
                        'getContrastText',
                        'augmentColor',
                        'augmentColor',
                        'divider',
                        'tonalOffset',
                        'action',
                        'ERDO',
                        'EREN',
                        'ERJA',
                        'ERJU',
                        'ERLO',
                        'ERPF',
                        'ERPU',
                        'ERRC',
                        'ERRE',
                        'ERSO',
                        'ERNF',
                        'ERDN',
                        'ERCA',
                    ].indexOf(color) !== -1
                ) {
                    return null;
                }
                return (
                    <Grid
                        item
                        key={color}
                        md={3}
                    >
                        <DataCard
                            title={color.charAt(0).toUpperCase() + color.slice(1)}
                            color={color}
                        >
                            <Grid
                                container
                                columns={getPaletteLength(color as keyof Palette)}
                                columnSpacing={1}
                                rowSpacing={1}
                            >
                                {Object.keys(
                                    theme.palette[color as keyof Palette] as keyof Palette,
                                ).map((variant) => {
                                    if (variant === 'contrastText') {
                                        return null;
                                    }
                                    if (
                                        [
                                            'lightGreen',
                                            'red',
                                            'pink',
                                            'purple',
                                            'deepPurple',
                                            'amber',
                                            'blueGrey',
                                            'teal',
                                            'deepOrange',
                                            'brown',
                                            'cyan',
                                            'green',
                                            'indigo',
                                            'lightBlue',
                                            'lime',
                                            'orange',
                                            'yellow',
                                            'blue',
                                        ].indexOf(color) !== -1 &&
                                        ['main', 'light', 'dark'].indexOf(variant) === -1
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <Grid
                                            item
                                            key={variant}
                                            lg={1}
                                        >
                                            <Box
                                                sx={{
                                                    borderRadius: '5px',
                                                    backgroundColor: `${color}.${variant}`,
                                                }}
                                            >
                                                <Typography>{variant}</Typography>
                                            </Box>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </DataCard>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default PanelColors;
