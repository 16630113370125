import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

import { FactureAchat, MediaObject } from '@europrocurement/l2d-domain';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';

import generateB2DPath from '../../utils/generateB2DPath';

/**
 * Envoi vers la liste des MediaObjects
 *
 * @returns () => void
 */
export const useNavigateToMediaObjectsList = () => {
    const navigate = useNavigate();
    const roleChecker = UseKeycloakCheckRole();
    const isInterne = roleChecker('realm:interne');

    return async () => {
        const response = isInterne
            ? generateB2DPath('listmediaobjectinternally')
            : generateB2DPath('listmediaobject');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

/**
 * Envoi vers la liste des Factures d'achat
 *
 * @returns () => void
 */
export const useNavigateToFactureAchatsList = () => {
    const navigate = useNavigate();

    return () => {
        const response = generateB2DPath('listfacture');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

/**
 * Envoi vers la liste des relevés
 *
 * @returns () => void
 */
export const useNavigateToStatementList = () => {
    const navigate = useNavigate();

    return () => {
        const response = generateB2DPath('listInvoiceStatement');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

/**
 * Envoi vers la liste des Factures d'achat en pause
 *
 * @returns () => void
 */
export const useNavigateToPausedFactureAchatsList = () => {
    const navigate = useNavigate();

    return () => {
        const response = generateB2DPath('listfacturepause');

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

export const useNavigateToStatementRecap = () => {
    const navigate = useNavigate();

    return (factureAchat: FactureAchat) => {
        const response = generateB2DPath('statementRecap', {
            statementId: factureAchat.idReleveAchat ? factureAchat.idReleveAchat : undefined,
        });

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

export const useNavigateToFactureAchatRecap = () => {
    const navigate = useNavigate();

    return (factureAchat: FactureAchat, mediaObject: MediaObject) => {
        const response = generateB2DPath('formmultirecap', {
            factureAchat,
            mediaObjectId: mediaObject.id,
        });

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

export const useNavigateToFactureAchatRecapFormalite = () => {
    const navigate = useNavigate();

    return (factureAchat: FactureAchat, mediaObject: MediaObject) => {
        const response = generateB2DPath('formmultirecapfpro', {
            factureAchat,
            mediaObjectId: mediaObject.id,
        });

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

export const useNavigateToFactureAchatNextDossier = () => {
    const navigate = useNavigate();

    return (factureAchat: FactureAchat, mediaObject: MediaObject) => {
        const response = generateB2DPath('formmultinextdossier', {
            factureAchat,
            mediaObjectId: mediaObject.id,
        });

        if (response.status === 'OK') {
            navigate(response.path);
        }
    };
};

/**
 * Envoi vers la saisie d'un prochain dossier (multi-annonces)
 *
 * @returns () => void
 */
export const useNavigateToDernierDossier = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    return (factureAchat: FactureAchat) => {
        const response = generateB2DPath('formmultiadddossier', {
            factureAchat,
        });

        if (response.status === 'OK') {
            navigate(response.path);
        } else if (response.status === 'KO') {
            enqueueSnackbar(response.message, {
                variant: 'error',
            });
        }
    };
};

/**
 * Envoi vers la liste des MediaObjects et
 * annule les modifications apportées au MediaObject
 *
 * @returns () => Promise<void>
 */
export const useBackFromMediaObjectEdit = () => {
    const navigateToListe = useNavigateToMediaObjectsList();

    return async () => {
        navigateToListe();
    };
};
