import { Dispatch } from 'react';

import { NavigateFunction } from 'react-router';
import {
    CustomizerReducerType,
    MediaObject,
    MediaObjectApiObject,
    Route,
} from '@europrocurement/l2d-domain';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { getMediaObject } from '@b2d/redux/RootStore';

import { AnyAction, CombinedState, ThunkDispatch } from '@reduxjs/toolkit';

import { jsonLdIdScraper } from '@europrocurement/l2d-utils';
import generateB2DPath from './generateB2DPath';

/**
 * Navigation vers multi-annonces
 *
 * Permet d'accéder au formulaire de saisie multi-annonces en nettoyant à la fois le state et les DataSources.
 *
 * @param multiAnnonceNavigation
 * @param onError
 */
export const navigateToMultiAnnonces = (
    showMessage: (message: string, variant: 'info' | 'warning' | 'error') => void,
    navigate: NavigateFunction,
    idMediaObject?: number,
    idStatement?: number,
) => {
    const response = generateB2DPath(
        idStatement ? 'formmultimediaobjectstatement' : 'formmultimediaobject',
        {
            mediaObjectId: idMediaObject,
            statementId: idStatement,
        },
    );

    if (response.status === 'OK') {
        navigate(response.path);
    } else if (response.status === 'KO') {
        showMessage(response.message, 'warning');
    }
};

const findNextMediaObject = (mediaObjectList: MediaObjectApiObject[], idSelected?: string) => {
    if (!idSelected || Number.isNaN(Number(idSelected))) {
        return mediaObjectList[0];
    }

    const idSelectedConverted: number = Number(idSelected);

    const currentIndex = mediaObjectList.findIndex(
        (mediaObjectItem) => mediaObjectItem.id === idSelectedConverted,
    );
    const nextIndex = currentIndex + 1;

    // If the current item is the last one or not found, return the first item
    return nextIndex >= mediaObjectList.length || currentIndex === -1
        ? mediaObjectList[0]
        : mediaObjectList[nextIndex];
};

const nextUnregisteredPurchaseNeedNewMediaObject = (
    showMessage: (message: string, variant: 'info' | 'warning' | 'error') => void,
    dispatch: ThunkDispatch<
        CombinedState<{
            routing: CombinedState<{
                routing: Route[];
            }>;
            customizer: CustomizerReducerType;
        }>,
        undefined,
        AnyAction
    > &
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Dispatch<any>,
    navigate: NavigateFunction,
    navigateToList: () => void,
    idCurrentMediaObject?: string,
) => {
    setTimeout(async () => {
        const mediaObjects: { payload: { 'hydra:member': MediaObject[] } } = (await dispatch(
            getMediaObject({}),
        )) as unknown as { payload: { 'hydra:member': MediaObject[] } };

        const mediaObjectsContent = mediaObjects.payload['hydra:member'];

        const noItems = mediaObjectsContent.length === 0;
        const remainingItemIsSelectedOne =
            mediaObjectsContent.length === 1 &&
            mediaObjectsContent[0]['@id'] === idCurrentMediaObject;

        if (noItems || remainingItemIsSelectedOne) {
            navigateToList();
            showMessage('Toutes les factures sont traitées ou en traitement', 'info');
        } else {
            let response = null;

            const mediaObjectToUse = findNextMediaObject(mediaObjectsContent, idCurrentMediaObject);

            response = generateB2DPath('formmediaobject', {
                mediaObjectId: mediaObjectToUse.id,
            });
            if (response.status === 'OK') {
                dispatch({
                    type: ACTIONS.RESET,
                });
                navigate(response.path);
            } else if (response.status === 'KO') {
                showMessage(response.message, 'warning');
            }
        }
    }, 500);
};

const nextUnregisteredPurchaseNeedSameMediaObject = (
    showMessage: (message: string, variant: 'info' | 'warning' | 'error') => void,
    dispatch: ThunkDispatch<
        CombinedState<{
            routing: CombinedState<{
                routing: Route[];
            }>;
            customizer: CustomizerReducerType;
        }>,
        undefined,
        AnyAction
    > &
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Dispatch<any>,
    navigate: NavigateFunction,
    idCurrentMediaObject: string,
    idStatement: number,
) => {
    const response = generateB2DPath('statementRegister', {
        mediaObjectId: jsonLdIdScraper(idCurrentMediaObject),
        statementId: idStatement,
    });

    if (response.status === 'OK') {
        dispatch({
            type: ACTIONS.FULL_RESET,
        });
        navigate(response.path);
    } else if (response.status === 'KO') {
        showMessage(response.message, 'warning');
    }
};

export const navigateToNewFacture = async (
    showMessage: (message: string, variant: 'info' | 'warning' | 'error') => void,
    dispatch: ThunkDispatch<
        CombinedState<{
            routing: CombinedState<{
                routing: Route[];
            }>;
            customizer: CustomizerReducerType;
        }>,
        undefined,
        AnyAction
    > &
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Dispatch<any>,
    navigate: NavigateFunction,
    navigateToList: () => void,
    idCurrentMediaObject?: string,
    idStatement?: number,
) => {
    if (idCurrentMediaObject && idStatement) {
        nextUnregisteredPurchaseNeedSameMediaObject(
            showMessage,
            dispatch,
            navigate,
            idCurrentMediaObject,
            idStatement,
        );
    } else {
        nextUnregisteredPurchaseNeedNewMediaObject(
            showMessage,
            dispatch,
            navigate,
            navigateToList,
            idCurrentMediaObject,
        );
    }
};

export const navigateToRecapStatement = async (navigate: NavigateFunction, idStatement: number) => {
    const response = generateB2DPath('statementRecap', {
        statementId: idStatement,
    });

    if (response.status === 'OK') {
        navigate(response.path);
    }
};
