import React, { useCallback, useEffect, useState } from 'react';

import {
    SubmitHandler,
    useForm,
    SubmitErrorHandler,
    FieldErrors,
    FormState,
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { Box, Button } from '@mui/material';

import {
    Fournisseur,
    Dossier,
    RubriqueFacturationApiObject,
    Commande,
    DOSSIERS_SLICE_NAME,
    DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME,
    MediaObject,
    TauxTvaApiObject,
    FactureAchatNote,
    DossierFormalite,
    DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME,
    FOURNISSEUR_SLICE_NAME,
    FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME,
} from '@europrocurement/l2d-domain';
import { FactureVente } from '@europrocurement/l2d-domain/reducers/dossiers/slices/factureVenteSlice';
import { TypeRemiseFournisseurRead } from '@europrocurement/l2d-domain/openApi/ApiTiers';
import { FlexyForm, FlexyFormSubmitButtonRender, FormObject } from '@europrocurement/flexy-form';
import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { useModal } from '@europrocurement/flexy-components';

import {
    selectFactureVente,
    selectFournisseurAc,
    FactureFormSelector,
    RootStateType,
    AppDispatch,
    customizerSelector,
} from '@b2d/redux/RootStore';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';

import {
    ajouterLigneRSF,
    defaultProduits,
    replaceProduits,
    removeEmptyProduits,
    useSyncAchatProduitsWithVente,
    useUpdateFormWithVentesAndEvents,
} from '@b2d/pages/Achats/components/forms/functions/produitFunctions';
import { updateFormWithFournisseur } from '@b2d/pages/Achats/components/forms/functions/fournisseurFunctions';
import {
    resetDossiersFields,
    useUpdateFormWithDossier,
    useUpdateFormWithDossierFormalite,
} from '@b2d/pages/Achats/components/forms/functions/dossierFonctions';
import { useResetFactureAchatState } from '@b2d/pages/Achats/components/forms/functions/generalFunctions';
import { formalitesVentesToProduitsAchats } from '@b2d/pages/Achats/components/forms/functions/venteFunctions';
import {
    FactureAchatCreate,
    FactureFormObject,
    filterOptionsProps,
} from '@b2d/pages/Achats/components/forms/types';
import { navigateToNewFacture } from '@b2d/utils/navigationHelper';
import _ from 'lodash';
import useAddCustomContextActions from '@b2d/pages/Achats/hooks/contextActions/useAddCustomContextActions';
import useUpdateDiscountRate from '@b2d/pages/Achats/hooks/useUpdateDiscountRate';
import useUpdateTotals from '@b2d/pages/Achats/hooks/useUpdateTotals';
import useSwitchLockValues from '@b2d/pages/Achats/hooks/useSwitchLockValues';
import useShowMessage from '@b2d/hooks/useShowMessage';
import useDomain from '@b2d/hooks/useDomain';
import {
    confirmationSaisieFactureModalMessages,
    removeProductsModalMessages,
    saisieDossierCommandeModalMessages,
    saisieDossierFormaliteModalMessages,
} from '../../../constants/wording/modals';

import { useNavigateToMediaObjectsList } from '../../../achatRouterHooks';
import ConfirmationModalContent from '../../modals/ConfirmationModalContent';
import type { Nullable } from '../../../constants/types';
import useTreatmentRefusal from '../../../hooks/contextActions/useTreatmentRefusal';
import useUpdateSociete from '../../../hooks/contextActions/useUpdateSociete';
import useDeleteMediaObject from '../../../hooks/contextActions/useDeleteMediaObject';
import useHandleSections from '../formElements/hooks/useHandleSections';
import formInvoicePurchaseToApiInvoicePurchase from '../functions/dataTransformers/formToApi/formInvoicePurchaseToApiInvoicePurchase';
import { convertToNumberAndFormatToString } from '../functions/calculsProduits';

export type RegisterSimpleInvoiceFormProps = {
    mediaObject: MediaObject;
    facture: FactureFormObject | Partial<FactureFormObject>;
    onSubmit: (data: FactureAchatCreate, goToNext?: boolean, id_statement?: number) => void;
    onCancel: () => void;
};

const RegisterSimpleInvoiceForm: React.FunctionComponent<RegisterSimpleInvoiceFormProps> =
    function ({ mediaObject, facture, onSubmit, onCancel }) {
        const roleChecker = UseKeycloakCheckRole();
        const isInterne = roleChecker('realm:interne');

        const [validSubmit, setValidSubmit] = useState<boolean>(false);
        const [stateNotes, setStateNotes] = useState<FactureAchatNote[]>([]);
        const [lastVenteUpdate, setLastVenteUpdate] = useState<Nullable<number>>(null);

        const { mediaobjectid } = useParams();

        /** ********** */
        /* DataSources */
        /** ********** */
        const fournisseursDataSource: DataSource<Fournisseur> = useSelector(
            (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures,
            _.isEqual,
        );

        const rubFactsDataSource: DataSource<RubriqueFacturationApiObject> = useSelector(
            (s: RootStateType) => s.dossiers.rubfac.main,
            _.isEqual,
        );

        const tauxTvaDataSource: DataSource<TauxTvaApiObject> = useSelector(
            (s: RootStateType) => s.dossiers.txtva.main,
            _.isEqual,
        );

        const ventesDataSource: DataSource<FactureVente> = useSelector(
            (s: RootStateType) => s.dossiers.facturevente.main,
            _.isEqual,
        );

        /** ********** */
        /* DataSources */
        /** ********** */

        /** ************* */
        /* Selected items */
        /** ************* */

        const commandeSelected: Commande | undefined = useSelector(
            (s: RootStateType) => s.dossiers.commande.main.selected,
            _.isEqual,
        );

        const venteSelected: FactureVente | undefined = useSelector(
            (s: RootStateType) => s.dossiers.facturevente.main.selected,
            _.isEqual,
        );

        const dossierSelected: Dossier | undefined = useSelector(
            (s: RootStateType) => s.dossiers.dos[DOSSIER_AUTOCOMPLETE_DATASOURCE_NAME].selected,
            _.isEqual,
        );

        const dossierFormaliteSelected: DossierFormalite | undefined = useSelector(
            (s: RootStateType) =>
                s.formalites.dosform[DOSSIERS_FORMALITES_AUTOCOMPLETE_DATASOURCE_NAME].selected,
            _.isEqual,
        );

        const fournisseurSelected: Fournisseur | undefined = useSelector(
            (s: RootStateType) => s.tiers.fournisseur.autocompleteFactures.selected,
            _.isEqual,
        );

        // Reducer instanciation (replace useStates)
        const dispatch = useDispatch<AppDispatch>();
        const state = useSelector(FactureFormSelector);

        // Initialisation du formulaire
        const formContext = useForm({
            mode: 'onTouched',
            defaultValues: facture,
        });

        const { enqueueSnackbar } = useSnackbar();
        const navigate = useNavigate();

        const { modalActions } = useModal();

        const switchLockValues = useSwitchLockValues();
        const { stateSwitchLockValues } = switchLockValues;
        const lockTotalsSectionValue = stateSwitchLockValues.totals.value;

        const { updateTotals } = useUpdateTotals({ formContext, stateSwitchLockValues });
        const updateDiscountRate = useUpdateDiscountRate({
            formContext,
            stateSwitchLockValues: switchLockValues.stateSwitchLockValues,
            fournisseur: fournisseurSelected,
            overwriteAfterUpdate: () => {
                updateTotals({
                    reasonToTriggerConfirmationModal: !lockTotalsSectionValue,
                });
            },
        });
        const { stateDiscountRate } = updateDiscountRate;

        const { xIdSociete } = useSelector(customizerSelector);

        const updateFormWithDossier = useUpdateFormWithDossier();
        const updateFormWithDossierFormalite = useUpdateFormWithDossierFormalite();
        const syncAchatProduitsWithVente = useSyncAchatProduitsWithVente();
        const updateFormWithVentesAndEvents = useUpdateFormWithVentesAndEvents();
        const showMessage = useShowMessage();
        const resetStates = useResetFactureAchatState();
        const navigateToListe = useNavigateToMediaObjectsList();
        useDomain();

        const updateNotes = useCallback(
            (notes: FactureAchatNote[]) => setStateNotes([...notes]),
            [],
        );

        const handleSections = useHandleSections({
            formContext,
            state,
            stateNotes: {
                get: stateNotes,
                set: updateNotes,
            },
        });

        const stateHeaderSectionStructure = handleSections.states.header;

        useEffect(() => {
            if (!isInterne || !formContext.getValues('facture_formalite')) {
                if (fournisseursDataSource.filters.ddmId) {
                    dispatch({
                        type: `${FOURNISSEUR_SLICE_NAME}/delete${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Filter`,
                        payload: {
                            field: 'ddmId',
                        },
                    });
                }
            } else if (
                !fournisseursDataSource.filters.ddmId ||
                fournisseursDataSource.filters.ddmId !== (xIdSociete === 12 ? state.defDomain : 0)
            ) {
                dispatch({
                    type: `${FOURNISSEUR_SLICE_NAME}/set${FOURNISSEUR_AUTOCOMPLETE_FACTURE_DATASOURCE_NAME}Filter`,
                    payload: {
                        key: 'ddmId',
                        value: xIdSociete === 12 ? state.defDomain : 0,
                    },
                });
            }
        }, [
            dispatch,
            formContext,
            fournisseursDataSource.filters.ddmId,
            isInterne,
            state.defDomain,
            xIdSociete,
        ]);

        /**
         * DEB - useEffects
         */

        /**
         * Actions à l'ouverture de la page de saisie de facture simple
         */
        useEffect(() => {
            const filterOptions: filterOptionsProps = {
                sliceName: DOSSIERS_SLICE_NAME,
                dataSourceName: 'main',
            };

            dispatch({
                type: ACTIONS.RESET_DATASOURCE_FILTERS,
                payload: filterOptions,
            });
        }, [dispatch]);

        /**
         * Infos facture de vente par N° de dossier / d'annonce
         *
         * Si le N° de dossier / d'annonce change et que l'utilisateur est interne :
         * Enregistrer le fournisseur correspondant.
         *
         * @see dossierSelected?.facture
         * @see dispatch
         * @see isInterne
         */
        useEffect(() => {
            updateFormWithVentesAndEvents(
                formContext,
                dossierSelected || null,
                dossierFormaliteSelected || null,
                fournisseurSelected || null,
                venteSelected || null,
                ventesDataSource.selectedStatus,
            );
        }, [
            formContext,
            updateFormWithVentesAndEvents,
            dossierSelected,
            dossierFormaliteSelected,
            fournisseurSelected,
            venteSelected,
            ventesDataSource.selectedStatus,
        ]);

        /**
         * S'il n'y plus de dossier ou de fournisseur sélectionné,
         * alors on remplace le tableau de produits par la valeur par défaut.
         */
        useEffect(() => {
            if (!dossierSelected && !fournisseurSelected) {
                replaceProduits(formContext, defaultProduits(state.defDomain, false));
            }
        }, [dossierSelected, formContext, fournisseurSelected, state.defDomain]);

        /**
         * Infos fournisseur par N° de dossier / d'annonce
         *
         * Si le N° de dossier / d'annonce change et que l'utilisateur est interne :
         * Enregistrer le fournisseur correspondant.
         *
         * @see dossierSelected?.prestations
         * @see dispatch
         * @see isInterne
         */
        useEffect(() => {
            if (
                dossierSelected &&
                dossierSelected.prestations &&
                dossierSelected.prestations[0] &&
                !dossierSelected.prestations[0].idSocieteFacture
            ) {
                enqueueSnackbar(
                    "Cette parution n'est liée à aucunes société, il est impossible d'enregistrer la facture.",
                    {
                        autoHideDuration: 10000,
                        variant: 'error',
                    },
                );
            }

            const presta =
                dossierSelected?.prestations &&
                dossierSelected?.prestations.length > 0 &&
                dossierSelected?.prestations[0];

            if (presta) {
                dispatch({ type: ACTIONS.SET_NUM_PRESTA, payload: presta.numero });
                formContext.trigger('numero_annonce');
            }
            const idFacturante = presta ? presta.idEntiteFacturante : null;
            if (idFacturante && !stateHeaderSectionStructure.supplierFromStatement.value) {
                dispatch(selectFournisseurAc({ id: idFacturante }));
            }
        }, [
            dossierSelected?.prestations,
            dispatch,
            formContext,
            dossierSelected,
            enqueueSnackbar,
            stateHeaderSectionStructure.supplierFromStatement.value,
        ]);

        /**
         * Infos dossier par N° de dossier / d'annonce
         *
         * Si un dossier est sélectionné, met à jour les informations.
         */
        useEffect(() => {
            if (dossierFormaliteSelected) return;

            if (
                dossierSelected &&
                dossierSelected.numero &&
                `${dossierSelected.numero}` !== state.defaultNumeroDossier
            ) {
                updateFormWithDossier(formContext, dossierSelected);
            } else if (!dossierSelected) {
                resetDossiersFields(formContext);
                if (formContext.getFieldState('dossier_formalite').isTouched) {
                    resetStates(formContext);
                }
            }
        }, [
            dossierFormaliteSelected,
            dossierSelected,
            formContext,
            resetStates,
            state.defaultNumeroDossier,
            updateFormWithDossier,
        ]);

        /**
         * Infos dossier par N° de dossier de formalité
         *
         * Si un dossier de formalité est sélectionné, met à jour les informations.
         */
        useEffect(() => {
            if (dossierSelected) return;

            if (
                dossierFormaliteSelected &&
                dossierFormaliteSelected.numero &&
                dossierFormaliteSelected.numero !== state.defaultNumeroDossierFormalite
            ) {
                updateFormWithDossierFormalite(formContext, dossierFormaliteSelected);
            } else if (!dossierFormaliteSelected) {
                if (formContext.getFieldState('dossier').isTouched) {
                    resetStates(formContext);
                }
            }
        }, [
            dossierSelected,
            dossierFormaliteSelected,
            formContext,
            resetStates,
            state.defaultNumeroDossierFormalite,
            updateFormWithDossierFormalite,
        ]);

        /**
         * Ajout des RubFacts généraux
         *
         * @see dispatch
         * @see rubFactsDataSource.data
         */
        useEffect(() => {
            const rubFactsToAdd: RubriqueFacturationApiObject[] = rubFactsDataSource.data;

            dispatch({ type: ACTIONS.ADD_RUB_FACTS, payload: rubFactsToAdd });
        }, [dispatch, rubFactsDataSource.data]);

        /**
         * Filtre sur les rubriques de facturation
         *
         * Conserver les rubriques de facturation liées au domaine (i.e : Publication, Adjudication, ...)
         *
         * @see dispatch
         * @see rubFactsDataSource.data
         * @see state.defDomain
         */
        useEffect(() => {
            dispatch({ type: ACTIONS.SET_RUB_FACT, payload: rubFactsDataSource.data });

            if (state.defDomain !== 0) {
                dispatch({
                    type: ACTIONS.FILTER_RUB_FACTS,
                    payload: {
                        domaine: state.defDomain,
                        rubfacts: rubFactsDataSource.data,
                    },
                });
            }
        }, [dispatch, rubFactsDataSource.data, state.defDomain]);

        /**
         * Alimente la liste des taux de TVA dans le state
         *
         * @see tauxTvaDataSource.data
         * @see state.txTva
         */
        useEffect(() => {
            if (state.txTva.length < 1) {
                dispatch({ type: ACTIONS.SET_TAUX_TVA, payload: tauxTvaDataSource.data });
            }
        }, [dispatch, state.txTva.length, tauxTvaDataSource.data]);

        /**
         * Select la facture de vente de la commande,
         * si le dossier n'a pas lui-même de facture de vente.
         *
         * On cherche dans la commande le dossier qui correspond au dossier sélectionné
         * pour s'assurer que la facture soit la bonne.
         *
         * E.g. :
         *     - Dossier sans facture : 1272353
         *     - Dossier avec facture : 802308
         */
        useEffect(() => {
            const commandWithVenteAndDossierWithout: boolean = !!(
                commandeSelected?.idFacture &&
                dossierSelected &&
                dossierSelected.id &&
                !dossierSelected.facture
            );

            if (commandWithVenteAndDossierWithout) {
                const matchedDossier = commandeSelected?.dossiers?.find((dossier) => {
                    if (!dossierSelected?.id) return false;
                    return dossier.includes(dossierSelected?.id.toString());
                });

                const newIdVenteFromCommandeAndDifferentThanVenteSelected: boolean = !!(
                    venteSelected &&
                    commandeSelected &&
                    commandeSelected?.idFacture !== venteSelected.id
                );

                if (
                    matchedDossier &&
                    ventesDataSource.selectedStatus !== 'loading' &&
                    (!venteSelected || newIdVenteFromCommandeAndDifferentThanVenteSelected)
                ) {
                    dispatch(selectFactureVente({ id: commandeSelected?.idFacture as number }));
                }
            }
        }, [
            commandeSelected,
            dossierSelected,
            ventesDataSource.selectedStatus,
            dispatch,
            venteSelected,
        ]);

        /**
         * Ajout des lignes d'achat correspondant à la vente pour un dossier classique
         *
         * RG : https://legal2digital.atlassian.net/browse/PA-54
         *
         * Si une facture de vente est trouvée :
         * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
         * la propriété 'affiche' est à false.
         *
         * @see venteSelected
         * @see formContext
         * @see addProduit
         * @see state.rubFacts
         */
        useEffect(() => {
            // Instanciation d'un formulaire d'édition de facture, on ignore le useEffect

            if (venteSelected && venteSelected.id && venteSelected.id !== lastVenteUpdate) {
                setLastVenteUpdate(venteSelected.id);
                syncAchatProduitsWithVente(
                    venteSelected?.lignes || undefined,
                    formContext,
                    state.rubFacts,
                    dossierSelected,
                );
            }
        }, [
            lastVenteUpdate,
            dossierSelected,
            formContext,
            state.rubFacts,
            syncAchatProduitsWithVente,
            venteSelected,
        ]);

        /**
         * Ajout des lignes d'achat correspondant à la vente pour un dossier de formalites
         *
         * RG : https://legal2digital.atlassian.net/browse/PA-54
         *
         * Si une facture de vente est trouvée :
         * Ajouter les lignes de vente à l'achat si elles ne sont pas LIB ou
         * la propriété 'affiche' est à false.
         */
        useEffect(() => {
            if (dossierSelected) return;

            if (
                dossierFormaliteSelected &&
                dossierFormaliteSelected.id &&
                dossierFormaliteSelected.id !== lastVenteUpdate
            ) {
                setLastVenteUpdate(dossierFormaliteSelected.id);
                formalitesVentesToProduitsAchats(`${dossierFormaliteSelected.id}`, xIdSociete)
                    .then((lignes) => {
                        if (lignes && lignes.length > 0) {
                            formContext.setValue('produits', []);
                            syncAchatProduitsWithVente(
                                lignes,
                                formContext,
                                state.rubFacts,
                                dossierSelected,
                                true,
                                true,
                            );
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }, [
            dossierFormaliteSelected,
            dossierSelected,
            formContext,
            lastVenteUpdate,
            state.rubFacts,
            syncAchatProduitsWithVente,
            xIdSociete,
        ]);

        // TODO: Double la ligne RSF (BUG). A voir plus tard si vraiment inutile.
        // useEffect(() => {
        //     if (domaine && !venteSelected) {
        //         replaceProduits(formContext, defaultProduits(domaine, true));
        //     }
        // }, [domaine, formContext, venteSelected])

        /**
         * Afficher infos fournisseur
         *
         * RG : https://legal2digital.atlassian.net/browse/PA-53
         *
         * Si le N° de dossier / annonce change :
         * Remplir les champs Siren, Raison sociale, Type remise
         * Ajouter une ligne de produit : Remise
         *
         * @see fournisseurSelected
         * @see updateRSFFournisseur
         */
        useEffect(() => {
            const fournisseur = fournisseurSelected;
            if (fournisseur !== undefined) {
                formContext.setValue('fournisseur', fournisseur);
                formContext.setValue('siren_fournisseur', fournisseur);
                formContext.trigger('siren_fournisseur');
            }

            updateFormWithFournisseur(formContext, fournisseur, {
                typeRemise: (type: TypeRemiseFournisseurRead) => {
                    if (type.code === 'RSF' || type.code === 'RSF Inclus') {
                        ajouterLigneRSF(formContext, fournisseurSelected);
                    }
                },
                siren: (siren: string) => {
                    if (siren !== state.defaultSiren && state.defDomain !== 3) {
                        dispatch({ type: ACTIONS.SET_SIREN, payload: siren });
                    }
                },
            });
        }, [
            dispatch,
            formContext,
            fournisseurSelected,
            state.defaultSiren,
            state.rubFacts,
            state.defDomain,
        ]);

        /**
         * Si le dossier sélectionné possède une commande hors Annonce Légale (domaine 1) et que l'utilisateur a le rôle mada,
         * alors empêcher la saisie de la facture avec ce dossier.
         */
        useEffect(() => {
            const isDossierOfCommande: boolean = !!(
                dossierSelected &&
                commandeSelected &&
                dossierSelected?.commande !== null &&
                dossierSelected?.commande !== undefined &&
                commandeSelected?.idDomaine !== 1
            );

            if (isDossierOfCommande && !isInterne) {
                modalActions.call(
                    <ConfirmationModalContent
                        messages={saisieDossierCommandeModalMessages}
                        actionOnValidation={() => {
                            navigateToNewFacture(
                                showMessage,
                                dispatch,
                                navigate,
                                navigateToListe,
                                mediaobjectid,
                            );
                        }}
                        actionOnCancellation={() => {
                            window.location.reload();
                        }}
                    />,
                );
            }
        }, [
            commandeSelected,
            dispatch,
            dossierSelected,
            isInterne,
            mediaobjectid,
            modalActions,
            navigate,
            navigateToListe,
            showMessage,
        ]);

        /**
         * Si le dossier sélectionné provient d'une formalité (domaine 3 = formalité) et que l'utilisateur a le rôle mada,
         * alors empêcher la saisie de la facture avec ce dossier.
         */
        useEffect(() => {
            const isDossierFormalite: boolean = !!(
                dossierFormaliteSelected && state.defDomain === 3
            );

            if (isDossierFormalite && !isInterne) {
                modalActions.call(
                    <ConfirmationModalContent
                        messages={saisieDossierFormaliteModalMessages}
                        actionOnValidation={() => {
                            navigateToNewFacture(
                                showMessage,
                                dispatch,
                                navigate,
                                navigateToListe,
                                mediaobjectid,
                            );
                        }}
                        actionOnCancellation={() => {
                            window.location.reload();
                        }}
                    />,
                );
            }
        }, [
            dispatch,
            dossierFormaliteSelected,
            isInterne,
            mediaobjectid,
            modalActions,
            navigate,
            navigateToListe,
            showMessage,
            state.defDomain,
        ]);

        /**
         * FIN - useEffects
         */

        /**
         * Actions lors de l'envoi du formulaire
         *
         * Si le formulaire est rempli correctement et le bouton d'envoi est appuyé :
         * Formaliser les données du formulaire de façon à les rendre compatible avec l'API achats
         *
         * @param data
         * @see onSubmit
         *
         */
        const handleSubmit: SubmitHandler<FactureFormObject> = useCallback(
            async (data: FactureFormObject) => {
                const apiInvoicePurchase = formInvoicePurchaseToApiInvoicePurchase(
                    data,
                    mediaObject,
                );

                if (stateDiscountRate.hasSetRsf.value) {
                    apiInvoicePurchase.hasSetRsf = true;
                }

                if (stateHeaderSectionStructure.temporaryStatementId.value) {
                    apiInvoicePurchase.idReleveAchat =
                        stateHeaderSectionStructure.temporaryStatementId.value;
                }

                if (stateNotes) {
                    // Création des notes à lier à la FactureAchat qui va être créée
                    const iriNotes: string[] = stateNotes
                        .filter((stateNote) => stateNote['@id'] !== undefined)
                        .map((stateNote) => stateNote['@id']) as string[];

                    apiInvoicePurchase.notes = iriNotes;
                }

                // Si les totaux ne sont pas bloqués on set les totaux manuellement
                if (!lockTotalsSectionValue) {
                    apiInvoicePurchase.ht = convertToNumberAndFormatToString(data.total_ht);
                    apiInvoicePurchase.tva = convertToNumberAndFormatToString(data.total_tva);
                    apiInvoicePurchase.ttc = convertToNumberAndFormatToString(data.total_ttc);
                }
                if (data.goToNext) {
                    onSubmit(apiInvoicePurchase, true);
                } else {
                    onSubmit(apiInvoicePurchase, false);
                }
            },
            [
                lockTotalsSectionValue,
                stateDiscountRate.hasSetRsf.value,
                mediaObject,
                onSubmit,
                stateNotes,
                stateHeaderSectionStructure.temporaryStatementId.value,
            ],
        );

        const handleInvalid: SubmitErrorHandler<FactureFormObject> = useCallback(
            (errors: FieldErrors<FactureFormObject>) => {
                // Formalisme des données du formulaire de création de facture d'achat
                if (Object.keys(errors).length === 1 && errors.produits) {
                    let oneProduitOk = false;
                    if (errors.produits.length) {
                        for (let index = 0; index < errors.produits.length; index++) {
                            if (errors.produits[index] === undefined) {
                                oneProduitOk = true;
                            }
                        }

                        if (oneProduitOk) {
                            modalActions.call(
                                <ConfirmationModalContent
                                    messages={removeProductsModalMessages}
                                    actionOnValidation={() => {
                                        removeEmptyProduits(formContext);
                                        formContext.handleSubmit(
                                            (d) => {
                                                handleSubmit(d as FactureFormObject);
                                            },
                                            (e) => console.log(e),
                                        )();
                                    }}
                                    actionOnAlternative={() => {
                                        removeEmptyProduits(formContext);
                                        formContext.handleSubmit(
                                            (factureToSubmit) => {
                                                const cloneFactureToSubmit = { ...factureToSubmit };
                                                cloneFactureToSubmit.goToNext = true;
                                                handleSubmit(
                                                    cloneFactureToSubmit as FactureFormObject,
                                                );
                                            },
                                            (e) => console.log(e),
                                        )();
                                    }}
                                />,
                            );
                        }
                    }
                }
            },
            [formContext, handleSubmit, modalActions],
        );

        const renderedActionButtons: FlexyFormSubmitButtonRender | undefined = useCallback(
            (formstate: FormState<FormObject>) => {
                const { isSubmitSuccessful, isSubmitting, isLoading, isValidating } = formstate;

                const reasonToLock: boolean =
                    (isSubmitSuccessful || isSubmitting || isLoading || isValidating) &&
                    validSubmit;

                return (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Button
                            color="error"
                            disabled={reasonToLock}
                            variant="contained"
                            onClick={onCancel}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Annuler la saisie
                        </Button>
                        <Button
                            type="submit"
                            disabled={reasonToLock}
                            variant="contained"
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Terminer la saisie
                        </Button>
                        <Button
                            disabled={reasonToLock}
                            variant="contained"
                            onClick={formContext.handleSubmit((factureToSubmit) => {
                                const cloneFactureToSubmit = { ...factureToSubmit };
                                cloneFactureToSubmit.goToNext = true;
                                handleSubmit(cloneFactureToSubmit as FactureFormObject);
                            }, handleInvalid)}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Facture Suivante
                        </Button>
                    </Box>
                );
            },
            [formContext, handleInvalid, handleSubmit, onCancel, validSubmit],
        );

        const renderedActionButtonsStatement: FlexyFormSubmitButtonRender | undefined = useCallback(
            (formstate: FormState<FormObject>) => {
                const { isSubmitSuccessful, isSubmitting, isLoading, isValidating } = formstate;

                const reasonToLock: boolean =
                    (isSubmitSuccessful || isSubmitting || isLoading || isValidating) &&
                    validSubmit;

                return (
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        <Button
                            color="error"
                            disabled={reasonToLock}
                            variant="contained"
                            onClick={onCancel}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Annuler la saisie
                        </Button>
                        <Button
                            type="submit"
                            disabled={reasonToLock}
                            variant="contained"
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Récapitulatif de Relevé
                        </Button>
                        <Button
                            disabled={reasonToLock}
                            variant="contained"
                            onClick={formContext.handleSubmit((factureToSubmit) => {
                                const cloneFactureToSubmit = { ...factureToSubmit };
                                cloneFactureToSubmit.goToNext = true;

                                handleSubmit(cloneFactureToSubmit as FactureFormObject);
                            }, handleInvalid)}
                            style={{ marginTop: '16px', marginBottom: '16px' }}
                        >
                            Ajouter Facture au Relevé
                        </Button>
                    </Box>
                );
            },
            [formContext, handleInvalid, handleSubmit, onCancel, validSubmit],
        );

        const deleteMediaObject = useDeleteMediaObject({
            mediaObject,
        });

        const updateSociete = useUpdateSociete({
            mediaObject,
        });

        const treatmentRefusal = useTreatmentRefusal({
            mediaObject,
        });

        useAddCustomContextActions([
            treatmentRefusal,
            ...(isInterne ? [deleteMediaObject, updateSociete] : []),
        ]);

        return (
            <FlexyForm
                formObject={facture}
                formStructure={handleSections.factureFormStructure}
                onSubmit={() =>
                    modalActions.call(
                        <ConfirmationModalContent
                            key={`${formContext}-${confirmationSaisieFactureModalMessages}`}
                            messages={confirmationSaisieFactureModalMessages}
                            actionOnValidation={() => {
                                formContext.handleSubmit(
                                    (d) => {
                                        setValidSubmit(true);
                                        handleSubmit(d as FactureFormObject);
                                    },
                                    () => handleInvalid,
                                )();
                            }}
                            actionOnCancellation={() => {
                                setValidSubmit(false);
                            }}
                        />,
                    )
                }
                onInvalid={handleInvalid}
                formContext={formContext}
                submitButton={{
                    render: (formstate, innerHandleSubmit) => {
                        if (stateHeaderSectionStructure.temporaryStatementId.value) {
                            return renderedActionButtonsStatement(formstate, innerHandleSubmit);
                        }
                        return renderedActionButtons(formstate, innerHandleSubmit);
                    },
                }}
            />
        );
    };

export default RegisterSimpleInvoiceForm;
