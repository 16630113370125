import { Box } from '@mui/material';
import * as React from 'react';
import { Controller, FieldValues, UseFormReturn } from 'react-hook-form';
import { formatPrice } from '@europrocurement/l2d-utils';

import TextField from '../../components/form/TextField';
import { ArticleAdditionalInformations, ArticlesSchema } from '../Form/validation';
import SelectField from '../../components/form/SelectField';
import { sTypeOptions } from '../../Article/options';

type ComponentProps = {
    articlePath: string;
    context: UseFormReturn<FieldValues, unknown>;
    article: ArticlesSchema['articles'][number] & ArticleAdditionalInformations;
};

const ArticleOverrideSubForm: React.FC<ComponentProps> = function (props) {
    const { articlePath, context, article } = props;

    const getInputName = (key: string) => `${articlePath}.${key}`;

    const isAdvertCategory = article.originalArticle?.rubArticle === 'PUB';

    const {
        formState: { errors },
        control,
    } = context;

    const originalLabel = article.originalArticle?.libelle;
    const originalPrice = article.originalArticle?.prixUnitaire;

    const pricePlaceholder = () => {
        if (originalPrice) {
            return `${formatPrice(originalPrice)} €`;
        }
        return 'Aucun prix défini';
    };

    return (
        <Box>
            <Controller
                name={getInputName('label')}
                control={control}
                render={({ field }) => (
                    <TextField
                        sx={{ width: '100%' }}
                        {...field}
                        name={field.name}
                        placeholder={originalLabel ?? ''}
                        label="Libellé"
                        errors={errors}
                        required={false}
                    />
                )}
            />
            <Controller
                name={getInputName('price')}
                control={control}
                render={({ field }) => (
                    <TextField
                        sx={{ width: '100%' }}
                        {...field}
                        name={field.name}
                        placeholder={pricePlaceholder()}
                        label="Tarif"
                        errors={errors}
                        required={false}
                    />
                )}
            />
            {isAdvertCategory && (
                <Controller
                    name={getInputName('subTypeId')}
                    control={control}
                    render={({ field }) => (
                        <SelectField
                            {...field}
                            required
                            label="Sous-type"
                            placeholder="Sélectionnez un sous-type"
                            name={field.name}
                            blurInputOnSelect
                            options={sTypeOptions}
                            errors={errors}
                        />
                    )}
                />
            )}
        </Box>
    );
};

export default ArticleOverrideSubForm;
