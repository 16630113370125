import { useEffect } from 'react';
import offerCacheSlice from '@europrocurement/l2d-domain/reducers/offre/slices/offerCacheSlice';
import { useDispatch } from 'react-redux';
import { documentsApi } from '@b2d/redux/subReducers/Offers';
import useFetchCollection from '../Package/Form/useFetchCollection';

const fetchActions = {
    documents: () => documentsApi.apiTypesDocumentsGetCollection({ itemsPerPage: 1000 }),
};

/** Offer cached data redux store managment  :
 * Todo : - Refactor useLoadStore to move here all offer related data that's need to be fetched once at module loading (article specifics, ...)
 */
const useOfferCache = () => {
    const { fetch, result, isFetching, isLoaded } = useFetchCollection(fetchActions.documents);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isLoaded && !isFetching) {
            dispatch(offerCacheSlice.actions.updateCachedDocuments(result as Array<unknown>));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result, isLoaded, isFetching]);
};
export default useOfferCache;
