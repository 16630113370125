import React, { useContext, useState } from 'react';
import { FaOptionIcon, FaOptionIconProps } from '@europrocurement/l2d-icons';
import { Box, Collapse, lighten, PaletteColor, SxProps, useTheme } from '@mui/material';
import './MenuItem.css';
import { MenuContext } from '@europrocurement/flexy-components/providers';

export type MenuItemProps = {
    identifier: string;
    label: string;
    icon: FaOptionIconProps;
    onClick: (target: string | null) => void;
    href?: string;
    color?: PaletteColor;
    childrenItems?: MenuItemProps[];
    folded?: boolean;
    ancestors?: string[];
};

const isItemActive = (activeTree: string[], identifier: string): boolean =>
    activeTree.indexOf(identifier) !== -1 &&
    activeTree.length - 1 === activeTree.indexOf(identifier);

const MenuItem: React.FunctionComponent<MenuItemProps> = function (props) {
    const {
        identifier,
        label,
        icon,
        onClick,
        href = null,
        color,
        childrenItems = [],
        folded = false,
        ancestors = [],
    } = props;
    const theme = useTheme();
    const { activeTree, setActiveTree, openElements, setOpenElements } = useContext(MenuContext);
    const [hover, setHover] = useState<boolean>(false);
    const [collapsed, setCollapsed] = useState<boolean>(openElements.indexOf(identifier) === -1);
    const activeElement = isItemActive(activeTree, identifier);
    const mainColor = color || theme.palette.primary;
    const boxClassNames = ['menu-item'];
    if (folded) {
        boxClassNames.push('menu-item-folded');
    }
    const childrenAncestors = [...ancestors, identifier];
    const childrenNodes = childrenItems.map((child) => (
        <MenuItem
            key={child.identifier}
            {...child}
            folded={folded}
            ancestors={childrenAncestors}
        />
    ));

    const handleClick = () => {
        if (childrenItems.length > 0) {
            const idx = openElements.indexOf(identifier);
            if (idx !== -1) {
                openElements.splice(idx, 1);
            } else {
                openElements.push(identifier);
            }

            setOpenElements(openElements);
            setCollapsed(openElements.indexOf(identifier) === -1);
        }

        if (href) {
            setActiveTree(childrenAncestors);
        }

        if (onClick) {
            onClick(href || null);
        }
    };

    const containerStyle: SxProps = {
        borderRightColor: activeElement || hover ? mainColor.main : 'transparent',
        fontWeight: activeElement || hover ? 600 : 500,
        paddingLeft: folded ? `4px` : `${(20 + (childrenAncestors.length - 1) * 15).toString()}px`,
        paddingRight: folded ? '0' : '10px',
    };

    if (activeTree.indexOf(identifier) !== -1 && !activeElement) {
        if (theme.palette.mode === 'light') {
            containerStyle.borderRightColor = lighten(mainColor.main, 0.8);
            containerStyle.fontWeight = 500;
        } else {
            containerStyle.borderRightColor = lighten(mainColor.main, 0.2);
        }
    }

    let chevron = null;
    if (childrenItems.length > 0 && !folded) {
        chevron = collapsed ? (
            <Box sx={{ marginLeft: 'auto' }}>
                <FaOptionIcon
                    icon={['fasl', 'chevron-right']}
                    sx={{ display: 'flex', alignItems: 'center' }}
                />
            </Box>
        ) : (
            <Box sx={{ marginLeft: 'auto' }}>
                <FaOptionIcon
                    icon={['fasl', 'chevron-down']}
                    sx={{ display: 'flex', alignItems: 'center' }}
                />
            </Box>
        );
    }

    return (
        <Box
            className={boxClassNames.join(' ')}
            data-testid="menu-item"
        >
            <Box
                onClick={handleClick}
                className="menu-item-link"
                data-testid="menu-item-link"
                style={containerStyle}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <FaOptionIcon {...icon} />
                {folded ? '' : label}
                {chevron}
            </Box>
            <Collapse
                in={!collapsed}
                timeout="auto"
                unmountOnExit
            >
                {childrenNodes}
            </Collapse>
        </Box>
    );
};

export default MenuItem;
