import { UseFormReturn } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormObject } from '@europrocurement/flexy-form';
import { FactureAchatLigneJsonldFactureAchatRead } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import { Dossier, DossierFormalite } from '@europrocurement/l2d-domain';
import { RootStateType } from '@b2d/redux/RootStore';
import { ACTIONS } from '@b2d/redux/FactureFormReducer';
import { DateTime } from 'luxon';

/**
 * Met à jour le champs "Dénomination société" de la section "Dossier Formalité"
 *
 * @param formContext
 * @param value
 */
export const updateDenominationSociete = function (
    formContext: UseFormReturn<FormObject>,
    value: string,
) {
    formContext.setValue('denomination_societe_formalite', value);
};

/**
 * Met à jour le champs "Journal" de la section "Dossier Annonce"
 *
 * @param formContext
 * @param value
 */
export const updateNomJournal = function (formContext: UseFormReturn<FormObject>, value: string) {
    formContext.setValue('nom_journal', value);
};

/**
 * Met à jour le champs "Date de parution" de la section "Dossier Annonce"
 *
 * @param formContext
 * @param value
 */
export const updateDateParution = function (formContext: UseFormReturn<FormObject>, value: string) {
    formContext.setValue('date_parution', value);
};

/**
 * Met à jour le champs "Annonceur" de la section "Dossier Annonce"
 *
 * @param formContext
 * @param value
 */
export const updateAnnonceur = function (formContext: UseFormReturn<FormObject>, value: string) {
    formContext.setValue('nom_annonceur', value);
};

/**
 * Vide les champs de la section "Dossier Annonce"
 *
 * @param formContext
 */
export const resetDossiersFields = function (formContext: UseFormReturn<FormObject>) {
    updateNomJournal(formContext, '');
    updateDateParution(formContext, '');
    updateAnnonceur(formContext, '');
};

/**
 * Vide les champs de la section "Dossier Formalité"
 *
 * @param formContext
 */
export const resetDossierFormaliteFields = function (formContext: UseFormReturn<FormObject>) {
    updateDenominationSociete(formContext, '');
};

/**
 * Complète le formulaire grâce au dossier.
 *
 * @param formContext
 * @param dossier
 * @param callbacks appelés en fonction de certains évènements :
 *      onDossierDelete => Si le dossier est supprimé
 *      onDossierNumeroChange => Si le N° de dossier change
 *      onPrestaNumeroChange => Si le N° de prestation change
 */
export const updateFormWithDossier = function (
    formContext: UseFormReturn<FormObject>,
    dossier?: Dossier,
    callbacks?: {
        onDossierDelete?: () => void;
        onDossierNumeroChange?: (numero: number | null | undefined) => void;
        onPrestaNumeroChange?: (numero: string | null | undefined) => void;
    },
) {
    if (!dossier) {
        if (callbacks?.onDossierDelete) {
            callbacks?.onDossierDelete();
        }

        // resetDossiersFields(formContext);
        return;
    }

    const nomJal = `${dossier.prestations && dossier.prestations[0]?.jal?.nom}`;

    updateNomJournal(formContext, nomJal || 'Pas de nom de journal');

    formContext.setValue('dossier', dossier);
    formContext.setValue('numero_dossier', dossier.numero);

    if (callbacks && callbacks.onDossierNumeroChange) {
        callbacks.onDossierNumeroChange(dossier.numero);
    }

    updateAnnonceur(formContext, dossier.annonceur ? dossier.annonceur : "Pas de nom d'annonceur");

    formContext.trigger('numero_dossier');

    if (dossier.prestations && dossier.prestations[0].numero) {
        formContext.setValue('numero_annonce', dossier.prestations[0].numero);
        formContext.trigger('numero_annonce');

        formContext.setValue(
            'date_parution',
            dossier.prestations[0].dateParutionDemandee
                ? DateTime.fromISO(dossier.prestations[0].dateParutionDemandee).toLocaleString(
                      DateTime.DATE_SHORT,
                  )
                : 'Pas de date de parution',
        );

        if (callbacks && callbacks.onPrestaNumeroChange) {
            callbacks.onPrestaNumeroChange(dossier.prestations[0].numero);
        }
    }
};

/**
 * Complète le formulaire grâce au dossier de formalité.
 *
 * @param formContext
 * @param dossierFormalite
 * @param callbacks appelés en fonction de certains évènements :
 *      onDossierDelete => Si le dossier est supprimé
 *      onDossierNumeroChange => Si le N° de dossier change
 *      onPrestaNumeroChange => Si le N° de prestation change
 */
export const updateFormWithDossierFormalite = function (
    formContext: UseFormReturn<FormObject>,
    dossierFormalite?: DossierFormalite,
    callbacks?: {
        onDossierFormaliteDelete?: () => void;
        onDossierFormaliteNumeroChange?: (numero: string | null | undefined) => void;
    },
) {
    if (!dossierFormalite) {
        if (callbacks?.onDossierFormaliteDelete) {
            callbacks?.onDossierFormaliteDelete();
        }
        resetDossierFormaliteFields(formContext);
        return;
    }

    const denominationSociete = dossierFormalite.societeDenomination;

    updateDenominationSociete(formContext, denominationSociete || 'Pas de dénomination société');

    formContext.setValue('dossier_formalite', dossierFormalite);
    formContext.setValue('numero_dossier_formalite', dossierFormalite.numero);

    if (callbacks && callbacks.onDossierFormaliteNumeroChange) {
        callbacks.onDossierFormaliteNumeroChange(dossierFormalite.numero);
    }

    formContext.trigger('numero_dossier_formalite');
};

/**
 * Hook pour mettre à jour les infos de la section "Dossier Annonce"
 * si un dossier est sélectionné. Sinon, Le state est vidé.
 *
 * @returns void
 */
export const useUpdateFormWithDossier = function () {
    const dispatch = useDispatch();
    const defaultNumeroDossier = useSelector(
        (s: RootStateType) => s.factureForm.defaultNumeroDossier,
    );
    const defaultNumeroPresta = useSelector(
        (s: RootStateType) => s.factureForm.defaultNumeroPresta,
    );

    return (formContext: UseFormReturn<FormObject>, dossiersSelected: Dossier) => {
        updateFormWithDossier(formContext, dossiersSelected, {
            onDossierNumeroChange: (numero: number | null | undefined) => {
                if (numero && `${numero}` !== defaultNumeroDossier) {
                    dispatch({ type: ACTIONS.SET_NUM_DOSSIER, payload: numero });
                }
            },
            onPrestaNumeroChange: (numero: string | null | undefined) => {
                if (defaultNumeroPresta !== numero) {
                    dispatch({
                        type: ACTIONS.SET_NUM_PRESTA,
                        payload: numero,
                    });
                }
            },
        });
    };
};

/**
 * Hook pour mettre à jour les infos de la section "Dossier Formalité"
 * si un dossier est sélectionné. Sinon, Le state est vidé.
 *
 * @returns void
 */
export const useUpdateFormWithDossierFormalite = function () {
    const dispatch = useDispatch();
    const defaultNumeroDossierFormalite = useSelector(
        (s: RootStateType) => s.factureForm.defaultNumeroDossierFormalite,
    );

    return (formContext: UseFormReturn<FormObject>, dossierFormaliteSelected: DossierFormalite) => {
        if (defaultNumeroDossierFormalite !== dossierFormaliteSelected?.numero) {
            updateFormWithDossierFormalite(formContext, dossierFormaliteSelected, {
                onDossierFormaliteNumeroChange: (numero: string | null | undefined) => {
                    if (numero && numero !== defaultNumeroDossierFormalite) {
                        dispatch({ type: ACTIONS.SET_NUM_DOSSIER_FORMALITE, payload: numero });
                    }
                },
            });
        }
    };
};

/**
 * Regroupe les lignes par dossier.
 * e.g.: [numeroDossier] - [libelleAnnonceur]: [{factureAchatLigne1}, {factureAchatLigne2}, ...]
 *
 * @param factureAchat
 * @returns {[x: string]: FactureAchatLigneJsonldFactureAchatReadTimestampableBlameambleGroup[];}
 */
export const groupLinesByDossiers = (lignes: FactureAchatLigneJsonldFactureAchatRead[]) => {
    if (!lignes) {
        return {};
    }

    return lignes.reduce(
        (
            previousValue: {
                [x: string]: FactureAchatLigneJsonldFactureAchatRead[];
            },
            currentValue: FactureAchatLigneJsonldFactureAchatRead,
        ) => {
            const newPreviousValue = previousValue;

            const objKey = `${currentValue.numeroDossier || 'Dossier non défini'} - ${
                currentValue.libelleAnnonceur || 'Annonceur non défini'
            }`;
            if (!newPreviousValue[objKey]) {
                newPreviousValue[objKey] = [];
            }
            newPreviousValue[objKey].push(currentValue);
            return newPreviousValue;
        },
        {},
    );
};

/**
 * Regroupe les lignes par dossier formalité.
 * e.g.: [numeroDossier] - [libelleAnnonceur]: [{factureAchatLigne1}, {factureAchatLigne2}, ...]
 *
 * @param factureAchat
 * @returns {[x: string]: FactureAchatLigneJsonldFactureAchatReadTimestampableBlameambleGroup[];}
 */
export const groupLinesByDossiersFormalite = (
    lignes: FactureAchatLigneJsonldFactureAchatRead[],
) => {
    if (!lignes) {
        return {};
    }

    return lignes.reduce(
        (
            previousValue: {
                [x: string]: FactureAchatLigneJsonldFactureAchatRead[];
            },
            currentValue: FactureAchatLigneJsonldFactureAchatRead,
        ) => {
            const newPreviousValue = previousValue;

            const objKey = `${currentValue.idDossierFpro || 'Dossier non défini'} - ${
                currentValue.libelleAnnonceur || 'Annonceur non défini'
            }`;
            if (!newPreviousValue[objKey]) {
                newPreviousValue[objKey] = [];
            }
            newPreviousValue[objKey].push(currentValue);
            return newPreviousValue;
        },
        {},
    );
};
