import { useMemo } from 'react';

import { FormStructure } from '@europrocurement/flexy-form';
import {
    UseProductsSectionStructureProps,
    UseTotalsSectionStructureProps,
} from '@b2d/pages/Achats/components/forms/formElements/types';
import productsStructure from '@b2d/pages/Achats/components/forms/formElements/fragments/productsSection/productsStructure';
import { ExtractArrayType } from '@b2d/utils/types';

type HeaderChildrenArrayType = ExtractArrayType<UseTotalsSectionStructureProps['headerChildren']>;

const useProductsSectionStructure = ({
    formContext,
    rubFacts,
    txTva,
    mode,
    headerSwitches,
    headerChildren,
    hasBlured,
    openModal,
    produitsVente,
    displaySells,
    handleTotalsLock,
    stateSwitchLockValues,
}: UseProductsSectionStructureProps) => {
    const overwriteHeaderChildren = useMemo<HeaderChildrenArrayType>(() => {
        let temporaryHeaderChildren: HeaderChildrenArrayType = [];

        if (headerChildren && Array.isArray(headerChildren)) {
            temporaryHeaderChildren = [...temporaryHeaderChildren, ...headerChildren];
        } else {
            temporaryHeaderChildren.push(headerChildren);
        }

        return temporaryHeaderChildren;
    }, [headerChildren]);

    const productsSectionStructure = useMemo<Array<FormStructure>>(
        () =>
            productsStructure({
                formContext,
                rubFacts,
                txTva,
                mode,
                headerSwitches,
                headerChildren: overwriteHeaderChildren,
                hasBlured,
                openModal,
                produitsVente,
                displaySells,
                handleTotalsLock,
                stateSwitchLockValues,
            }),
        [
            formContext,
            rubFacts,
            txTva,
            mode,
            headerSwitches,
            overwriteHeaderChildren,
            hasBlured,
            openModal,
            produitsVente,
            displaySells,
            handleTotalsLock,
            stateSwitchLockValues,
        ],
    );

    return {
        productsSectionStructure,
    };
};

export default useProductsSectionStructure;
