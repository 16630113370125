import invoiceStatement from './invoiceStatement';
import invoicePurchase from './invoicePurchase';
import mediaObject from './mediaObject';

const models = {
    invoiceStatement,
    invoicePurchase,
    mediaObject,
};

export default models;
