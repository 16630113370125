import React, { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FACTURES_ACHATS_PAUSE_DATASOURCE_NAME, FactureAchat } from '@europrocurement/l2d-domain';
import { LazyBreadcrumb } from '@europrocurement/flexy-components';
import { DataSource } from '@europrocurement/l2d-redux-utils';
import { FilterDatatable } from '@europrocurement/flexy-datatable';

import { UseKeycloakCheckRole } from '@europrocurement/l2d-keycloak';
import {
    AppDispatch,
    FactureachatSelector,
    getFactureAchatPause,
} from '../../../../redux/RootStore';

import achatsViewPathResolver from '../../achatsViewPathResolver';
import FactureAchatList from '../../components/lists/FactureAchatList';

const UnclosedRegisteredInvoicesView: React.FunctionComponent = function () {
    const facturesAchatsDataSource: DataSource<FactureAchat> =
        useSelector(FactureachatSelector)[FACTURES_ACHATS_PAUSE_DATASOURCE_NAME];

    const roleChecker = UseKeycloakCheckRole();
    const dispatch = useDispatch<AppDispatch>();
    const isInterne = roleChecker('realm:interne');

    useEffect(() => {
        dispatch({
            type: `${facturesAchatsDataSource.slicename}/set${facturesAchatsDataSource.name}Filter`,
            payload: { key: 'cloture', value: false },
        });

        dispatch({
            type: `${facturesAchatsDataSource.slicename}/set${facturesAchatsDataSource.name}Filter`,
            payload: { key: 'deleted', value: false },
        });
    }, [dispatch, facturesAchatsDataSource.name, facturesAchatsDataSource.slicename]);

    useEffect(() => {
        if (
            facturesAchatsDataSource.filters.cloture === false &&
            facturesAchatsDataSource.filters.deleted === false
        ) {
            dispatch(getFactureAchatPause({}));
        }
    }, [
        dispatch,
        facturesAchatsDataSource.filters.cloture,
        facturesAchatsDataSource.filters.deleted,
    ]);

    const lazyBreadcrumbPathOnUserRole = useMemo(() => {
        if (isInterne) {
            return {
                path: 'Mes factures à traiter',
                link: achatsViewPathResolver('listmediaobjectinternally'),
            };
        }

        return {
            path: 'Factures à traiter',
            link: achatsViewPathResolver('listmediaobject'),
        };
    }, [isInterne]);

    return (
        <>
            <LazyBreadcrumb
                path={[
                    { path: 'Portail achats', link: lazyBreadcrumbPathOnUserRole.link },
                    lazyBreadcrumbPathOnUserRole,
                    'Factures en pause',
                ]}
            />
            {facturesAchatsDataSource.filters.cloture === false &&
            facturesAchatsDataSource.filters.deleted === false ? (
                <FactureAchatList
                    filtersControl
                    dataSource={facturesAchatsDataSource}
                    fetchData={getFactureAchatPause}
                    filterFilters={(filtre: FilterDatatable) =>
                        filtre.field !== 'cloture' && filtre.field !== 'deleted'
                    }
                />
            ) : null}
        </>
    );
};

export default UnclosedRegisteredInvoicesView;
