import _ from 'lodash';

import type { FactureAchat } from '@europrocurement/l2d-domain';
import type { FactureAchatJsonldFactureAchatWrite } from '@europrocurement/l2d-domain/openApi/ApiAchats';
import type { FactureFormObject } from '@b2d/pages/Achats/components/forms/types';

import cleanClone from './cleanClone';

export default (
    factureForm: FactureFormObject,
    currentFacture: FactureAchat,
    lockedTotaux: boolean,
): FactureAchatJsonldFactureAchatWrite => {
    const clone = _.cloneDeep(currentFacture);

    if (!lockedTotaux) {
        clone.ht = factureForm.total_ht.toString();
        clone.tva = factureForm.total_tva.toString();
        clone.ttc = factureForm.total_ttc.toString();
    }

    // if (clone.lignes && clone.lignes?.length > 0 && Object.keys(groupLinesByDossiers(clone)).length === 1) {
    //     clone.libelleAnnonceur = clone.lignes[0].libelleAnnonceur || '';
    // }

    clone.cloture = true;

    return cleanClone(clone);
};
