import React from 'react';

import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
    TableContainer,
    SxProps,
} from '@mui/material';

import { SkeletonArray, SpcContext, SpcContextProps } from '@europrocurement/flexy-components';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import CheckIcon from '@mui/icons-material/Check';
import type { Variant } from '@mui/material/styles/createTypography';

import type {
    EuroprocApiResponseStatus,
    Pagination as PaginationType,
} from '@europrocurement/l2d-redux-utils';
import { ColumnDatatable } from '../ColumnDatatable';
import FlexyFilters from '../FlexyFilters/FlexyFilters';
import { DisplayOpts, SearchOpts } from '../DatatableHeader';
import ColumnHeaderItemSortabled, {
    SortDirection,
} from '../ColumnHeaderItemSortabled/ColumnHeaderItemSortabled';

export type DatatablePagination = PaginationType & {
    onPageChange: (page: number) => void;
    onItemsPerPageChange: (itemsPerPage: number) => void;
};

export type FlexyDatatableProps<T = Record<string, unknown>> = {
    status: EuroprocApiResponseStatus;
    columns: Array<ColumnDatatable<T>>;
    setColumns?: React.Dispatch<React.SetStateAction<ColumnDatatable<T>[]>>;
    data: Array<T>;
    columnLabelVariant?: Variant;
    textCellVariant?: Variant;
    pagination?: DatatablePagination;
    searchOpts?: SearchOpts;
    // handleExport?: DatatableHeaderProps['handleExport'];
    onClickRow?: (e: React.MouseEvent<HTMLElement>, item?: T) => void;
    onWheelClickRow?: (e: React.MouseEvent<HTMLElement>, item?: T) => void;
    filtersControl?: boolean;
    isSearch?: boolean;
    isExport?: boolean;
    isFilters?: boolean;
    sx?: SxProps;
    hideColumnOptions?: boolean;
    selectedId?: number;
};

type FlexyDatatableData<T extends Record<string, unknown>> = T & {
    datatableIdentifier: string;
};

const FlexyDatatableTable = function <T extends Record<string, unknown>>({
    status,
    columns,
    setColumns,
    data,
    columnLabelVariant,
    textCellVariant,
    pagination,
    searchOpts,
    // handleExport,
    onClickRow,
    onWheelClickRow,
    filtersControl = true,
    isSearch = true,
    isExport = true,
    isFilters = true,
    selectedId,
    hideColumnOptions = false,
    sx = {},
}: FlexyDatatableProps<T>) {
    const datatableDatas: Array<FlexyDatatableData<T>> = data.map((item, index) => ({
        ...item,
        datatableIdentifier: `datatableIdentifier${index}`,
    }));

    const { onPageChange, onItemsPerPageChange, itemsPerPage } = {
        ...pagination,
    } as DatatablePagination;

    const displayOpts: DisplayOpts | undefined = setColumns
        ? {
              columns,
              setColumns,
          }
        : undefined;

    const { toggleRightMenu } = React.useContext<SpcContextProps>(SpcContext);
    // const { onSearchChange, filtersOpts, search } = searchOpts as SearchOpts;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const openDisplayColumn = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function renderData() {
        // return <h1>here oéoéo</h1>;
        switch (status) {
            case undefined:
            case 'succeeded':
                return (
                    <TableBody
                        data-testid="FlexyDatatableBody"
                        sx={{
                            bgColor: 'purple !important',
                            overflow: 'hidden',
                        }}
                    >
                        {datatableDatas.map((item: FlexyDatatableData<T>) => (
                            <TableRow
                                key={item.datatableIdentifier}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        backgroundColor: 'background.default',
                                    },
                                    backgroundColor: `${
                                        selectedId === item.id ? 'background.default' : 'inherit'
                                    }`,
                                }}
                            >
                                {columns?.map((column) =>
                                    column.isDisplayed ? (
                                        <TableCell
                                            key={`${item.datatableIdentifier}-${column.label}`}
                                            onClick={(e) =>
                                                column.onClickCell
                                                    ? column.onClickCell
                                                    : onClickRow && onClickRow(e, item)
                                            }
                                            onAuxClick={(e) => {
                                                const wheelClick = e.button === 1;
                                                if (wheelClick) {
                                                    if (onWheelClickRow) {
                                                        onWheelClickRow(e, item);
                                                    }
                                                }
                                            }}
                                            sx={{
                                                padding: 0.5,
                                                width: column.fullWidth ? '100%' : '',
                                            }}
                                        >
                                            <Typography
                                                variant={textCellVariant || 'body1'}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                {typeof column.render === 'string'
                                                    ? column.render in item &&
                                                      (item[column.render] as string)
                                                    : column.render(item)}
                                            </Typography>
                                        </TableCell>
                                    ) : null,
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                );
            case 'loading':
                return (
                    <TableBody data-testid="FlexyDatatableBody">
                        <TableRow>
                            <TableCell
                                data-testid="StatusLoading"
                                colSpan={12}
                                sx={{ height: '45vh' }}
                            >
                                <SkeletonArray
                                    withHeader={false}
                                    columns={4}
                                    rows={8}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                );
            case 'idle':
                return (
                    <TableBody data-testid="FlexyDatatableBody">
                        <TableRow>
                            <TableCell
                                colSpan={12}
                                sx={{ height: '45vh' }}
                            >
                                <Typography>En attente d&apos;une recherche...</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                );
            case 'failed':
            default:
                return (
                    <TableBody data-testid="FlexyDatatableBody">
                        <TableRow>
                            <TableCell
                                colSpan={12}
                                sx={{ height: '45vh' }}
                                data-testid="StatusFailed"
                            >
                                une erreur lors du chargement est survenue
                            </TableCell>
                        </TableRow>
                    </TableBody>
                );
        }
    }

    const paginationRef = React.useRef<HTMLDivElement>(null);
    const filterRef = React.useRef<HTMLDivElement>(null);

    return (
        <Box sx={{ width: '100%', padding: '30px 30px 10px 30px', maxHeight: '100%' }}>
            {filtersControl ? (
                <Box ref={filterRef}>
                    <FlexyFilters
                        isSearch={isSearch}
                        isExport={isExport}
                        isFilters={isFilters}
                        searchOpts={searchOpts}
                        displayOpts={displayOpts}
                        // handleExport={handleExport}
                        toggleRightMenu={toggleRightMenu}
                    />
                </Box>
            ) : null}
            <TableContainer
                sx={{
                    // overflow:'hidden'
                    maxHeight: `calc(100% - ${
                        (paginationRef?.current && paginationRef?.current.clientHeight) || 52
                    }px - ${(filterRef?.current && filterRef?.current.clientHeight) || 0}px )`,
                    ...sx,
                }}
            >
                <Table
                    stickyHeader
                    aria-label="simple table"
                    sx={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    <TableHead
                        data-testid="FlexyDatatableHead"
                        sx={{
                            borderBottom: '2px solid',
                            borderColor: 'secondary.main',
                        }}
                    >
                        <TableRow
                            sx={{
                                borderBottom: '2px solid',
                                borderColor: 'secondary.main',
                            }}
                        >
                            {columns?.map((column, i, arr) => {
                                if (arr.length - 1 !== i) {
                                    let orderable = false;
                                    let defaultOrder: SortDirection;
                                    if (column.sortable && searchOpts?.ordersOpts?.orders) {
                                        orderable = true;
                                        if (column.field && searchOpts.ordersOpts?.orders) {
                                            const currentOrder = searchOpts.ordersOpts.orders.find(
                                                (item) => item.field === column.field,
                                            );
                                            if (currentOrder) {
                                                defaultOrder = currentOrder.value as SortDirection;
                                            }
                                        }
                                    }

                                    return (
                                        column.isDisplayed && (
                                            <TableCell
                                                key={column.label}
                                                sx={{
                                                    padding: '16px 16px 16px 4px ',
                                                    borderBottom: '2px solid',
                                                    backgroundColor: 'background.paper',
                                                    borderColor: 'secondary.main',
                                                }}
                                            >
                                                <Typography
                                                    variant={columnLabelVariant}
                                                    sx={{
                                                        fontWeight: '700',
                                                        color: 'secondary.main',
                                                    }}
                                                    display="flex"
                                                    flexDirection="row"
                                                >
                                                    {!(column.displayLabel === false) &&
                                                        column.label}
                                                    {orderable && searchOpts?.ordersOpts ? (
                                                        <ColumnHeaderItemSortabled
                                                            onOrdersChange={
                                                                searchOpts.ordersOpts
                                                                    ?.onOrdersChange
                                                            }
                                                            defaultValue={defaultOrder}
                                                            field={column.field}
                                                        />
                                                    ) : null}
                                                </Typography>
                                            </TableCell>
                                        )
                                    );
                                }

                                return (
                                    <TableCell
                                        key={column.label}
                                        sx={{
                                            paddingBottom: '12px',
                                            borderBottom: '2px solid',
                                            backgroundColor: 'background.paper',
                                            borderColor: 'secondary.main',
                                        }}
                                        data-testid="FiltersColumn"
                                    >
                                        {displayOpts && !hideColumnOptions && (
                                            <>
                                                <Tooltip
                                                    title="affichage colonnes"
                                                    data-testid="DisplayFiltersButton"
                                                >
                                                    <IconButton onClick={openDisplayColumn}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: 48 * 4.5,
                                                            width: '20ch',
                                                        },
                                                    }}
                                                >
                                                    {columns
                                                        ?.slice(0, -1)
                                                        .map((currentColumn, index) => (
                                                            <MenuItem
                                                                key={currentColumn.label}
                                                                selected={currentColumn.isDisplayed}
                                                                onClick={() => {
                                                                    const newCols = [...columns];
                                                                    newCols[index].isDisplayed =
                                                                        !newCols[index].isDisplayed;
                                                                    if (setColumns)
                                                                        setColumns(newCols);
                                                                }}
                                                            >
                                                                {currentColumn.isDisplayed && (
                                                                    <CheckIcon fontSize="small" />
                                                                )}
                                                                {currentColumn.label}
                                                            </MenuItem>
                                                        ))}
                                                </Menu>
                                            </>
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    {renderData()}
                </Table>
            </TableContainer>
            {pagination && status === 'succeeded' ? (
                <TablePagination
                    ref={paginationRef}
                    data-testid="DatatablePagination"
                    component="div"
                    page={pagination.page}
                    count={pagination.total || 0}
                    onPageChange={(_e, _page) => onPageChange(_page)}
                    rowsPerPage={itemsPerPage}
                    onRowsPerPageChange={(e) => onItemsPerPageChange(+e.target.value)}
                    labelDisplayedRows={(opts) =>
                        `Page ${+opts.page + 1} | ${opts.from} - ${opts.to} sur ${opts.count}`
                    }
                    labelRowsPerPage="Nombre d'éléments par page"
                    rowsPerPageOptions={pagination.rowsPerPageOptions || [10, 20, 50, 100]}
                />
            ) : null}
        </Box>
    );
};

export default FlexyDatatableTable;
